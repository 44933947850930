// @mui
import PropTypes from 'prop-types';
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// utils
import { fDateTime } from 'src/utils/format-time';

// ----------------------------------------------------------------------

export default function OrderDetailsHistory({ history, showComments = true }) {
  // eslint-disable-next-line
  const renderSummary = (
    <Stack
      spacing={2}
      component={Paper}
      variant="outlined"
      sx={{
        p: 2.5,
        minWidth: 260,
        flexShrink: 0,
        borderRadius: 2,
        typography: 'body2',
        borderStyle: 'dashed',
      }}
    >
      <Stack spacing={0.5}>
        <Box sx={{ color: 'text.disabled' }}>Order time</Box>
        {fDateTime(history.orderTime)}
      </Stack>
      <Stack spacing={0.5}>
        <Box sx={{ color: 'text.disabled' }}>Payment time</Box>
        {fDateTime(history.orderTime)}
      </Stack>
      <Stack spacing={0.5}>
        <Box sx={{ color: 'text.disabled' }}>Delivery time for the carrier</Box>
        {fDateTime(history.orderTime)}
      </Stack>
      <Stack spacing={0.5}>
        <Box sx={{ color: 'text.disabled' }}>Completion time</Box>
        {fDateTime(history.orderTime)}
      </Stack>
    </Stack>
  );

  const renderTimeline = (
    <div style={{ maxHeight: '250px', width: '100%', overflowY: 'auto' }}>
      <Timeline
        sx={{
          p: 0,
          m: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {[...history]
          ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          ?.map((item, index) => {
            // eslint-disable-next-line
            const firstTimeline = index === 0;
            return (
              <TimelineItem key={`${item.title}_${index}`}>
                <TimelineSeparator>
                  <TimelineDot
                    color={
                      item?.action === 'Order Created By Customer' ||
                      item?.action === 'Order Updated by Customer'
                        ? 'grey'
                        : 'primary'
                    }
                  />
                  {item.createdAt ? null : <TimelineConnector />}
                </TimelineSeparator>

                <TimelineContent>
                  <Typography variant="subtitle2">
                    Action - {item.action} {item?.admin?.first_name ? item?.admin?.first_name : ''}{' '}
                    {item?.admin?.last_name ? item?.admin?.last_name : ''}
                  </Typography>

                  {item.body ? (
                    <Typography
                      sx={{
                        color: 'text.disabled',
                        typography: 'caption',
                        mt: 0.5,
                        fontSize: '0.9rem',
                      }}
                      variant="subtitle2"
                    >
                      <b>Updated values:</b>
                      {Object.entries(item.body)
                        ?.filter(([key]) => key !== 'orderId' && key !== 'changedBy')
                        ?.map(([key, value]) => {
                          const formattedKey = key?.replace(/_/g, ' ');
                          const formattedValue =
                            typeof value === 'string' ? value.replace(/_/g, ' ') : value;
                          return ` ${formattedKey}: ${formattedValue}`;
                        })
                        .join(', ')}
                    </Typography>
                  ) : (
                    ''
                  )}

                  <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
                    {fDateTime(item.createdAt)}
                  </Box>
                </TimelineContent>
              </TimelineItem>
            );
          })}
      </Timeline>
    </div>
  );

  const renderComments = (
    <div style={{ maxHeight: '250px', width: '100%', overflowY: 'auto' }}>
      <Timeline
        sx={{
          p: 0,
          m: 0,
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
      >
        {history?.map((item, index) => {
          // eslint-disable-next-line
          const firstTimeline = index === 0;

          return item.comment ? (
            <TimelineItem key={`${item.title}_${index}`}>
              <TimelineSeparator>
                <TimelineDot color="primary" />
                {item.createdAt ? null : <TimelineConnector />}
              </TimelineSeparator>

              <TimelineContent>
                <Typography variant="subtitle2">{item.comment}</Typography>
                <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
                  {fDateTime(item.createdAt)}
                </Box>
              </TimelineContent>

              <Typography variant="subtitle2">
                {item?.admin?.first_name ? item?.admin?.first_name : ''}{' '}
                {item?.admin?.last_name ? item?.admin?.last_name : ''}
              </Typography>
            </TimelineItem>
          ) : null;
        })}
      </Timeline>
    </div>
  );
  return (
    <>
      <Card sx={{ mt: 5 }}>
        <CardHeader title="History" />
        <Stack
          spacing={3}
          alignItems={{ md: 'flex-start' }}
          direction={{ xs: 'column-reverse', md: 'row' }}
          sx={{ p: 3 }}
        >
          {renderTimeline}
        </Stack>
      </Card>
      {showComments ? (
        <Card sx={{ mt: 5 }}>
          <CardHeader title="Comment" />
          <Stack
            spacing={3}
            alignItems={{ md: 'flex-start' }}
            direction={{ xs: 'column-reverse', md: 'row' }}
            sx={{ p: 3 }}
          >
            {renderComments}
          </Stack>
        </Card>
      ) : (
        ''
      )}
    </>
  );
}

OrderDetailsHistory.propTypes = {
  history: PropTypes.array,
  showComments: PropTypes.bool,
};
