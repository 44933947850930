import { Helmet } from 'react-helmet-async';
import { BoxListView } from 'src/sections/box/view';

export default function BoxListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Box</title>
      </Helmet>

      <BoxListView />
    </>
  );
}
