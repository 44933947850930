import {
  Button,
  Drawer,
  IconButton,
  MenuItem,
  Typography,
  Select,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useState } from 'react';
import OrderList from 'src/custom_components/orderList/OrderList';
import { usePalletActions } from 'src/context/providers/PalleteContext';
import { useAirwayBillActions } from 'src/context/providers/AirwayBillContext';
import { debounce } from 'lodash';
import ZebraPrintComponentBarCodes from 'src/custom_components/zebraPrintBarcode/ZebraPrintBarcode';
import InputFieds from '../../custom_components/Froms/InputFields';
import { status, personalInformation, status_colors } from './view/data';

const CustomerDrawerComponentpallet = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [palletData, setpalletData] = useState({
    tracking: aRow.tracking || '',
    status: aRow.status || '',
    airwayBill: aRow?.airwaybill_id || null,
  });

  const { getAirwayBills } = useAirwayBillActions();

  // const airwayBills = useAirwayBillData().airwayBills?.filter((item) => item.status !== 'LANDED');
  const [airwayBill, setAirwayBill] = useState({
    id: '',
    tracking: aRow?.airwaybill_id || null,
  });
  const { updatePallet } = usePalletActions();
  const Clear = () => {
    setpalletData({});
    setAirwayBill({
      id: '',
      tracking: '',
    });
  };

  const handleUpdate = () => {
    const palletUPDAta = {
      id: aRow.id,
      tracking: palletData.tracking,
      status: palletData.status,
      airwaybill_id: airwayBill.id,
    };
    updatePallet(aRow.id, palletUPDAta);
    handleDrawerToggle();
    Clear();
    setARow({});
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: `Edit pallet - ${aRow.id}`,
    buttons: {
      status: true,
      elements: [{ name: 'Update', onClick: handleUpdate, disabled: false }],
    },
  };
  const [airwaybillOptions, setAirwaybillOptions] = useState([]);
  const [loadingAWB, setLoadingAWB] = useState(false);
  const setAirwayBillValue = (e) => {
    setAirwayBill(e);
  };
  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setpalletData({
      ...palletData,
      [name]: value,
    });
  };
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
      setARow({});
    }
  };

  const fetchAWB = useCallback(
    async (filters) => {
      setLoadingAWB(true);
      try {
        const response = await getAirwayBills(filters);
        setAirwaybillOptions(response?.airwaybills);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingAWB(false);
      }
    },
    [getAirwayBills]
  );

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchAWB(initialFilters);
  }, [fetchAWB]);
  // Fetch pallet data based on input

  const handleAWBInputChange = debounce((event, newInputValue) => {
    const filters = {
      tracking: newInputValue,
      take: 5,
    };
    fetchAWB(filters);
  }, 500);
  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          Clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={() => {
          handleDrawerToggle();
          Clear();
          setARow({});
        }}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              width: '40%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {Object.keys(palletData)?.map((key) => {
              if (key === 'status') {
                return (
                  <Select
                    key={key}
                    name={key}
                    value={palletData[key]}
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: status_colors[palletData.status][0],
                      color: status_colors[palletData.status][1],
                    }}
                    variant="outlined"
                    sx={{ width: '70%', height: '40px' }}
                  >
                    {status.map((i, index) => (
                      <MenuItem key={index} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }
              return null;
            })}

            <IconButton
              onClick={() => {
                handleDrawerToggle();
                Clear();
                setARow({});
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Form */}

        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Form */}

          {/* attached orders */}
          <OrderList aRow={aRow} />
          {/* attached orders */}
          {/* Personal Information */}
          <InputFieds
            userData={palletData}
            heading="Pallet Information"
            inputFields={personalInformation}
            setUserData={setpalletData}
          />
          {/* Personal Information */}

          <div
            className="content-wrap"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '0px 0px 0px 20px',
            }}
          >
            {/* <SelectFieldForOrder
              key="airwayBill"
              widthComp="95%"
              selectName="airwayBill"
              data={airwayBills}
              setter={handleInputChange}
              selectedValue={palletData?.airwayBill}
              optionName="tracking"
            /> */}
            <Autocomplete
              sx={{ width: '96%' }}
              options={airwaybillOptions}
              getOptionLabel={(option) => option.tracking || ''}
              value={airwayBill}
              onChange={(event, newValue) => setAirwayBillValue(newValue)}
              onInputChange={handleAWBInputChange}
              loading={loadingAWB}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="AWB Tracking"
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingAWB ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              marginTop: '3%',
            }}
          >
            <ZebraPrintComponentBarCodes tracking={aRow.tracking} />
          </div>
        </div>
        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid rgba(220, 220, 220, 0.2)',
              padding: '16px',
            }}
          >
            {DrawerConfig.buttons.elements.map((element, index) => (
              <Button
                variant="contained"
                key={index}
                style={{
                  marginLeft: '24px',
                  width: '136px',
                }}
                disabled={element.disabled}
                onClick={element.onClick}
              >
                {element.name}
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CustomerDrawerComponentpallet.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponentpallet;
