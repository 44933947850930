import { Helmet } from 'react-helmet-async';
import { PalleteListView } from 'src/sections/pallete/view';

export default function PalleteListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Pallete</title>
      </Helmet>

      <PalleteListView />
    </>
  );
}
