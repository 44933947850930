import PropTypes from 'prop-types';
import { useCallback } from 'react';
// @mui
import { Stack, MenuItem, TextField, InputAdornment } from '@mui/material';

// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function UserTableToolbar({
  filters,
  onFilters,
  //
  roleOptions,
  dataFiltered,
}) {
  const popover = usePopover();
  const handleFilterName = useCallback(
    (event) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  // const handleFilterRole = useCallback(
  //   (event) => {
  //     onFilters(
  //       'role',
  //       typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
  //     );
  //   },
  //   [onFilters]
  // );
  const exportToCsv = (data, filename) => {
    // Early exit if data is empty to prevent errors
    if (!data.length) {
      console.warn('No data to export');
      return;
    }

    let csvContent = 'data:text/csv;charset=utf-8,';

    // Header
    const headers = [
      'name',
      'tracking_number',
      'room_number',
      'weight',
      'custom',
      'declaration_status',
      'nusxa',
      'vatPaymentAuto',
      'status',
    ];
    csvContent += `${headers.join(',')}\r\n`;

    // Rows
    data.forEach((row) => {
      const rowData = headers.map((header) => {
        let value = '';
        switch (header) {
          case 'room_number':
            value = row.customer.room_number; // Mapping 'custom' to 'customer_room'
            break;
          case 'tracking_number':
            value = row.tracking_number.toString();
            break;
          // Add more cases here if needed for other mappings
          default:
            value = row[header] || '';
        }
        return `"${String(value).replace(/"/g, '""')}"`; // Correct handling of data that may include commas or quotes
      });
      csvContent += `${rowData.join(',')}\r\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link); // Required for FF
    link.click(); // This triggers the download
  };

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <TextField
            fullWidth
            value={filters.name}
            onChange={handleFilterName}
            placeholder="Search..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
          />
          {/* 
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton> */}
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        {/* <MenuItem
          onClick={() => {
            popover.onClose();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          Print
        </MenuItem> */}

        <MenuItem
          onClick={() => {
            popover.onClose();
            const dataToExport = dataFiltered;
            exportToCsv(dataToExport, 'export.csv');
          }}
        >
          <Iconify icon="solar:export-bold" />
          Export
        </MenuItem>
      </CustomPopover>
    </>
  );
}

UserTableToolbar.propTypes = {
  filters: PropTypes.object,
  onFilters: PropTypes.func,
  roleOptions: PropTypes.array,
  dataFiltered: PropTypes.array,
};
