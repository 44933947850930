// @mui
import {
  Box,
  Button,
  Container,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';

//
import { debounce } from 'lodash';
import UploadImageComponent from 'src/custom_components/Froms/imageContentUpload';
import { useOrderActions } from 'src/context/providers/OrderContext';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useState } from 'react';
import { useAirwayBillActions } from 'src/context/providers/AirwayBillContext';
import DownloadForRS from './downloadForRS';

export default function TcTable({ TABLE_HEAD, defaultFilters, tableNames, kanbanInput }) {
  const settings = useSettingsContext();
  const [rsData, setRsData] = useState({});
  const [files, setFiles] = useState([]);
  const [selectedPallets, setSelectedPallets] = useState([]);
  const [selectedPalletIds, setSelectedPalletIds] = useState([]);
  const { getAirwayBills } = useAirwayBillActions();
  const [airwayBill, setAirwayBill] = useState({
    id: '',
    tracking: '',
  });
  // Custom
  const [airwaybillOptions, setAirwaybillOptions] = useState([]);
  const [loadingAWB, setLoadingAWB] = useState(false);
  const setAirwayBillValue = (e) => {
    setAirwayBill(e);
    setSelectedPallets(e?.pallets);
    setSelectedPalletIds([]);
  };
  const { getRSOrders, sendFile } = useOrderActions();

  const sendFilesTORS = () => {
    if (files.length) {
      sendFile(files);
    }
  };
  const handlePalletChange = (palletId) => {
    setSelectedPalletIds((prevSelectedPalletIds) => {
      if (prevSelectedPalletIds.includes(palletId)) {
        return prevSelectedPalletIds.filter((id) => id !== palletId);
      }
      return [...prevSelectedPalletIds, palletId];
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      if (selectedPalletIds.length) {
        try {
          const data = await getRSOrders(selectedPalletIds);
          setRsData(data);
        } catch (error) {
          console.error('Failed to fetch RS orders:', error);
        }
      }
    };
    fetchData();
  }, [selectedPalletIds, getRSOrders]);

  const fetchAWB = useCallback(
    async (filters) => {
      setLoadingAWB(true);
      try {
        const response = await getAirwayBills(filters);
        setAirwaybillOptions(response?.airwaybills);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingAWB(false);
      }
    },
    [getAirwayBills]
  );

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchAWB(initialFilters);
  }, [fetchAWB]);
  // Fetch pallet data based on input

  const handleAWBInputChange = debounce((event, newInputValue) => {
    const filters = {
      tracking: newInputValue,
      take: 5,
    };
    fetchAWB(filters);
  }, 500);

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs heading="RS" sx={{ padding: '0px 3%' }} />
        <div className="select_containers_large">
          <div
            className="child_kanban_large"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <Typography variant="h6">Download Data For RS</Typography>
            <Box className="headings">
              <div
                className="little_input_boxes"
                style={{
                  backgroundColor: settings.themeMode === 'light' ? '#ffffff' : '#161C24',
                }}
              >
                <div style={{ width: '80%' }}>
                  {/* <SelectField
                    selectName="Air Waybill"
                    widthComp="65%"
                    data={airwayBills}
                    setter={setAirwayBillValue}
                    selectedValue={airwayBill || null}
                    name1="tracking"
                  /> */}

                  <Autocomplete
                    sx={{ width: '40%' }}
                    options={airwaybillOptions}
                    getOptionLabel={(option) => option.tracking || ''}
                    value={airwayBill}
                    onChange={(event, newValue) => setAirwayBillValue(newValue)}
                    onInputChange={handleAWBInputChange}
                    loading={loadingAWB}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="AWB Tracking"
                        variant="filled"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loadingAWB ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  <FormGroup>
                    {selectedPallets?.map((pallet, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPalletIds.includes(pallet.id)}
                            onChange={() => handlePalletChange(pallet.id)}
                            value={pallet.id}
                          />
                        }
                        label={pallet.tracking}
                        key={index}
                      />
                    ))}
                  </FormGroup>
                </div>
                <DownloadForRS rsData={rsData} />
              </div>
            </Box>
          </div>

          <div
            className="child_kanban_large"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8', marginTop: '70px' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <Typography variant="h6">Upload Data From RS</Typography>
            <Box className="headings">
              <div
                className="little_input_boxes"
                style={{
                  backgroundColor: settings.themeMode === 'light' ? '#ffffff' : '#161C24',
                }}
              >
                <UploadImageComponent files={files} setFiles={setFiles} hasPadding={false} />
                <Button
                  onClick={sendFilesTORS}
                  variant="contained"
                  disabled={!files?.length}
                  startIcon={<Iconify icon="material-symbols:upload" />}
                  style={{ fontSize: '0.7rem' }}
                >
                  UPLOAD
                </Button>
              </div>
            </Box>
          </div>
        </div>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
};
