import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@mui/material';

function AlertReturnFunction({ type, text, onClose }) {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return open ? (
    <div
      style={{
        position: 'absolute',
        zIndex: 1000,
        top: '10px',
        left: '50%',
      }}
    >
      <Alert severity={type}>{text}</Alert>
    </div>
  ) : null;
}

AlertReturnFunction.propTypes = {
  type: PropTypes.oneOf(['error', 'info', 'success', 'warning']),
  text: PropTypes.string.isRequired,
  onClose: PropTypes.func,
};

export default AlertReturnFunction;
