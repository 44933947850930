import { CsutomOrdersListView } from 'src/sections/customOrders/view';
import { Helmet } from 'react-helmet-async';

export default function CustomOrdersListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Live orders</title>
      </Helmet>
      <CsutomOrdersListView />
    </>
  );
}
