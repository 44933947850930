import * as React from 'react';
import { Box, FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';

export default function ComboBox({ nameOfInput, data = [], setter, value }) {
  const handleChange = (event, newValue) => {
    setter({ target: { name: nameOfInput, value: newValue.name } });
  };
  return (
    <Box sx={{ width: '100%' }}>
      <FormControl fullWidth variant="filled">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          value={data.find((city) => city.name === value) || null}
          onChange={handleChange}
          options={data}
          sx={{ width: '95%' }}
          renderInput={(params) => <TextField {...params} label={nameOfInput} />}
        />
      </FormControl>
    </Box>
  );
}

ComboBox.propTypes = {
  nameOfInput: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  setter: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};
