// @mui
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
// import Avatar from '@mui/material/Avatar';
import CardHeader from '@mui/material/CardHeader';
import ListItemText from '@mui/material/ListItemText';
// utils
// import { fCurrency } from 'src/utils/format-number';
// components
import Scrollbar from 'src/components/scrollbar';
import { Button } from '@mui/material';
import { useOrderActions } from 'src/context/providers/OrderContext';
import { useState } from 'react';

// ----------------------------------------------------------------------

export default function OrderDetailsItems({
  items,
  shipping,
  discount,
  taxes,
  subTotal,
  totalAmount,
  aRow,
}) {
  const { rejectPayment } = useOrderActions();
  const [status, newStatus] = useState('');
  const updatePayment = (e) => {
    rejectPayment(e.target.id)
      .then(() => newStatus('REJECTED'))
      .catch((error) => {
        console.error('Error rejecting payment:', error);
      });
  };
  return (
    <Card>
      <CardHeader title="Details" />
      <Stack
        sx={{
          px: 3,
        }}
      >
        <Scrollbar>
          {aRow?.payments?.map((item) => (
            <Stack
              key={item.id}
              direction="row"
              alignItems="center"
              sx={{
                py: 3,
              }}
            >
              <ListItemText
                primary={`Amount: ${item.amount}`}
                secondary={item.modified_at}
                primaryTypographyProps={{
                  typography: 'body2',
                }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                  mt: 0.5,
                }}
              />

              <Box sx={{ typography: 'body2', padding: '20px' }}>{status || item.status}</Box>
              {item.status === 'PENDING' && status !== 'REJECTED' ? (
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={(e) => {
                    updatePayment(e);
                  }}
                  id={item.id}
                >
                  REJECT
                </Button>
              ) : (
                ''
              )}
            </Stack>
          ))}
        </Scrollbar>
      </Stack>
    </Card>
  );
}

OrderDetailsItems.propTypes = {
  discount: PropTypes.number,
  items: PropTypes.array,
  shipping: PropTypes.number,
  subTotal: PropTypes.number,
  taxes: PropTypes.number,
  totalAmount: PropTypes.number,
  aRow: PropTypes.object,
};
