import TcTable from '../TcTable';
import { STATUS_OPTIONS, TABLE_HEAD, defaultFilters, tableNames } from './data';

export default function PaymentListView() {
  return (
    <TcTable
      STATUS_OPTIONS={STATUS_OPTIONS}
      TABLE_HEAD={TABLE_HEAD}
      defaultFilters={defaultFilters}
      tableNames={tableNames}
    />
  );
}

