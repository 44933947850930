import TcTable from '../TcTable';
import {
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  OTHER_STATUS_OPTIONS_FOR_ORDER,
  kanbanInput,
  TIME_SLOT_FOR_ORDER
} from './data';

export default function TimeSlotListView() {
  return (
    <TcTable
      STATUS_OPTIONS={STATUS_OPTIONS}
      TABLE_HEAD={TABLE_HEAD}
      defaultFilters={defaultFilters}
      tableNames={tableNames}
      OTHER_STATUS_OPTIONS_FOR_ORDER={OTHER_STATUS_OPTIONS_FOR_ORDER}
      kanbanInput={kanbanInput}
      TIME_SLOT_FOR_ORDER={TIME_SLOT_FOR_ORDER}
    />
  );
}
