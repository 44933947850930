import { Helmet } from 'react-helmet-async';
import StatusUpdate from 'src/sections/statusUpdate/view/kanban-list-view';

export default function StatusUpdateList() {
  return (
    <>
      <Helmet>
        <title>Status Update</title>
      </Helmet>

      <StatusUpdate />
    </>
  );
}
