// @mui
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';
// import SelectField from 'src/custom_components/Froms/select';
import { OrderBox, boxIMG, palletIMG } from 'src/assets/added_icons';
import { useCallback, useEffect, useState } from 'react';
// routes
// import { useParams } from 'react-router-dom';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';

//
import AlertReturnFunction from 'src/custom_components/Alert/AlertRetunrFunction';
import { usePalletActions, usePalletData } from 'src/context/providers/PalleteContext';
import { debounce } from 'lodash';
import { useOrderActions } from 'src/context/providers/OrderContext';
import { useShelfActions, useShelfData } from 'src/context/providers/ShelfContext';
// import { useCustomerData } from 'src/context/providers/CustomerContext';
import OrderDrawerComponent from '../order/order-drawer-component';

export default function TcTable({ TABLE_HEAD, defaultFilters, tableNames, kanbanInput }) {
  const [acceptanceShelf, setAcceptanceShelf] = useState('');
  const [alertData, setAlertData] = useState(null);
  const [orderIdAcceptance, setOrderIdAcceptance] = useState('');
  const settings = useSettingsContext();
  const { findShelfByOrder } = useShelfActions();

  // Custom
  // const tableData = useOrderData().orders;
  const [aRow, setARow] = useState({});
  const shelfDataAcceptance = useShelfData().shelves;
  const acceptanceShelfByID = shelfDataAcceptance.filter((i) => i.id === acceptanceShelf?.id);
  const { updateOrderByShelf } = useOrderActions();

  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const [pallet, setPallete] = useState({
    id: '',
    tracking: '',
  });
  const palleteData = usePalletData()?.Palletes;

  const palletByID = palleteData?.filter((item) => item.id === pallet?.id);
  const { getPalletes } = usePalletActions();
  // UI handlers
  // const handleShelfTypeChange = (event) =>
  //   setShelfType(event.target.checked ? 'DELIVERY' : 'ACCEPTANCE');

  const setShelfValue = (e) => {
    setAcceptanceShelf(e);
  };

  const updateAcceptance = (e) => {
    e.preventDefault();
    const updateOrderData = {
      tracking: orderIdAcceptance,
      shelf_id: acceptanceShelf,
    };
    updateOrderByShelf(updateOrderData).then(() => {
      setAcceptanceShelf('');
      setOrderIdAcceptance('');
    });
  };
  const clickedShelf = (e) => {};

  const closeAlert = () => {
    setAlertData(null);
  };
  // const setPalleteValue = (e) => {
  //   setPallete(e);
  // };

  const clickedBox = (e) => {};

  useEffect(() => {
    if (orderIdAcceptance) {
      findShelfByOrder(orderIdAcceptance)
        .then((shelf) => {
          console.log(shelf);
          // setSuggestedShelf(shelf);
        })
        .catch((error) => {
          console.error('Error fetching suggested shelf:', error);
        });
    }
  }, [orderIdAcceptance, findShelfByOrder]);

  const [loadingPallet, setLoadingPallet] = useState(false);
  const [palletOptions, setPalletOptions] = useState([]);
  const fetchPalletes = useCallback(
    async (filters) => {
      setLoadingPallet(true);
      try {
        const response = await getPalletes(filters); // Make API call with filters
        setPalletOptions(response?.pallets); // Assuming API returns an array of pallet data
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingPallet(false);
      }
    },
    [getPalletes]
  );

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchPalletes(initialFilters);
  }, [fetchPalletes]);
  // Fetch pallet data based on input

  const handlePalletInputChange = debounce((event, newInputValue) => {
    const filters = {
      tracking: newInputValue,
      take: 5,
    };
    fetchPalletes(filters);
  }, 500);

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {alertData && (
          <AlertReturnFunction onClose={closeAlert} type={alertData.type} text={alertData.text} />
        )}
        {Object.keys(aRow).length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}
        <CustomBreadcrumbs heading="Orders" sx={{ padding: '0px 3%' }} />

        <Box className="select_containers_large select_containers" sx={{ display: 'flex' }}>
          <div
            className="child_kanban"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <Box className="headings">
              <Typography variant="h6">
                Pallet {palletByID[0]?.tracking ? palletByID[0]?.tracking : ''}{' '}
              </Typography>
              <div className="number_of_item">
                {palletByID[0]?.orders?.length ? palletByID[0]?.orders?.length : 0}
              </div>
            </Box>
            <form
              className="little_input_boxes"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff', width: '45%' }
                  : { backgroundColor: '#161C24', width: '45%' }
              }
            >
              <Autocomplete
                sx={{ width: '100%' }}
                options={palletOptions}
                getOptionLabel={(option) => option.tracking || ''}
                value={pallet}
                onChange={(event, newValue) => setPallete(newValue)}
                onInputChange={handlePalletInputChange}
                loading={loadingPallet}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pallet Tracking"
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingPallet ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

              {/* <SelectField
                widthComp="100%"
                selectName="Pallet Tracking"
                data={palleteData}
                setter={setPalleteValue}
                selectedValue={pallet || null}
                name1="tracking"
              /> */}
            </form>
            <Box className="Parent_Kanban">
              <Box
                className="boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8' }
                    : { backgroundColor: '#2d353d' }
                }
              >
                {palletByID[0]?.orders?.length ? (
                  palletByID[0].orders.map((i, index) => (
                    <Box
                      className="box_items"
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#ffffff' }
                          : { backgroundColor: '#161C24' }
                      }
                      key={i.id}
                      data-box-id={i.id}
                      onClick={clickedBox}
                    >
                      <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.tracking_number}</div>
                        <div
                          className="light_text"
                          style={{ color: !i.declaration_status ? 'red' : 'green' }}
                        >
                          {i.declaration_status ? i.declaration_status : 'NOT DECLERED'}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.room_number}</div>
                        <div className="light_text">{i.status}</div>
                      </div>
                    </Box>
                  ))
                ) : (
                  <div className="notFoundContainer">
                    <img src={palletIMG} alt="" width="20%" />
                    <Typography variant="h6" style={{ fontSize: '18px' }}>
                      Selected pallete is empty
                    </Typography>
                    <Typography variant="h6" style={{ color: '#637381', fontSize: '14px' }}>
                      Choose desired pallet and start scanning items
                    </Typography>
                  </div>
                )}
              </Box>
            </Box>
          </div>
          <div
            className="child_kanban_large"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <div>
              <Box className="headings" sx={{ justifyContent: 'space-between' }}>
                {/* Switch UI */}
                {/* <FormControlLabel
                  control={
                    <Switch checked={shelfType === 'DELIVERY'} onChange={handleShelfTypeChange} />
                  }
                  label={shelfType === 'ACCEPTANCE' ? 'Acceptance Shelf' : 'Delivery Shelf'}
                /> */}
                {/* Switch UI */}
                <Box
                  sx={{
                    display: 'flex',
                    gap: '20px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant="h6">
                    Shelf {acceptanceShelfByID[0]?.name ? acceptanceShelfByID[0].name : ''}{' '}
                  </Typography>
                  <div className="number_of_item">
                    {acceptanceShelfByID[0]?.orders?.length
                      ? acceptanceShelfByID[0]?.orders?.length
                      : 0}
                  </div>
                </Box>
              </Box>
            </div>

            <form
              className="little_input_boxes flex_col"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff' }
                  : { backgroundColor: '#161C24' }
              }
              onSubmit={updateAcceptance}
            >
              <TextField
                variant="filled"
                style={{ padding: '0px', width: '100%' }}
                name="orderIdAcceptance"
                label="Tracking"
                required
                value={orderIdAcceptance}
                onChange={(e) => setOrderIdAcceptance(e.target.value)}
                name1="code"
              />
              <TextField
                variant="filled"
                style={{ padding: '0px', width: '100%' }}
                name="shelfTracking"
                label="shelf"
                required
                value={acceptanceShelf}
                onChange={(e) => setShelfValue(e.target.value)}
                name1="shelf"
              />

              <Button variant="contained" type="submit" style={{ width: '100%' }}>
                ADD
              </Button>
            </form>
            <Box className="Parent_Kanban">
              <Box
                className="boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8' }
                    : { backgroundColor: '#2d353d' }
                }
              >
                {acceptanceShelfByID[0]?.orders?.length ? (
                  acceptanceShelfByID[0]?.orders.map((i, index) => (
                    <Box
                      className="box_items"
                      key={i.id}
                      data-shelf-id={i.id}
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#ffffff' }
                          : { backgroundColor: '#161C24' }
                      }
                      onClick={clickedShelf}
                    >
                      <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />{' '}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.tracking_number}</div>
                        <div className="light_text">{i.name}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.room_number}</div>
                        <div className="light_text">{i.status}</div>
                      </div>
                    </Box>
                  ))
                ) : (
                  <div className="notFoundContainer">
                    <img
                      src={boxIMG}
                      alt=""
                      width="10%"
                      // width="20%"
                    />
                    <Typography variant="h6" style={{ fontSize: '18px' }}>
                      Selected shelf is empty
                    </Typography>
                    <Typography variant="h6" style={{ color: '#637381', fontSize: '14px' }}>
                      Choose shelf from menu and start scanning
                    </Typography>
                  </div>
                )}
              </Box>
            </Box>
          </div>
        </Box>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
};
