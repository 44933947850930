import { Helmet } from 'react-helmet-async';
import TimeSlotListView from 'src/sections/TimeSlots/view/TimeSlotListView-list-view';

export default function TimeSlotListPage() {
  return (
    <>
      <Helmet>
        <title>Time Slot</title>
      </Helmet>

      <TimeSlotListView />
    </>
  );
}
