import React, { useState } from 'react';
import { Button, Drawer, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useAddressActions } from 'src/context/providers/AddressContext';
import InputFields from 'src/custom_components/Froms/InputFields';
import { personalInformation } from './view/data';

const AddressDrawerComponent = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [AddressData, setAddressData] = useState({
    street: aRow.street || '',
    city: aRow.city || '',
    state: aRow.state || '',
    country: aRow.country || '',
    tel: aRow.tel || '',
    zipCode: aRow.zipCode || '',
  });

  const { updateAddress } = useAddressActions();

  const clear = () => {
    setAddressData({});
  };

  const handleUpdate = () => {
    const airwaybillUpdateData = {
      street: AddressData.street,
      city: AddressData.city,
      state: AddressData.state,
      country: AddressData.country,
      tel: AddressData.tel,
      zipCode: AddressData.zipCode,
    };
    updateAddress(aRow.id, airwaybillUpdateData);
    handleDrawerToggle();
    clear();
    setARow({});
  };

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setAddressData({
  //     ...AddressData,
  //     [name]: value,
  //   });
  // };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      clear();
      setARow({});
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        position: 'fixed',
        zIndex: 2,
        top: 0,
        left: 0,
        display: isDrawerOpen ? 'block' : 'none',
        cursor: 'pointer',
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: '35%', xs: '100%', md: '100%' } },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{`Edit Address - ${aRow.country}`}</Typography>
          <div
            style={{
              width: '10%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <InputFields
            userData={AddressData}
            heading="Address Information"
            inputFields={personalInformation}
            setUserData={setAddressData}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid rgba(220, 220, 220, 0.2)',
            padding: '16px',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            style={{ marginLeft: '24px', width: '136px' }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

AddressDrawerComponent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default AddressDrawerComponent;
