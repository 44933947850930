const TABLE_HEAD = [
  { id: 'country', label: 'Country', width: 320 },
  { id: 'currency', label: 'Currency', width: 380 },
  { id: 'price', label: 'price', width: 320 },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', width: 100 },
];

const defaultFilters = {
  name: '',
  country: 'country',
  currency: 'currency',
  role: [],
  status: 'all',
};
const tableNames = [
  { id: 'name', label: 'Country' },
  { id: 'currency', label: 'Currency' },
  { id: 'price', label: 'price' },
  { id: 'status', label: 'Status' },
];

const personalInformation = [
  {
    name: 'name',
    width: '48%',
    label: 'Name',
  },
  {
    name: 'currency',
    width: '48%',
    label: 'Currency',
  },
  {
    name: 'price',
    width: '96%',
    label: 'Price',
  },
];

const status_colors = {
  ACTIVE: ['#22C55E29', '#118D57'],
  INACTIVE: ['#919EAB29', '#637381'],
};

const status = ['ACTIVE', 'INACTIVE'];

export { TABLE_HEAD, defaultFilters, tableNames, status, personalInformation, status_colors };
