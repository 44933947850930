// @mui
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';
import { OrderBox, boxIMG, palletIMG } from 'src/assets/added_icons';
import { useCallback, useEffect, useState } from 'react';
// routes
// import { useParams } from 'react-router-dom';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';

//
import { debounce } from 'lodash';
import { useOrderActions, useOrderData } from 'src/context/providers/OrderContext';
import { useBoxActions, useBoxData } from 'src/context/providers/BoxContext';
import { usePalletActions, usePalletData } from 'src/context/providers/PalleteContext';
import OrderDrawerComponent from '../order/order-drawer-component';

export default function TcTable({ TABLE_HEAD, defaultFilters, tableNames, kanbanInput }) {
  const [box, setBox] = useState({
    id: '',
    tracking: '',
  });
  const [pallet, setPallete] = useState({
    id: '',
    tracking: '',
  });

  const [orderIdBox, setOrderIdBox] = useState('');
  const [orderIdPallet, setOrderIdPallete] = useState('');

  const [loadingPallet, setLoadingPallet] = useState(false);
  const [palletOptions, setPalletOptions] = useState([]);

  const settings = useSettingsContext();
  // Custom
  const tableData = useOrderData().orders;
  const [aRow, setARow] = useState({});
  const boxData = useBoxData()?.Boxes;
  const palleteData = usePalletData()?.Palletes;
  const boxByID = boxData?.filter((item) => item.id === box?.id);
  const palletByID = palleteData?.filter((item) => item.id === pallet?.id);
  const { updateOrderByBoxAndPallet } = useOrderActions();
  const { getPalletes } = usePalletActions();

  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const updateBoxData = (e) => {
    e.preventDefault();
    const updateOrderData = {
      tracking: orderIdBox,
      box_id: box.id,
    };
    updateOrderByBoxAndPallet(updateOrderData);
  };

  const updatePalleteData = (e) => {
    e.preventDefault();
    const updateOrderData = {
      tracking: orderIdPallet,
      pallet_id: pallet.id,
    };
    updateOrderByBoxAndPallet(updateOrderData);
  };
  const clickedBox = (e) => {
    const boxId = e.currentTarget.getAttribute('data-box-id');
    const rowFromRouter = tableData.filter((item) => item.id === boxId);
    if (rowFromRouter?.length) {
      setARow(rowFromRouter[0]);
      setIsDrawerOpen(true);
    }
  };

  const fetchPalletes = useCallback(
    async (filters) => {
      setLoadingPallet(true);
      try {
        const response = await getPalletes(filters); // Make API call with filters
        setPalletOptions(response?.pallets); // Assuming API returns an array of pallet data
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingPallet(false);
      }
    },
    [getPalletes]
  );

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchPalletes(initialFilters);
  }, [fetchPalletes]);
  // Fetch pallet data based on input

  const handlePalletInputChange = debounce((event, newInputValue) => {
    const filters = {
      tracking: newInputValue,
      take: 5,
    };
    fetchPalletes(filters);
  }, 500);

  // box logic

  const [loadingBox, setLoadingBox] = useState(false);
  const [boxOptions, setBoxOptions] = useState([]);

  const { getBoxes } = useBoxActions();

  const fetchBoxes = useCallback(
    async (filters) => {
      setLoadingBox(true);
      try {
        const response = await getBoxes(filters); // Make API call with filters
        setBoxOptions(response?.boxes); // Assuming API returns an array of box data
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingBox(false);
      }
    },
    [getBoxes]
  );

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchBoxes(initialFilters);
  }, [fetchBoxes]);

  const handleBoxInputChange = debounce((event, newInputValue) => {
    const filters = {
      tracking: newInputValue,
      take: 5,
    };
    fetchBoxes(filters);
  }, 500);

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {Object.keys(aRow)?.length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}
        <CustomBreadcrumbs heading="Orders" sx={{ padding: '0px 3%' }} />

        <div className="select_containers">
          <div
            className="child_kanban"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <div>
              <Box className="headings">
                <Typography variant="h6">
                  Box {boxByID[0]?.tracking ? boxByID[0].tracking : ''}{' '}
                </Typography>
                <div className="number_of_item">
                  {boxByID[0]?.orders?.length ? boxByID[0]?.orders?.length : 0}
                </div>
              </Box>
            </div>
            <form
              className="little_input_boxes"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff' }
                  : { backgroundColor: '#161C24' }
              }
              onSubmit={updateBoxData}
            >
              {/* <SelectField
                selectName="Box Tracking"
                data={boxData}
                setter={setBoxValue}
                selectedValue={box || null}
                widthComp="40%"
                name1="tracking"
              /> */}
              <Autocomplete
                sx={{ width: '40%' }}
                options={boxOptions}
                getOptionLabel={(option) => option.tracking || ''}
                value={box}
                onChange={(event, newValue) => setBox(newValue)}
                onInputChange={handleBoxInputChange}
                loading={loadingBox}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Box Tracking"
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingBox ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <TextField
                variant="filled"
                style={{ padding: '0px', width: '40%' }}
                name="OrderIDbox"
                label="Tracking"
                required
                value={orderIdBox}
                onChange={(e) => setOrderIdBox(e.target.value)}
              />
              <Button variant="contained" type="submit" style={{ width: '10%' }}>
                ADD
              </Button>
            </form>
            <Box className="Parent_Kanban">
              <Box
                className="boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8' }
                    : { backgroundColor: '#2d353d' }
                }
              >
                {boxByID[0]?.orders?.length ? (
                  boxByID[0]?.orders.map((i, index) => (
                    <Box
                      className="box_items"
                      key={i.id}
                      data-box-id={i.id}
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#ffffff' }
                          : { backgroundColor: '#161C24' }
                      }
                      onClick={clickedBox}
                    >
                      <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />{' '}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.tracking_number}</div>
                        <div
                          className="light_text"
                          style={{ color: !i.declaration_status ? 'red' : 'green' }}
                        >
                          {i.declaration_status ? i.declaration_status : 'NOT DECLERED'}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.room_number}</div>
                        <div className="light_text">{i.status}</div>
                      </div>
                    </Box>
                  ))
                ) : (
                  <div className="notFoundContainer">
                    <img src={boxIMG} alt="" width="20%" />
                    <Typography variant="h6" style={{ fontSize: '18px' }}>
                      Selected box is empty
                    </Typography>
                    <Typography variant="h6" style={{ color: '#637381', fontSize: '14px' }}>
                      Choose box from menu and start scanning
                    </Typography>
                  </div>
                )}
              </Box>
            </Box>
          </div>
          <div
            className="child_kanban"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <Box className="headings">
              <Typography variant="h6">
                Pallet {palletByID[0]?.tracking ? palletByID[0]?.tracking : ''}{' '}
              </Typography>
              <div className="number_of_item">
                {palletByID[0]?.orders?.length ? palletByID[0]?.orders?.length : 0}
              </div>
            </Box>
            <form
              className="little_input_boxes"
              onSubmit={updatePalleteData}
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff' }
                  : { backgroundColor: '#161C24' }
              }
            >
              {/* <SelectField
                widthComp="40%"
                selectName="Pallet Tracking"
                data={palleteData}
                setter={setPalleteValue}
                selectedValue={pallet || null}
                name1="tracking"
              /> */}

              <Autocomplete
                sx={{ width: '40%' }}
                options={palletOptions}
                getOptionLabel={(option) => option.tracking || ''}
                value={pallet}
                onChange={(event, newValue) => setPallete(newValue)}
                onInputChange={handlePalletInputChange}
                loading={loadingPallet}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pallet Tracking"
                    variant="filled"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingPallet ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              <TextField
                variant="filled"
                style={{ width: '40%' }}
                name="orderIDPallet"
                label="Tracking"
                value={orderIdPallet}
                required
                onChange={(e) => setOrderIdPallete(e.target.value)}
              />
              <Button variant="contained" type="submit" style={{ width: '10%' }}>
                ADD
              </Button>
            </form>
            <Box className="Parent_Kanban">
              <Box
                className="boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8' }
                    : { backgroundColor: '#2d353d' }
                }
              >
                {palletByID[0]?.orders?.length ? (
                  palletByID[0].orders.map((i, index) => (
                    <Box
                      className="box_items"
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#ffffff' }
                          : { backgroundColor: '#161C24' }
                      }
                      key={i.id}
                      data-box-id={i.id}
                      onClick={clickedBox}
                    >
                      <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.tracking_number}</div>
                        <div
                          className="light_text"
                          style={{ color: !i.declaration_status ? 'red' : 'green' }}
                        >
                          {i.declaration_status ? i.declaration_status : 'NOT DECLERED'}
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.room_number}</div>
                        <div className="light_text">{i.status}</div>
                      </div>
                    </Box>
                  ))
                ) : (
                  <div className="notFoundContainer">
                    <img src={palletIMG} alt="" width="20%" />
                    <Typography variant="h6" style={{ fontSize: '18px' }}>
                      Selected pallete is empty
                    </Typography>
                    <Typography variant="h6" style={{ color: '#637381', fontSize: '14px' }}>
                      Choose desired pallet and start scanning items
                    </Typography>
                  </div>
                )}
              </Box>
            </Box>
          </div>
        </div>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
};
