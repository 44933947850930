// BranchContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types'; // @mui
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const BranchContext = createContext();
const BranchActionsContext = createContext();

export const BranchProvider = ({ children }) => {
  const [branches, setBranches] = useState([]);
  const createBranch = async (newBranch) => {
    try {
      // Simulate API call to create branch
      const response = await apiService.post('/branches', newBranch, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      toast.success('Branch created!');
      // eslint-disable-next-line
      const data = await response.data;
       getBranches()
    } catch (error) {
      toast.error('Error creating branch!');
      console.error('Error creating branch:', error);
    }
  };

  const deleteBranch = async (branchId) => {
    try {
      await apiService.put(
        '/branches/update/soft',
        { ids: branchId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setBranches((prevBranches) => prevBranches.filter((branch) => !branchId.includes(branch.id)));
      toast.success('Branch deleted!');
    } catch (error) {
      toast.error('Error deleting branch!');
      console.error('Error deleting branch:', error);
    }
  };
  const updateBranch = async (branchId, updatedBranch) => {
    try {
      // Simulate API call to update branch
      const response = await apiService.put(`/branches/${branchId}`, updatedBranch, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setBranches((prevBranches) =>
        prevBranches.map((branch) => (branch.id === branchId ? { ...branch, ...data } : branch))
      );
      toast.success('Branch updated!');
    } catch (error) {
      console.error('Error updating branch:', error);
      console.error('Error updating branch:', error);
    }
  };

  const getBranches = async () => {
    try {
      // Simulate API call to get branches
      const response = await apiService.get(`/branches`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setBranches(data);
    } catch (error) {
      console.error('Error fetching branches:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
    }
  };

  useEffect(() => {
    getBranches();
  }, []);

  const createBranchCallback = useCallback(createBranch, []);
  const deleteBranchCallback = useCallback(deleteBranch, []);
  const updateBranchCallback = useCallback(updateBranch, [updateBranch]); // Add the missing dependency here
  const getBranchesCallback = useCallback(getBranches, []); // Add the missing dependency here

  const branchActionsValue = useMemo(
    () => ({
      createBranch: createBranchCallback,
      deleteBranch: deleteBranchCallback,
      updateBranch: updateBranchCallback,
      getBranches: getBranchesCallback,
    }),
    [createBranchCallback, deleteBranchCallback, updateBranchCallback, getBranchesCallback]
  );

  const branchesValue = useMemo(() => branches, [branches]);

  return (
    <BranchContext.Provider value={branchesValue}>
      <BranchActionsContext.Provider value={branchActionsValue}>
        {children}
      </BranchActionsContext.Provider>
    </BranchContext.Provider>
  );
};

export const useBranchData = () => useContext(BranchContext);
export const useBranchActions = () => useContext(BranchActionsContext);

BranchProvider.propTypes = {
  children: PropTypes.element,
};
