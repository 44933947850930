import React, { useState } from 'react';
import { Button, Drawer, IconButton, MenuItem, Typography, Select, Box } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useAirwayBillActions } from 'src/context/providers/AirwayBillContext';
import DatePickerValue from 'src/custom_components/Froms/datePicker';
import { usePalletData } from 'src/context/providers/PalleteContext';
import InputFields from 'src/custom_components/Froms/InputFields';
import { status, personalInformation } from './view/data';

const AirwaybillDrawerComponent = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [airwaybillData, setAirwaybillData] = useState({
    name: aRow.name || '',
    tracking: aRow.tracking || '',
    status: aRow.status || '',
    airway_date: aRow.airway_date || '',
  });

  const { updateAirwayBill } = useAirwayBillActions();

  const clear = () => {
    setAirwaybillData({});
  };
  const handleUpdate = () => {
    const airwaybillUpdateData = {
      id: aRow.id,
      name: airwaybillData.name,
      tracking: airwaybillData.tracking,
      status: airwaybillData.status,
      airway_date: airwaybillData.airway_date,
    };
    updateAirwayBill(aRow.id, airwaybillUpdateData);
    handleDrawerToggle();
    clear();
    setARow({});
  };
  const palletes = usePalletData()?.Palletes?.filter((pallete) => pallete?.airwaybill_id === aRow?.id);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAirwaybillData({
      ...airwaybillData,
      [name]: value,
    });
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      clear();
      setARow({});
    }
  };

  const renderPalletes = palletes?.map((i) => (
    <Box sx={{ border: '2px dashed #F6F8F9', padding: '5px', borderRadius: '10px' }}>
      <Typography>{i?.tracking}</Typography>
    </Box>
  ));

  return (
    <div
      role="button"
      tabIndex={0}
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        position: 'fixed',
        zIndex: 2,
        top: 0,
        left: 0,
        display: isDrawerOpen ? 'block' : 'none',
        cursor: 'pointer',
      }}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: '35%', xs: '100%', md: '100%' } },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{`Edit Airwaybill - ${aRow.name}`}</Typography>
          <div
            style={{
              width: '40%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {Object.keys(airwaybillData).map((key) => {
              if (key === 'status') {
                return (
                  <Select
                    key={key}
                    name={key}
                    value={airwaybillData[key]}
                    onChange={handleInputChange}
                    variant="outlined"
                    sx={{ width: '70%', height: '40px' }}
                  >
                    {status.map((i, index) => (
                      <MenuItem key={index} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }
              return null;
            })}
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          <InputFields
            userData={airwaybillData}
            heading="Airwaybill Information"
            inputFields={personalInformation}
            setUserData={setAirwaybillData}
          />
          <div
            className="content-wrap"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '0px 0px 0px 20px',
            }}
          >
            <DatePickerValue
              setter={handleInputChange}
              value={new Date(airwaybillData.airway_date)}
              selectName="airway_date"
            />
          </div>
          {palletes?.length ? (
            <div
              className="content-wrap"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '20px 0px 0px 20px',
              }}
            >
              <Typography variant="primary">Pallets</Typography>

              {renderPalletes}
            </div>
          ) : (
            ''
          )}
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            borderTop: '1px solid rgba(220, 220, 220, 0.2)',
            padding: '16px',
            position: 'absolute',
            bottom: '0',
            left: '0',
            width: '100%',
          }}
        >
          <Button
            variant="contained"
            style={{ marginLeft: '24px', width: '136px' }}
            onClick={handleUpdate}
          >
            Update
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

AirwaybillDrawerComponent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default AirwaybillDrawerComponent;
