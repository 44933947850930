// AddressContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const AddressContext = createContext();
const AddressActionsContext = createContext();
const SelectedAddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});

  const createAddress = async (newAddress) => {
    try {
      const response = await apiService.post('/addresses', newAddress, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setAddresses((prevAddresses) => [...prevAddresses, data]);
      toast.success('Address created!');
    } catch (error) {
      console.error('Error creating Address:', error);
      toast.error('Error creating Address!');
    }
  };

  const deleteAddress = async (addressIds) => {
    try {
      await apiService.put(
        '/addresses/update/soft',
        { ids: addressIds },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setAddresses((prevAddresses) =>
        prevAddresses.filter((address) => !addressIds.includes(address.id))
      );
      toast.success('Address deleted!');
    } catch (error) {
      console.error('Error deleting Address:', error);
      toast.error('Error deleting Address!');
    }
  };

  const updateAddress = async (addressId, updatedAddress) => {
    try {
      const response = await apiService.patch(`/addresses/${addressId}`, updatedAddress, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setAddresses((prevAddresses) =>
        prevAddresses.map((address) =>
          address.id === addressId ? { ...address, ...data } : address
        )
      );
      toast.success('Address updated!');
    } catch (error) {
      console.error('Error updating Address:', error);
      toast.error('Error updating Address!');
    }
  };

  const getAddresses = async () => {
    try {
      const response = await apiService.get(`/addresses`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setAddresses(data);
      return data;
    } catch (error) {
      console.error('Error fetching Addresses:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
      return error;
    }
  };

  const getAddressById = async (addressId) => {
    try {
      const response = await apiService.get(`/addresses/${addressId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setSelectedAddress(data);
      return data;
    } catch (error) {
      console.error('Error fetching Address by ID:', error);
      throw error;
    }
  };

  useEffect(() => {
    getAddresses();
  }, []);

  const createAddressCallback = useCallback(createAddress, []);
  const deleteAddressCallback = useCallback(deleteAddress, []);
  const updateAddressCallback = useCallback(updateAddress, [updateAddress]);
  const getAddressesCallback = useCallback(getAddresses, []);

  const AddressActionsValue = useMemo(
    () => ({
      createAddress: createAddressCallback,
      deleteAddress: deleteAddressCallback,
      updateAddress: updateAddressCallback,
      getAddresses: getAddressesCallback,
      getAddressById,
    }),
    [createAddressCallback, deleteAddressCallback, updateAddressCallback, getAddressesCallback]
  );

  const AddressesValue = useMemo(() => addresses, [addresses]);

  return (
    <AddressContext.Provider value={AddressesValue}>
      <AddressActionsContext.Provider value={AddressActionsValue}>
        <SelectedAddressContext.Provider value={selectedAddress}>
          {children}
        </SelectedAddressContext.Provider>
      </AddressActionsContext.Provider>
    </AddressContext.Provider>
  );
};

export const useAddressData = () => useContext(AddressContext);
export const useAddressActions = () => useContext(AddressActionsContext);
export const useSelectedAddress = () => useContext(SelectedAddressContext);

AddressProvider.propTypes = {
  children: PropTypes.element,
};
