import axios from 'axios';

// const API_BASE_URL = 'http://localhost:4001';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// const API_BASE_URL = 'https://api.gzavnili.com';
// const API_BASE_URL = 'http://192.168.102.51:3000/';

export const apiService = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
});

export const getCountries = () => apiService.get('/countries');
export const postCountries = (data) => apiService.post('/countries', data);
export const updateCountries = (country_id, data) =>
  apiService.put(`/countries/${country_id}`, data);

export { API_BASE_URL };
