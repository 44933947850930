import { Helmet } from 'react-helmet-async';
import PaymentListView from 'src/sections/payments/view/payment-list-view';

export default function PaymentListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Payment</title>
      </Helmet>

      <PaymentListView />
    </>
  );
}
