import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TodayIcon from '@mui/icons-material/Today';
import EventIcon from '@mui/icons-material/Event';
import PropTypes from 'prop-types';

function SwitcherComponent({ setDate }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      setDate('today');
    }
    if (newValue === 1) {
      setDate('tomorrow');
    }
    setValue(newValue);
  };

  return (
    <Tabs value={value} onChange={handleChange} aria-label="icon position tabs example">
      <Tab icon={<TodayIcon />} iconPosition="start" id="1" label="Today" />
      <Tab icon={<EventIcon />} iconPosition="end" id="2" label="Tomorrow" />
    </Tabs>
  );
}

SwitcherComponent.propTypes = {
  setDate: PropTypes.func.isRequired,
};

export default SwitcherComponent;
