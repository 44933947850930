import { useState } from 'react';
import {
  Button,
  Drawer,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useContentActions } from 'src/context/providers/ContentContext';
import { inputDataRoles } from './view/data';

const RoleCreation = ({ isDrawerOpen, handleDrawerToggle }) => {
  const { createNewRoles } = useContentActions();
  const [userLocation, setUserLocation] = useState('');
  const [roles, setRoles] = useState('');

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '35%',
    drawerHeaderText: 'Add Roles',
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
    }
  };

  const handleChange = (event) => {
    setUserLocation(event.target.value);
  };
  const handleRoleChange = (event) => {
    setRoles(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    const values = {};
    for (let i = 0; i < form.elements.length; i += 1) {
      const element = form.elements[i];
      if (element.name) {
        values[element.name] = element.value;
      }
    }
    values.location = userLocation;
    values.role = roles;

    createNewRoles(values);
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
        </div>

        {/* Form */}

        <form style={{ padding: '20px' }} onSubmit={(e) => handleSubmit(e)}>
          {inputDataRoles.map((dataObject, index) => (
            <div key={index} style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
              {Object.keys(dataObject).map((fieldKey) => (
                <TextField
                  required
                  key={fieldKey}
                  label={
                    fieldKey.replace('_', ' ').charAt(0).toUpperCase() +
                    fieldKey.replace('_', ' ').slice(1)
                  }
                  id={fieldKey}
                  name={fieldKey}
                  type={fieldKey === 'password' ? 'password' : 'text'}
                  variant="outlined"
                  style={{ marginBottom: '10px', width: '48%' }}
                />
              ))}
              <FormControl variant="outlined" style={{ marginBottom: '10px', width: '48%' }}>
                <InputLabel id="demo-simple-select-label">Choose Location</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  onChange={handleChange}
                  value={userLocation}
                >
                  <MenuItem value="Georgia">Georgia</MenuItem>
                  <MenuItem value="China">China</MenuItem>
                  <MenuItem value="USA">USA</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ marginBottom: '10px', width: '48%' }}>
                <InputLabel id="demo-simple-select-label">Choose Role</InputLabel>
                <Select
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  onChange={handleRoleChange}
                  value={roles}
                >
                  <MenuItem value="Admin">Admin</MenuItem>
                  <MenuItem value="Sysadmin">Sysadmin</MenuItem>
                  <MenuItem value="ViewOnly">ViewOnly</MenuItem>
                </Select>
              </FormControl>
            </div>
          ))}
          <Button
            variant="contained"
            style={{
              width: '20%',
              float: 'right',
              margin: '20px',
            }}
            type="submit"
          >
            Create
          </Button>
        </form>
      </Drawer>
    </div>
  );
};

RoleCreation.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default RoleCreation;
