import { Helmet } from 'react-helmet-async';
import ShelfListView from 'src/sections/shelf/view/shelf-list-view';

export default function ShelfListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Shelf</title>
      </Helmet>

      <ShelfListView />
    </>
  );
}
