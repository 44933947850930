import {
  Button,
  Drawer,
  IconButton,
  MenuItem,
  Typography,
  Avatar,
  Select,
  CardHeader,
  Stack,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
// import OrderList from 'src/custom_components/orderList/OrderList';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { PATH_DASHBOARD } from 'src/config-global';
import Iconify from 'src/components/iconify';
import { usePaymentActions } from 'src/context/providers/PaymentContext';
import { useEffect, useState } from 'react';
import { personalInformation, contactInformation, status, status_colors } from './view/data';
import InputFieds from '../../custom_components/Froms/InputFields';
import CustomizedSwitches from '../../custom_components/Froms/InputSwitcher';
import { useCustomerActions } from '../../context/providers/CustomerContext';
import '../../styles/dashboard.scss';

const CustomerDrawerComponent = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const { updateCustomer, getCustomerCards, deleteCard } = useCustomerActions();
  const [userData, setUserData] = useState({
    id: aRow.id || '',
    first_name: aRow.first_name || '',
    last_name: aRow.last_name || '',
    room_number: aRow.room_number || '',
    first_name_GE: aRow.first_name_ka || '',
    last_name_GE: aRow.last_name_ka || '',
    email: aRow.email || '',
    phone: aRow.phone || '',
    verified: aRow.verified || '',
    status: aRow.status || '',
    promotion: aRow.promotion || null,
    company_id: aRow.company_id || null,
    company_info: aRow.company_info || null,
    id_number: aRow.id_number || null,
    repackaging: aRow.repackaging || null,
    insurance: aRow.insurance || null,
    marketing_permissions: aRow.marketing_permissions || null,
    registered: aRow.registered || null,
  });
  const { getPaymentByID } = usePaymentActions();
  const [paymentDetails, setPaymentDetails] = useState(null);

  const DrawerConfig = {
    SubNavigation: false,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: 'Manage Customer Information',
    SubNav: true,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };
  const [cards, setCards] = useState([]);
  const renderShipping = (
    <>
      <CardHeader title="Customer Addresses" />
      <Stack sx={{ pl: 3, pt: 1, pb: 2, typography: 'body2' }}>
        <Stack sx={{ color: 'green', display: 'flex', flexDirection: 'column' }}>
          {aRow?.customerAddresses.map((i) => (
            <Box component="span" sx={{ color: 'text.secondary' }}>
              {i?.address}
            </Box>
          ))}
        </Stack>
      </Stack>
    </>
  );
  // eslint-disable-next-line
  const renderCards = (
    <>
      <CardHeader title="Cards" sx={{ mb: 2 }} />
      {cards?.map((item) => (
        <Stack direction="row" alignItems="center" sx={{ p: 3, typography: 'body2' }} key={item.id}>
          <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
            Card {item?.cardType === 'mc' ? 'MasterCard' : 'Visa'}
          </Box>

          {item?.mask}
          <Iconify
            icon={item?.cardType === 'mc' ? 'logos:mastercard' : 'logos:visa'}
            width={24}
            sx={{ ml: 0.5 }}
          />
          <Button
            variant="outlined"
            color="error"
            sx={{ ml: 10 }}
            onClick={(e) => handleDeleteCardClick(item.id)}
          >
            Delete
          </Button>
        </Stack>
      ))}
    </>
  );
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      setARow({});
      handleDrawerToggle();
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const deleteCardCustomer = (cardId) => {
    deleteCard(aRow.id, cardId).then(() => {
      setCards(cards.filter((card) => card.id !== cardId));
    });
  };
  const handleDeleteCardClick = (cardId) => {
    handleOpenDialog(cardId);
  };

  const UpdateCustomerData = () => {
    const customerId = aRow.id;
    const updatedCustomerData = {
      id: customerId,
      first_name: userData.first_name,
      last_name: userData.last_name,
      first_name_ka: userData.first_name_GE,
      last_name_ka: userData.last_name_GE,
      email: userData.email,
      phone: userData.phone,
      verified: userData.verified,
      promotion: userData.promotion,
      company_id: userData.company_id,
      company_info: userData.company_info,
      repackaging: userData.repackaging,
      insurance: userData.insurance,
      marketing_permissions: userData.marketing_permissions,
    };
    updateCustomer(customerId, updatedCustomerData);
    handleOutsideClick();
  };
  /// payments
  useEffect(() => {
    if (aRow?.user_id) {
      getCustomerCards(aRow.user_id).then((data) => setCards(data));
    }
  }, [aRow, getCustomerCards]);

  useEffect(() => {
    if (aRow?.id) {
      getPaymentByID(aRow.id).then((data) => setPaymentDetails(data));
    }
  }, [aRow, getPaymentByID]);

  // Render payment details
  // Render payment details
  const [activeNavItem, setActiveNavItem] = useState('Customer Details');
  // const [originalData, setOriginalData] = useState(aRow);
  const [navActive, setNavActive] = useState('i');
  // useMemo(() => {
  //   setOriginalData(aRow);
  // }, [aRow]);

  const handlePaymentClick = (paymentId) => {
    const url = `${PATH_DASHBOARD.payment.root}/${paymentId}`;
    window.open(url, '_blank');
  };
  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
  };
  const getNavItemStyle = (item) => {
    if (item === activeNavItem) {
      return {
        width: '50%',
        padding: '0px',
        textAlign: 'center',
        borderBottom: '3px solid rgba(49, 134, 136, 0.4)',
      };
    }
    return {
      width: '50%',
      padding: '16px',
      textAlign: 'center',
    };
  };

  const handleNavKeyPress = (event, item) => {
    if (event.key === 'Enter') {
      handleNavItemClick(item);
    }
  };
  // Render payment details
  const renderPaymentDetails = paymentDetails?.length ? (
    <>
      <CardHeader title="Payment Details" sx={{ mb: 2 }} />

      {paymentDetails.map((item) => (
        <Box
          key={item.date}
          sx={{ p: 2, border: '1px dashed #DFE3E8', borderRadius: '10px', m: 1 }}
        >
          <Typography variant="h6">{item.date}</Typography>
          {item?.transactions?.map((transaction, index) => (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                p: 1,
                typography: 'body2',
                cursor: 'pointer',
                border: '0.5px solid  #DFE3E8',
                m: 1,
              }} // Add cursor pointer
              key={index}
              onClick={() => handlePaymentClick(transaction.id)}
            >
              <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
                Amount: {transaction?.amount}
              </Box>
              <Box
                component="span"
                sx={{ color: 'text.secondary', flexGrow: 1, textAlign: 'right' }}
              >
                {transaction?.status}
              </Box>
            </Stack>
          ))}
        </Box>
      ))}
    </>
  ) : null;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const handleOpenDialog = (cardId) => {
    setSelectedCardId(cardId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedCardId(null);
  };

  const handleConfirmDelete = () => {
    deleteCardCustomer(selectedCardId);
    handleCloseDialog();
  };

  const renderConfirmationDialog = (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Delete Card</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete this card?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button onClick={handleConfirmDelete} color="error" autoFocus variant="outlined">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          setARow({});
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={() => {
          setARow({});
          handleDrawerToggle();
        }}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '20px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Avatar
              sx={{
                bgcolor: '#DFE3E8',
                color: '#637381',
                borderRadius: '10px',
                width: '32px',
                height: '32px',
                fontSize: '16px',
              }}
              variant="square"
            >
              {userData?.first_name[0]?.toUpperCase()}
            </Avatar>
            <Typography variant="h6">
              {userData?.first_name} {userData?.last_name}
            </Typography>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            {Object.keys(userData).map((key) => {
              if (key === 'status') {
                return (
                  <Select
                    key={key}
                    name={key}
                    value={userData[key]}
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: status_colors[userData.status]
                        ? status_colors[userData.status][0]
                        : 'red',
                      color: status_colors[userData.status]
                        ? status_colors[userData.status][1]
                        : 'red',
                    }}
                    variant="outlined"
                    sx={{ width: '70%', height: '40px' }}
                  >
                    {status.map((i, index) => (
                      <MenuItem key={index} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }
              return null;
            })}
            <IconButton
              onClick={() => {
                handleDrawerToggle();
                setARow({});
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        {/* attached orders */}

        {DrawerConfig.SubNav && (
          <div>
            <div style={{ display: 'flex', paddingTop: '10px' }}>
              <div
                role="button"
                tabIndex={0}
                style={getNavItemStyle('Customer Details')}
                onClick={() => {
                  handleNavItemClick('Customer Details');
                  setNavActive('i');
                }}
                onKeyPress={(event) => handleNavKeyPress(event, 'Customer Details')}
              >
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', paddingTop: '15px' }}>
                  Customer Information
                </Typography>
              </div>
              <div
                role="button"
                tabIndex={0}
                style={getNavItemStyle('Order History')}
                onClick={() => {
                  handleNavItemClick('Order History');
                  setNavActive('d');
                }}
                onKeyPress={(event) => handleNavKeyPress(event, 'Order History')}
              >
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', paddingTop: '15px' }}>
                  Payments
                </Typography>
              </div>
            </div>
          </div>
        )}
        {/* <OrderList aRow={aRow} /> */}
        {/* attached orders */}

        {navActive === 'i' && (
          <>
            <div>
              {/* Personal Information */}
              <InputFieds
                userData={userData}
                heading="Personal Information"
                inputFields={personalInformation}
                setUserData={setUserData}
              />
              {/* Personal Information */}

              {/* Contact Information */}

              <InputFieds
                userData={userData}
                heading="Contact Information"
                inputFields={contactInformation}
                setUserData={setUserData}
              />

              {/* Contact Information */}

              {/* Address */}

              {/* <InputFieds
          userData={userData}
          heading="Address"
          inputFields={addressInformation}
          setUserData={setUserData}
        /> */}

              {/* Address */}

              {/* InputSwitcher */}
              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  padding: '30px 0px 20px 20px',
                }}
              >
                <CustomizedSwitches
                  text="verified"
                  isChecked={userData.verified}
                  userData={userData}
                  setUserData={setUserData}
                />
                <CustomizedSwitches
                  text="marketing permissions"
                  isChecked={userData.marketing_permissions}
                  userData={userData}
                  setUserData={setUserData}
                />
                {/* <CustomizedSwitches
              text="balance"
              isChecked={userData.balance}
              userData={userData}
              setUserData={setUserData}
            /> */}
              </div>

              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  padding: '30px 0px 20px 20px',
                }}
              >
                <CustomizedSwitches
                  text="repackaging"
                  isChecked={userData.repackaging}
                  userData={userData}
                  setUserData={setUserData}
                />
                <CustomizedSwitches
                  text="insurance"
                  isChecked={userData.insurance}
                  userData={userData}
                  setUserData={setUserData}
                />
              </div>

              {/* InputSwitcher */}
            </div>

            {renderShipping}
            {renderCards}
            {renderConfirmationDialog}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(220, 220, 220, 0.2)',
                padding: '30px',
              }}
            >
              <Button variant="contained" onClick={() => UpdateCustomerData()}>
                Update
              </Button>
            </div>
          </>
        )}

        {navActive === 'd' && renderPaymentDetails}
      </Drawer>
    </div>
  );
};

CustomerDrawerComponent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponent;
