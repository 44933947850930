import React from 'react';
import { TextField, Typography, InputAdornment, IconButton } from '@mui/material';
import CopyAllIcon from '@mui/icons-material/FileCopy';
import PropTypes from 'prop-types';

export default function TextAreaFields({
  heading = '',
  userData = [],
  setUserData,
  inputFields = [],
  hasCopy = false,
  noPadding = false,
}) {
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleCopyText = (text) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <div
      className="content-wrap"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        padding: noPadding ? '' : '30px 0px 0px 20px',
      }}
    >
      <Typography gutterBottom className="subTitles">
        {heading}
      </Typography>

      <div>
        {inputFields.map((field, index) => (
          <TextField
            key={index}
            variant="filled"
            style={{ padding: '0px 10px 15px 0px' }}
            name={field.name}
            label={field.label}
            value={userData[field.name] || ''}
            onChange={handleInputChange}
            type={field.type ? 'number' : 'string'}
            sx={{ width: field.width }}
            multiline
            disabled={field.disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {hasCopy ? (
                    <IconButton onClick={() => handleCopyText(userData[field.name])}>
                      <CopyAllIcon sx={{ fontSize: 20 }} />
                    </IconButton>
                  ) : (
                    ''
                  )}
                </InputAdornment>
              ),
            }}
          />
        ))}
      </div>
    </div>
  );
}

TextAreaFields.propTypes = {
  heading: PropTypes.string,
  userData: PropTypes.object,
  setUserData: PropTypes.func,
  inputFields: PropTypes.array,
  hasCopy: PropTypes.bool,
  noPadding: PropTypes.bool,
};
