import { Drawer, IconButton, Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import OrderList from 'src/custom_components/orderList/OrderList';
import { usePaymentActions } from 'src/context/providers/PaymentContext';
import { personalInformation } from './view/data';
import InputFieds from '../../custom_components/Froms/InputFields';
import '../../styles/dashboard.scss';

const PaymentDrawerComponent = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  // eslint-disable-next-line
  const [userData, setUserData] = useState({
    customer_room_number: aRow?.customer_room_number || '',
  });
  const [refundAmount, setRefundAmount] = useState('');

  const { refundPayment } = usePaymentActions();
  const DrawerConfig = {
    SubNavigation: false,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: 'Manage Customer Information',
    SubNav: true,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      setARow({});
      handleDrawerToggle();
    }
  };
  const refundP = () => {
    refundPayment(aRow.id, refundAmount);
  };
  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          setARow({});
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={() => {
          setARow({});
          handleDrawerToggle();
        }}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}
        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '20px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <IconButton
            onClick={() => {
              handleDrawerToggle();
              setARow({});
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* attached orders */}
        <OrderList aRow={aRow} />
        {/* attached orders */}
        <div>
          {/* Personal Information */}
          <InputFieds
            userData={aRow}
            heading="Personal Information"
            inputFields={personalInformation}
            setUserData={setUserData}
          />

          {/* InputSwitcher */}
        </div>
        {aRow.status === 'COMPLETED' ? (
          <div style={{ padding: '20px', display: 'flex', alignItems: 'center', gap: '20px' }}>
            <TextField
              label="Refund Amount"
              variant="outlined"
              sx={{ width: '50%' }}
              fullWidth
              value={refundAmount}
              onChange={(e) => setRefundAmount(e.target.value)}
            />

            <div>
              <Button
                variant="contained"
                color="success"
                sx={{ width: '30%', float: 'right', margin: '5%' }}
                onClick={refundP}
              >
                Refund
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
      </Drawer>
    </div>
  );
};

PaymentDrawerComponent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default PaymentDrawerComponent;
