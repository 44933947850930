import { Button, Drawer, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import TextAreaFields from 'src/custom_components/Froms/textAreaField';

// import { useCountryData } from 'src/context/providers/CountryContext';
import { useContentActions } from 'src/context/providers/ContentContext';
import { personalInformation } from './view/data';

const CustomerDrawerComponentContent = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [contentData, setContentData] = useState({
    nameKa: aRow.nameKa || '',
    nameEn: aRow.nameEn || '',
  });

  const { updateContentItem } = useContentActions();
  // const content = useContentData();

  // const FormItemValueSeter = (e, setter) => {
  //   setter(e.target.value);
  // };
  const Clear = () => {
    setContentData({});
  };

  const handleUpdate = () => {
    const contentUpdateData = {
      id: aRow.id,
      nameKa: contentData.nameKa,
      nameEn: contentData.nameEn,
    };
    updateContentItem(aRow.id, contentUpdateData);
    handleDrawerToggle();
    Clear();
    setARow({});
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '35%',
    drawerHeaderText: `Edit content - ${aRow.id}`,
    buttons: {
      status: true,
      elements: [{ name: 'Update', onClick: handleUpdate, disabled: false }],
    },
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };
 // eslint-disable-next-line
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setContentData({
      ...contentData,
      [name]: value,
    });
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
      setARow({});
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          Clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>

          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Form */}

        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            minHeight: '80vh',
          }}
        >
          {/* Personal Information */}
          <TextAreaFields
            userData={contentData}
            heading="Content Information"
            inputFields={personalInformation}
            setUserData={setContentData}
          />

          {/* Personal Information */}
        </div>
        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '16px',
            }}
          >
            {DrawerConfig.buttons.elements.map((element) => (
              <Button
                variant="contained"
                style={{
                  marginLeft: '24px',
                  position: 'relative',
                  width: '136px',
                }}
                disabled={element.disabled}
                onClick={element.onClick}
              >
                {element.name}
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CustomerDrawerComponentContent.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponentContent;
