// AirwayBillContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const AirwayBillContext = createContext();
const AirwayBillActionsContext = createContext();
const AirwayBillDetailsContext = createContext();

export const AirwayBillProvider = ({ children }) => {
  const [airwayBills, setAirwayBills] = useState([]);
  const [selectedAirwayBill, setSelectedAirwayBill] = useState([]);
  const [airwayBillRecordDetails, setAirwayBillRecordDetails] = useState({});
  const createAirwayBill = async (newAirwayBill) => {
    try {
      const response = await apiService.post('/airwaybills', newAirwayBill, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getAirwayBills();
      toast.success('Airwaybill created!');
    } catch (error) {
      console.error('Error creating AirwayBill:', error);
      toast.error('Error creating AirwayBill!');
    }
  };

  const deleteAirwayBill = async (airwayBillIds) => {
    try {
      await apiService.delete('/airwaybills', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: { ids: airwayBillIds }, // Include ids in the data property
      });
      setAirwayBills((prevAirwayBills) =>
        prevAirwayBills.filter((airwayBill) => !airwayBillIds.includes(airwayBill.id))
      );
      toast.success('Airwaybill deleted!');
    } catch (error) {
      toast.error('Error deleting AirwayBill');
      console.error('Error deleting AirwayBill:', error);
    }
  };

  const updateAirwayBill = async (airwayBillId, updatedAirwayBill) => {
    try {
      const response = await apiService.put(`/airwayBills/${airwayBillId}`, updatedAirwayBill, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setAirwayBills((prevAirwayBills) =>
        prevAirwayBills.map((airwayBill) =>
          airwayBill.id === airwayBillId ? { ...airwayBill, ...data } : airwayBill
        )
      );
      toast.success('Airwaybill updated!');
    } catch (error) {
      toast.error('Error updating AirwayBill');
      console.error('Error updating AirwayBill:', error);
    }
  };
  const getAirwayBills = async (filters = {}) => {
    // Remove 'role' from filters if it is an empty array
    const effectiveFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (key === 'role' && Array.isArray(value) && value.length === 0) {
        return acc; // Skip adding to the accumulator
      }
      acc[key] = value; // Otherwise, add to the accumulator
      return acc;
    }, {});

    const query = new URLSearchParams(effectiveFilters).toString();

    try {
      // Simulate API call to get AirwayBills
      const response = await apiService.get(`/airwaybills?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Check if response has data and airwaybills field
      const data = response.data || {};
      const airwaybills = data.airwaybills || [];

      // Set the state for airwaybills and record details
      setAirwayBillRecordDetails({
        page: data.page || 1,
        take: data.take || 5,
        total: data.total || airwaybills.length,
        totalPages: Math.ceil((+data.total || airwaybills.length) / (+data.take || 5)),
      });

      setAirwayBills(airwaybills);

      // Return the necessary values (you can still return these)
      return {
        airwaybills,
        page: data.page || 1,
        take: data.take || 5,
        total: data.total || airwaybills.length,
        totalPages: Math.ceil((+data.total || airwaybills.length) / (+data.take || 5)),
      };
    } catch (error) {
      console.error('Error fetching AirwayBills:', error);

      // Handle unauthorized error
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }

      // Return an empty response in case of an error
      return {
        airwaybills: [],
        page: 1,
        take: 5,
        total: 0,
        totalPages: 0,
      };
    }
  };

  const getAirwayBillById = async (airwayBillId) => {
    try {
      const response = await apiService.get(`/airwayBills/${airwayBillId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setSelectedAirwayBill(data);
      return data;
    } catch (error) {
      console.error('Error fetching AirwayBill by ID:', error);
      throw error;
    }
  };

  useEffect(() => {
    getAirwayBills();
  }, []);

  const createAirwayBillCallback = useCallback(createAirwayBill, []);
  const deleteAirwayBillCallback = useCallback(deleteAirwayBill, []);
  const updateAirwayBillCallback = useCallback(updateAirwayBill, [updateAirwayBill]);
  const getAirwayBillsCallback = useCallback(getAirwayBills, []);

  const AirwayBillActionsValue = useMemo(
    () => ({
      createAirwayBill: createAirwayBillCallback,
      deleteAirwayBill: deleteAirwayBillCallback,
      updateAirwayBill: updateAirwayBillCallback,
      getAirwayBills: getAirwayBillsCallback,
      getAirwayBillById,
    }),
    [
      createAirwayBillCallback,
      deleteAirwayBillCallback,
      updateAirwayBillCallback,
      getAirwayBillsCallback,
    ]
  );

  const AirwayBillsValue = useMemo(
    () => ({
      airwayBills,
      airwayBillRecordDetails,
    }),
    [airwayBills, airwayBillRecordDetails]
  );

  return (
    <AirwayBillContext.Provider value={AirwayBillsValue}>
      <AirwayBillActionsContext.Provider value={AirwayBillActionsValue}>
        <AirwayBillDetailsContext.Provider value={selectedAirwayBill}>
          {children}
        </AirwayBillDetailsContext.Provider>
      </AirwayBillActionsContext.Provider>
    </AirwayBillContext.Provider>
  );
};

export const useAirwayBillData = () => useContext(AirwayBillContext);
export const useAirwayBillActions = () => useContext(AirwayBillActionsContext);
export const useAirwayBillDetails = () => useContext(AirwayBillDetailsContext);

AirwayBillProvider.propTypes = {
  children: PropTypes.element,
};
