const TABLE_HEAD = [
  // { id: 'name', label: 'Name', width: 320 },
  { id: 'code', label: 'Code', width: 320 },
  { id: 'shelfType', label: 'Shelf Type', width: 320 },
  { id: 'status', label: 'Status', width: 10 },
  { id: '', width: 100 },
];

const defaultFilters = {
  name: '',
  role: [],
  status: '',
};
const tableNames = [
  // { id: 'name', label: 'Name' },
  { id: 'code', label: 'Code' },
  { id: 'shelfType', label: 'Shelf Type' },
  { id: 'status', label: 'Status' },
];
const personalInformation = [
  // {
  //   name: 'name',
  //   width: '96%',
  //   label: 'Name',
  // },
  {
    name: 'code',
    width: '96%',
    label: 'Code',
  },
];
const statusForSelect = [
  {
    id: 'ACTIVE',
    label: 'ACTIVE',
    name: 'ACTIVE',
  },
  {
    id: 'INACTIVE',
    label: 'INACTIVE',
    name: 'INACTIVE',
  },
];
const TypesForSelect = [
  {
    id: 'ACCEPTANCE',
    label: 'ACCEPTANCE',
    name: 'ACCEPTANCE',
  },
  {
    id: 'DELIVERY',
    label: 'DELIVERY',
    name: 'DELIVERY',
  },
  {
    id: 'NOT DECLERED',
    label: 'NOT DECLERED',
    name: 'NOT DECLERED',
  },
  {
    id: 'FAILED DELIVERY',
    label: 'FAILED DELIVERY',
    name: 'FAILED DELIVERY',
  },
];

const status_colors = {
  ACTIVE: ['#22C55E29', '#118D57'],
  INACTIVE: ['#919EAB29', '#637381'],
};

const status = ['ACTIVE', 'INACTIVE'];

export {
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  personalInformation,
  status_colors,
  statusForSelect,
  TypesForSelect,
};
