import { Button } from '@mui/material';
import React from 'react';
import * as XLSX from 'xlsx';
import PropTypes from 'prop-types';

import Iconify from 'src/components/iconify';

function DownloadForRS({ rsData }) {
  const exportToExcel = () => {
    // First Sheet
    const ws1 = XLSX.utils.json_to_sheet(rsData?.transformedDataFirst, {
      header: [
        'გზ.ნომერი',
        'პირადი ნომერი',
        'სახელი',
        'გვარი',
        'ტელეფონი',
        'მისამართი',
        'ქვეყანის კოდი',
        'წონა',
        'გზ. ტიპი',
        'დაბ.ტიპი',
        'რეგ.თარიღი',
        'საწყობში შემოტანის თარიღი',
        'დოკუმენტის ნომერი',
        'ტრანსპორტირების ხარჯები 1',
        'ტრანსპორტირების ხარჯები 1-ის ვალუტა',
        'ტრანსპორტირების ხარჯები  2',
        'ტრანსპორტირების ხარჯები 2-ის ვალუტა',
        'ტრანსპორტირების სხვა ხარჯები',
        'ტრანსპორტირების სხვა ხარჯების ვალუტა',
        'მაღაზიის სახელი',
        'შენიშვნა',
        'რეისის ნომერი',
        'რეზიდენტი/არარეზიდენტი',
      ],
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws1, 'Sheet1');
    const ws2 = XLSX.utils.json_to_sheet(rsData?.transformedDataSecond, {
      header: [
        'გზ.ნომერი',
        'საქონლის კოდი',
        'დასახელება',
        'რაოდენობა',
        'ფასი (ვალუტაში)',
        'ვალუტა',
        'შეღავათი',
      ],
    });
    XLSX.utils.book_append_sheet(wb, ws2, 'Sheet2');
    // Write the workbook to a file
    XLSX.writeFile(wb, 'rsData.xlsx');
  };
  return (
    <Button
      onClick={exportToExcel}
      variant="contained"
      disabled={!rsData?.transformedDataFirst?.length}
      startIcon={<Iconify icon="material-symbols:download" />}
      style={{ fontSize: '0.7rem' }}
    >
      DOWNLOAD
    </Button>
  );
}

DownloadForRS.propTypes = {
  rsData: PropTypes.object,
};

export default DownloadForRS;
