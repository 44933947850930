import { Helmet } from 'react-helmet-async';
import { RSListView } from 'src/sections/rs/view';

export default function RSListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: RS</title>
      </Helmet>

      <RSListView />
    </>
  );
}
