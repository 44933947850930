function applyFilter({ inputData, comparator, filters }) {
  const {
    name,
    // status,
    role,
    // timeSlot,
    // startDate,
    // endDate,
    // eslint-disable-next-line
    tracking,
    // eslint-disable-next-line
    currency,
    // eslint-disable-next-line
    country,
    // eslint-disable-next-line
    ...dynamicFilters
  } = filters;
  const stabilizedThis = inputData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let filteredData = stabilizedThis.map((el) => el[0]);
  // if (dynamicFilters) {
  //   Object.entries(dynamicFilters).forEach(([key, value]) => {
  //     if (value !== '' && value !== 'all') {
  //       filteredData = filteredData.filter((item) => {
  //         if (typeof value === 'boolean') {
  //           return (
  //             item[key] === value ||
  //             (value === false &&
  //               // item[key] === null ||
  //               item[key] === 'NOT')
  //           );
  //         }
  //         return (
  //           item[key] === value ||
  //           (value?.toUpperCase()?.startsWith('NOT') &&
  //             // item[key] === null ||

  //             item[key] === false)
  //         );
  //       });
  //     }
  //   });
  // }

  if (name) {
    filteredData = filteredData.filter(
      (user) =>
        (user.name && user?.name.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.code && user?.code.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.tracking && user?.tracking.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.nameKa && user?.nameKa.trim().toLowerCase().includes(name.toLowerCase())) ||
        (user.nameEn && user?.nameEn.trim().toLowerCase().includes(name.toLowerCase()))
    );
  }

  // if (status !== 'all') {
  //   filteredData = filteredData.filter((user) => user.status === status);
  // }

  // if (timeSlot !== 'all') {
  //   filteredData = filteredData.filter((user) => user.timeSlot === timeSlot);
  // }

  if (role.length) {
    filteredData = filteredData.filter((user) => role.includes(user.role));
  }

  // // Date filtering
  // if (startDate && endDate) {
  //   const start = new Date(startDate).setHours(0, 0, 0, 0);
  //   const end = new Date(endDate).setHours(23, 59, 59, 999);
  //   filteredData = filteredData.filter((item) => {
  //     const itemDate = new Date(item.createdAt).getTime(); // Replace 'createdAt' with your actual date property
  //     return itemDate >= start && itemDate <= end;
  //   });
  // } else if (startDate) {
  //   const start = new Date(startDate).setHours(0, 0, 0, 0);
  //   filteredData = filteredData.filter((item) => {
  //     const itemDate = new Date(item.createdAt).getTime(); // Adjust for your data's property
  //     return itemDate >= start;
  //   });
  // } else if (endDate) {
  //   const end = new Date(endDate).setHours(23, 59, 59, 999);
  //   filteredData = filteredData.filter((item) => {
  //     const itemDate = new Date(item.createdAt).getTime(); // Adjust for your data's property
  //     return itemDate <= end;
  //   });
  // }

  return filteredData;
}
export { applyFilter };
