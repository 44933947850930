// @mui
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';
import { useState } from 'react';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';

//
import AlertReturnFunction from 'src/custom_components/Alert/AlertRetunrFunction';
import { useOrderActions } from 'src/context/providers/OrderContext';
import OrderDrawerComponent from '../order/order-drawer-component';
import { status } from '../order/view/data';

export default function TcTable({ TABLE_HEAD, defaultFilters, tableNames, kanbanInput }) {
  const [alertData, setAlertData] = useState(null);
  const [formData, setFormData] = useState({
    status: '',
    tracking: '',
  });

  const settings = useSettingsContext();
  const { updateOrderStatus } = useOrderActions();

  // Custom
  const [aRow, setARow] = useState({});
  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const updateAcceptance = (e) => {
    e.preventDefault();
    const updateOrderData = {
      tracking: formData.tracking,
      status: formData.status,
    };
    updateOrderStatus(updateOrderData).then(() => {
      setFormData({
        status: '',
        tracking: '',
      });
    });
  };
  const closeAlert = () => {
    setAlertData(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {alertData && (
          <AlertReturnFunction onClose={closeAlert} type={alertData.type} text={alertData.text} />
        )}
        {Object.keys(aRow).length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}
        <CustomBreadcrumbs heading="Orders" sx={{ padding: '0px 3%' }} />
        <div className="select_containers_large">
          <div
            className="child_kanban_large"
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#f4f6f8' }
                : { backgroundColor: '#2D353D' }
            }
          >
            <div>
              <Box className="headings">
                <Typography variant="h6">Order Status Update</Typography>
              </Box>
            </div>
            <form
              className="little_input_boxes"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#ffffff' }
                  : { backgroundColor: '#161C24' }
              }
              onSubmit={updateAcceptance}
            >
              <TextField
                variant="filled"
                style={{ padding: '0px', width: '40%' }}
                name="tracking"
                label="Tracking"
                required
                value={formData.tracking}
                onChange={handleInputChange}
              />
              {Object.keys(formData).map((key, ind) => {
                if (key === 'status') {
                  return (
                    <FormControl variant="filled" sx={{ width: '40%', margin: 'auto' }} key={ind}>
                      <InputLabel id="demo-simple-select-filled-label">Status</InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        key={key}
                        name={key}
                        label="status"
                        placeholder="status"
                        defaultValue="status"
                        value={formData.status}
                        onChange={handleInputChange}
                        sx={{ width: '100%' }}
                      >
                        {status.map((i, index) => (
                          <MenuItem key={index} value={i}>
                            {i}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  );
                }
                return null;
              })}

              <Button variant="contained" type="submit" style={{ width: '10%' }}>
                UPDATE
              </Button>
            </form>
            {/* <Box className="Parent_Kanban"> */}
            {/* <Box
                className="boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#f4f5f8' }
                    : { backgroundColor: '#2d353d' }
                }
              > */}
            {/* {acceptanceShelfByID[0]?.orders?.length ? (
                  acceptanceShelfByID[0]?.orders.map((i, index) => (
                    <Box
                      className="box_items"
                      key={i.id}
                      data-shelf-id={i.id}
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#ffffff' }
                          : { backgroundColor: '#161C24' }
                      }
                      onClick={clickedShelf}
                    >
                      <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />{' '}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.tracking_number}</div>
                        <div className="light_text">{i.name}</div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <div className="bold_heading">{i.room_number}</div>
                        <div className="light_text">{i.customer_id}</div>
                      </div>
                    </Box>
                  ))
                ) : (
                  <div className="notFoundContainer">
                    <img
                      src={boxIMG}
                      alt=""
                      width="10%"
                      // width="20%"
                    />
                    <Typography variant="h6" style={{ fontSize: '18px' }}>
                      Selected shelf is empty
                    </Typography>
                    <Typography variant="h6" style={{ color: '#637381', fontSize: '14px' }}>
                      Choose shelf from menu and start scanning
                    </Typography>
                  </div>
                )} */}
            {/* </Box> */}
            {/* </Box> */}
          </div>
        </div>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
};
