// @mui
import { Button, Container, TextField } from '@mui/material';

// routes
// hooks
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { usePriceActions, usePriceData } from 'src/context/providers/PricesContext';
import { isNaN } from 'lodash';

export default function TcTable() {
  const settings = useSettingsContext();
  // State to hold form values
  const prices = usePriceData()[0];
  const { updatePrice } = usePriceActions();
  const [formValues, setFormValues] = useState({
    repackaging: 0,
    Insurance: 0,
    deliveryTbilisi: 0,
    deliveryRegion: 0,
  });
  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value === '' || value === '.') {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    } else {
      const numberValue = parseFloat(value);
      setFormValues({
        ...formValues,
        [name]: isNaN(numberValue) ? '' : numberValue,
      });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    updatePrice(prices.id, formValues);
  };
  useEffect(() => {
    if (prices) {
      setFormValues({
        repackaging: prices.repackaging || 0,
        Insurance: prices.Insurance || 0,
        deliveryTbilisi: prices.deliveryTbilisi || 0,
        deliveryRegion: prices.deliveryRegion || 0,
      });
    }
  }, [prices]);
  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Prices"
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />
        <Box
          sx={{ borderRadius: '20px', padding: '30px', width: '60%' }}
          style={
            settings.themeMode === 'light'
              ? { backgroundColor: '#f4f6f8' }
              : { backgroundColor: '#2D353D' }
          }
        >
          <form onSubmit={handleSubmit}>
            <TextField
              label="Repackaging"
              variant="outlined"
              type="number"
              name="repackaging"
              value={formValues.repackaging}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Insurance"
              variant="outlined"
              type="number"
              name="Insurance"
              value={formValues.Insurance}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Delivery to Tbilisi"
              variant="outlined"
              type="number"
              name="deliveryTbilisi"
              value={formValues.deliveryTbilisi}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <TextField
              label="Delivery to Region"
              variant="outlined"
              type="number"
              name="deliveryRegion"
              value={formValues.deliveryRegion}
              onChange={handleChange}
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 3 }}>
              Submit
            </Button>
          </form>
        </Box>
      </Container>
    </div>
  );
}
