import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const auth = JSON.parse(localStorage.getItem('token'));

// ----------------------------------------------------------------------

const authJwt = {
  path: 'jwt',
  element: <Outlet />,
  children: [
    {
      path: 'login',
      element: (
        <AuthClassicLayout>
          <JwtLoginPage />
        </AuthClassicLayout>
      ),
    },
  ],
};

export const authRoutes = !auth
  ? [
      {
        path: 'auth',
        children: [authJwt],
      },
    ]
  : [];
