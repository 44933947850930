import { Button, Drawer, IconButton, MenuItem, Typography, Select } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useShelfActions } from 'src/context/providers/ShelfContext';
import ZebraPrintComponentBarCodes from 'src/custom_components/zebraPrintBarcode/ZebraPrintBarcode';
import OrderList from 'src/custom_components/orderList/OrderList';
import InputFieds from '../../custom_components/Froms/InputFields';
import { status, personalInformation, status_colors } from './view/data';

const CustomerDrawerComponentshelf = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [shelfData, setshelfData] = useState({
    code: aRow.code || '',
    name: aRow.name || '',
    // type: aRow.shelfType || '',
    status: aRow.status || '',
  });

  const { updateShelf } = useShelfActions();

  const Clear = () => {
    setshelfData({});
  };

  const handleUpdate = () => {
    const shelfUPDAta = {
      id: aRow.id,
      code: shelfData.code,
      name: shelfData.name,
      status: shelfData.status,
    };
    updateShelf(aRow.id, shelfUPDAta);
    handleDrawerToggle();
    Clear();
    setARow({});
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '35%',
    drawerHeaderText: `Edit Shelf - ${aRow.id}`,
    buttons: {
      status: true,
      elements: [{ name: 'Update', onClick: handleUpdate, disabled: false }],
    },
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setshelfData({
      ...shelfData,
      [name]: value,
    });
  };
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
      setARow({});
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          Clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              width: '40%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {Object.keys(shelfData)?.map((key) => {
              if (key === 'status') {
                return (
                  <Select
                    key={key}
                    name={key}
                    value={shelfData[key]}
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: status_colors[shelfData.status][0],
                      color: status_colors[shelfData.status][1],
                    }}
                    variant="outlined"
                    sx={{ width: '70%', height: '40px' }}
                  >
                    {status.map((i, index) => (
                      <MenuItem key={index} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }
              return null;
            })}

            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Form */}

        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Form */}

          {/* attached orders */}

          <OrderList aRow={aRow} />
          {/* attached orders */}
          {/* Personal Information */}
          <InputFieds
            userData={shelfData}
            heading="Shelf Information"
            inputFields={personalInformation}
            setUserData={setshelfData}
          />
          {/* <div
            className="content-wrap"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              padding: '5px 0px 20px 20px',
            }}
          >
            <ComboBox
              nameOfInput="type"
              data={TypesForSelect}
              setter={handleInputChange}
              value={shelfData.type}
            />
          </div> */}

          {/* Personal Information */}
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '3%',
            }}
          >
            <ZebraPrintComponentBarCodes tracking={aRow.code} />
          </div>
        </div>
        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid rgba(220, 220, 220, 0.2)',
              padding: '16px',
            }}
          >
            {DrawerConfig.buttons.elements.map((element) => (
              <Button
                variant="contained"
                style={{
                  marginLeft: '24px',
                  width: '136px',
                }}
                disabled={element.disabled}
                onClick={element.onClick}
              >
                {element.name}
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CustomerDrawerComponentshelf.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponentshelf;
