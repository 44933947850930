// import { USER_STATUS_OPTIONS } from 'src/_mock';

// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];
const STATUS_OPTIONS = [{ value: 'all', label: 'All' }];

const TABLE_HEAD = [
  { id: 'first_name', label: 'First Name', width: 300 },
  { id: 'last_name', label: 'Last Name', width: 300 },
  { id: 'room_number_customer', label: 'Room Number', width: 280 },
  // { id: 'first_name_ka', label: 'First Name KA', width: 300 },
  // { id: 'last_name_ka', label: 'Last Name KA', width: 300 },
  { id: 'id_number', label: 'ID Number', width: 300 },
  { id: 'company_id', label: 'Company ID Number', width: 300 },
  { id: 'company_info', label: 'Company Info', width: 300 },
  { id: 'email', label: 'Email', width: 300 },
  { id: 'phone', label: 'Phone Number', width: 300 },
  { id: 'customerType', label: 'customerType', width: 300 },
  // { id: 'sender_address', label: 'Address', width: 280 },
  // { id: 'status', label: 'Status', width: 100 },
];

const defaultFilters = {
  role: [],
};

const tableNames = [
  { id: 'first_name', label: 'First Name' },
  { id: 'last_name', label: 'Last Name' },
  { id: 'room_number_customer', label: 'Room Number', width: 280 },
  // { id: 'first_name_ka', label: 'First Name KA' },
  // { id: 'last_name_ka', label: 'Last Name KA' },
  { id: 'id_number', label: 'ID Number', width: 300 },
  { id: 'company_id', label: 'Company ID Number', width: 300 },
  { id: 'company_info', label: 'Company Info', width: 300 },
  { id: 'email', label: 'Email', width: 300 },
  { id: 'phone', label: 'Phone Number', width: 300 },
  { id: 'customerType', label: 'customerType', width: 300 },
  // { id: 'sender_address', label: 'Address', width: 280 },
  // { id: 'status', label: 'Status', width: 100 },
];

const status = ['ACTIVE', 'INACTIVE', 'SUSPENDED', 'CLOSED'];

const status_colors = {
  ACTIVE: ['#22C55E29', '#118D57'],
  INACTIVE: ['#919EAB29', '#637381'],
  SUSPENDED: ['#FFAB0029', '#B76E00'],
  CLOSED: ['#FF563029', '#B71D18'],

  PLACED: ['#22C55E29', '#118D57'],
  PROCESSING: ['#FF563029', '#B71D18'],
  SHIPPED: ['#FFAB0029', '#B76E00'],
  OUT_FOR_DELIVERY: ['#DDF4FA', '#1A6C9C'],
  DELIVERED: ['#919EAB29', '#637381'],
  RETURN_TO_SENDER: ['#919EAB29', '#637381'],
  DELAYED: ['#919EAB29', '#637381'],
  CANCELLED: ['#919EAB29', '#637381'],
  NO_TRACKING_OR_ROOM: ['#919EAB29', '#637381'],
  DELETED: ['#919EAB29', '#637381'],
  Awaiting: ['#919EAB29', '#637381'],
  'Received in US warehouse': ['#919EAB29', '#637381'],
  'Hold in US warehouse': ['#919EAB29', '#637381'],
  Damaged: ['#919EAB29', '#637381'],
  'On the way from US warehouse': ['#919EAB29', '#637381'],
  'Droped  in US airport facility': ['#919EAB29', '#637381'],
  'Air Shipped from US': ['#919EAB29', '#637381'],
  Delayed: ['#919EAB29', '#637381'],
  'Customs Clearance': ['#919EAB29', '#637381'],
  'Customs Expertise': ['#919EAB29', '#637381'],
  'Received in Tbilisi Warehouse': ['#919EAB29', '#637381'],
  'Out For Delivery': ['#919EAB29', '#637381'],
  'Failed Delivery Attempt': ['#919EAB29', '#637381'],
  Delivered: ['#919EAB29', '#637381'],
  Canceled: ['#919EAB29', '#637381'],
  'Unclear Order': ['#919EAB29', '#637381'],
  'Action Needed': ['#919EAB29', '#637381'],
  'Needs Custom Clearance': ['#919EAB29', '#637381'],
  'Deadline for the customs clearance completion ': ['#919EAB29', '#637381'],
  'Needs to be transferred to government ': ['#919EAB29', '#637381'],
  'Transferred to government': ['#919EAB29', '#637381'],
  'Lost Parcel': ['#919EAB29', '#637381'],
};

const personalInformation = [
  {
    name: 'room_number',
    width: '96%',
    label: 'Room Number',
  },
  {
    name: 'first_name',
    width: '48%',
    label: 'First Name',
  },
  {
    name: 'last_name',
    width: '48%',
    label: 'Last Name',
  },
  {
    name: 'first_name_GE',
    width: '48%',
    label: 'First Name (GE)',
  },
  {
    name: 'last_name_GE',
    width: '48%',
    label: 'Last Name (GE)',
  },
  {
    name: 'company_info',
    width: '48%',
    label: 'Company Info',
  },
  {
    name: 'company_id',
    width: '48%',
    label: 'Company Id',
  },
  {
    name: 'id_number',
    width: '48%',
    label: 'Id Number',
  },
  {
    name: 'promotion',
    width: '48%',
    label: 'Promotion',
  },
];
const contactInformation = [
  {
    name: 'email',
    width: '96%',
    label: 'Email',
    disabled: true,
  },
  {
    name: 'phone',
    width: '96%',
    label: 'Phone Number 1',
  },
  {
    name: 'registered',
    width: '96%',
    label: 'registered',
    disabled: true,
  },
  // {
  //   name: 'phone_2',
  //   width: '48%',
  //   label: 'Phone Number 2',
  // },
];

const addressInformation = [
  {
    name: 'sender_address',
    width: '96%',
    label: 'Sender Address',
  },
  {
    name: 'georgian_main_address',
    width: '96%',
    label: 'Georgian Main Address',
  },
];

export {
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  status_colors,
  personalInformation,
  contactInformation,
  addressInformation,
};
