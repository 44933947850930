// OrderContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { redirect } from 'react-router';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { toast } from 'react-toastify';
import { useBoxActions } from './BoxContext';
import { usePalletActions } from './PalleteContext';
import { useShelfActions } from './ShelfContext';

const OrderContext = createContext();
const OrderActionsContext = createContext();

export const OrderProvider = ({ children }) => {
  const { getBoxes } = useBoxActions();
  const { getShelves } = useShelfActions();
  const { getPalletes } = usePalletActions();

  const [orders, setOrders] = useState([]);
  const [ordersCheck, setOrdersCheck] = useState([]);

  const [ordersTimeSlot, setOrdersTimeSlot] = useState([]);
  const [orderRecordDetails, setOrderRecordDetails] = useState({});

  const [course, setCourse] = useState([]);

  const getOrderss = async (filters = {}) => {
    // Remove 'role' from filters if it is an empty array
    const effectiveFilters = Object.entries(filters).reduce((acc, [key, value]) => {
      if (key === 'role' && Array.isArray(value) && value.length === 0) {
        return acc; // skip adding to the accumulator
      }
      acc[key] = value; // otherwise add to the accumulator
      return acc;
    }, {});

    const query = new URLSearchParams(effectiveFilters).toString();

    try {
      const response = await apiService.get(`/orders?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data.orders;
      setOrderRecordDetails({
        page: response.data.page,
        take: response.data.take,
        total: response.data.total,
        totalPages: Math.ceil(+response.data.total / +response.data.take),
      });
      setOrders(data);
    } catch (error) {
      console.error('Error fetching orders:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
    }
  };

  const getOrdersForCheck = async () => {
    try {
      const response = await apiService.get(`/orders/check`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setOrdersCheck(data);
      return data;
    } catch (error) {
      setOrdersCheck([]);
      return undefined;
    }
  };

  const getOrderTimeSlot = async () => {
    try {
      const response = await apiService.get(`orders/timeSlot`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setOrdersTimeSlot(data);
      return data;
    } catch (error) {
      setOrdersCheck([]);
      return undefined;
    }
  };

  const getOrderById = async (id) => {
    try {
      const response = await apiService.get(`/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      return data;
    } catch (error) {
      console.error('Error fetching order:', error);
      throw error;
    }
  };

  const createOrder = async (newOrder) => {
    try {
      const response = await apiService.post('/orders', newOrder, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      toast.success('Order created!');
      setOrders((prevOrderss) => [...prevOrderss, data]);
    } catch (error) {
      toast.error('Error creating order!');
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      await apiService.put(
        '/orders/update/soft',
        { ids: orderId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      getOrderss();
      toast.success('Order deleted!');
    } catch (error) {
      toast.error('Error deleting order!');
    }
  };

  const updateOrder = async (orderId, updatedOrder) => {
    updatedOrder.branch_id = '1ad52c30-2978-4fe0-9db4-ecbba1e04365';
    try {
      const response = await apiService.put(
        `/orders/updateOrderFromDashboard/${orderId}`,
        updatedOrder,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      // eslint-disable-next-line
      const data = await response.data;
      toast.success('Order updated!');
      getOrderss();
    } catch (error) {
      toast.error('Error updating order!');
    }
  };
  const updateDeclaration = async (id, updateDeclarationDto) => {
    try {
      const response = await apiService.put(`declarations/dashboard/${id}`, updateDeclarationDto, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      toast.success('Declaration updated!');
      // Add any additional actions if needed, such as refreshing the data
    } catch (error) {
      toast.error('Error updating declaration!');
    }
  };

  const createDeclarationDB = async (createDeclarationDto, length) => {
    try {
      const response = await apiService.post(
        `declarations/createFromDashboard`,
        createDeclarationDto,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      // eslint-disable-next-line
      const data = response.data;
      if (data && !length) {
        updateOrder(createDeclarationDto.order_id, { declaration_status: 'DECLERED' });
      }
      toast.success('Declaration Created!');
      return data;
    } catch (error) {
      toast.error('Error Creating declaration!');
      throw error;
    }
  };

  const updateOrderByBoxAndPallet = async (updateOrderData) => {
    try {
      const response = await apiService.patch(
        `/orders/update-by-tracking-and-box-pallet`,
        updateOrderData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const data = await response.data;
      if (data) {
        getOrderss();
        getBoxes();
        getPalletes();
        toast.success('Order Updated!');
        return data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.error);
    }
    return undefined;
  };

  const updateOrderByShelf = async (updateOrderData) => {
    try {
      const response = await apiService.patch(`/orders/update-by-tracking-shelf`, updateOrderData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      if (data) {
        getOrderss();
        getShelves();
        getPalletes();
        toast.success('Order Updated!');
        return data;
      }
    } catch (error) {
      toast.error(error.response.data.message || error.response.data.error);
    }
    return undefined;
  };

  const updateOrderStatus = async (updateOrderData) => {
    try {
      const response = await apiService.patch(`/orders/update-order-status`, updateOrderData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      if (data) {
        getOrderss();
        getOrdersForCheck();
        getOrderTimeSlot();
        toast.success('Order Updated!');
        return data;
      }
    } catch (error) {
      toast.error(error.response.data.error);
    }
    return undefined;
  };

  const sendToDelivery = async (orderIds, userToken) => {
    try {
      await apiService.put(
        'orders/update/status/out-for-delivery',
        {
          ids: orderIds,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      toast.success('Order updated!');
      getOrdersForCheck();
      getOrderTimeSlot();
      getOrderss();
      return true;
    } catch (error) {
      toast.error('Error updating orders!');
      return false;
    }
  };

  const rejectPayment = async (id) => {
    try {
      await apiService.post(
        'transactions/rejectPayment',
        {
          id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      toast.success('payment updated!');
      return true;
    } catch (error) {
      toast.error('Error updating payment!');
      return false;
    }
  };

  /// order creation flow
  const getOrderByTracking = async (tracking) => {
    try {
      const response = await apiService.get(`orders/search/trackingNumber/${tracking}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = response.data;
      toast.success('Order Found');
      return data;
    } catch (error) {
      toast.warn('Order Not Found');
      throw error;
    }
  };

  /// order creation flow
  const createOrderWithSteps = async (orderData) => {
    orderData.branch_id = '1ad52c30-2978-4fe0-9db4-ecbba1e04365';
    try {
      const response = await apiService.patch(`orders/create-order-by-steps`, orderData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = response.data;
      toast.success('Order Created');
      getOrderss();
      return data;
    } catch (error) {
      toast.warn('Order Not Created');
    }
    return null;
  };
  const getRSOrders = async (id) => {
    try {
      const response = await apiService.post(
        `rs/findPallets`,
        {
          palletIds: id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const data = await response.data;
      return data; // Success path
    } catch (error) {
      console.error('Error getting RS orders:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
      return null; // Error path
    }
  };

  const sendFile = async (files) => {
    if (files.length > 0) {
      const file = files[0]; // Assuming files is an array and you're uploading the first file
      try {
        const formData = new FormData();
        formData.append('file', file);
        // eslint-disable-next-line
        const response = await apiService.post(`rs/upload`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        toast.success('Sent successfully!');
      } catch (error) {
        console.error('Error sending file:', error);
        toast.error('Error sending file!');
      }
    } else {
      toast.warn('No file selected for upload');
    }
  };

  const getCourse = async () => {
    try {
      const response = await apiService.get(`/exchange-rate`, {
        timeout: 20000,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data['BOG course'];
      setCourse(data);
      return data;
    } catch (error) {
      setCourse('');
      return undefined;
    }
  };
  useEffect(() => {
    getOrderss();
    getOrdersForCheck();
    getCourse();
    getOrderTimeSlot();
  }, []);

  const createOrderCallback = useCallback(createOrder, []);
  const deleteOrderCallback = useCallback(deleteOrder, []);
  const updateOrderCallback = useCallback(updateOrder, [updateOrder]);
  const updatedDeclarationCallback = useCallback(updateDeclaration, []);
  const createDeclarationCallback = useCallback(createDeclarationDB, [createDeclarationDB]);
  const updateOrderByBoxAndPalletCallback = useCallback(updateOrderByBoxAndPallet, [
    getBoxes,
    getPalletes,
  ]);
  const getOrderssCallback = useCallback(getOrderss, []);
  const getOrdersForCheckCallback = useCallback(getOrdersForCheck, []);
  const getOrderByIdCallback = useCallback(getOrderById, []);
  const sendToDeliveryCallback = useCallback(sendToDelivery, []);
  const rejectPaymentCallback = useCallback(rejectPayment, []);
  const updateOrderByShelfCallback = useCallback(updateOrderByShelf, [getShelves, getPalletes]);
  const updateOrderStatusCallback = useCallback(updateOrderStatus, []);
  const getOrderByTrackingCallBack = useCallback(getOrderByTracking, []);
  const createOrderWithStepsCallback = useCallback(createOrderWithSteps, []);
  const getRSOrdersCallBack = useCallback(getRSOrders, []);
  const sendFileCallback = useCallback(sendFile, []);

  const orderActionsValue = useMemo(
    () => ({
      createOrder: createOrderCallback,
      deleteOrder: deleteOrderCallback,
      updateOrder: updateOrderCallback,
      getOrderss: getOrderssCallback,
      getOrderById: getOrderByIdCallback,
      sendToDelivery: sendToDeliveryCallback,
      rejectPayment: rejectPaymentCallback,
      getOrdersForCheck: getOrdersForCheckCallback,
      updateOrderByBoxAndPallet: updateOrderByBoxAndPalletCallback,
      updateOrderByShelf: updateOrderByShelfCallback,
      updateOrderStatus: updateOrderStatusCallback,
      getOrderByTracking: getOrderByTrackingCallBack,
      createOrderWithSteps: createOrderWithStepsCallback,
      getRSOrders: getRSOrdersCallBack,
      sendFile: sendFileCallback,
      updateDeclaration: updatedDeclarationCallback,
      createDeclarationDB: createDeclarationCallback,
    }),
    [
      createOrderCallback,
      deleteOrderCallback,
      updateOrderCallback,
      getOrderssCallback,
      getOrderByIdCallback,
      sendToDeliveryCallback,
      rejectPaymentCallback,
      updateOrderByBoxAndPalletCallback,
      updateOrderByShelfCallback,
      updateOrderStatusCallback,
      getOrdersForCheckCallback,
      getOrderByTrackingCallBack,
      createOrderWithStepsCallback,
      getRSOrdersCallBack,
      sendFileCallback,
      updatedDeclarationCallback,
      createDeclarationCallback,
    ]
  );

  const ordersValue = useMemo(
    () => ({
      orders,
      ordersCheck,
      course,
      ordersTimeSlot,
      orderRecordDetails,
    }),
    [orders, ordersCheck, course, ordersTimeSlot, orderRecordDetails]
  );
  return (
    <OrderContext.Provider value={ordersValue}>
      <OrderActionsContext.Provider value={orderActionsValue}>
        {children}
      </OrderActionsContext.Provider>
    </OrderContext.Provider>
  );
};

export const useOrderData = () => useContext(OrderContext);
export const useOrderActions = () => useContext(OrderActionsContext);

OrderProvider.propTypes = {
  children: PropTypes.element,
};
