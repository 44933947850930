import { Helmet } from 'react-helmet-async';
import ContentManagementListView from 'src/sections/contentManagement/view/content-list-view';

export default function ContentManagementList() {
  return (
    <>
      <Helmet>
        <title>Content Management</title>
      </Helmet>

      <ContentManagementListView />
    </>
  );
}
