import { Helmet } from 'react-helmet-async';
import { LiveOrdersListView } from 'src/sections/liveOrders/view';

export default function LiveOrdersListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Live orders</title>
      </Helmet>
      <LiveOrdersListView />
    </>
  );
}
