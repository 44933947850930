import { ORDER_STATUS_OPTIONS } from 'src/_mock';

const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...ORDER_STATUS_OPTIONS];

const OTHER_STATUS_OPTIONS_FOR_ORDER = [
  { value: 'all', label: 'All' },
  { value: 'warehouse', label: 'In Warehouse' },
  { value: 'readyToShip', label: 'Ready To Ship' },
  { value: 'shipped', label: 'Shipped' },
];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', width: 170 },
  { id: 'tracking_number', label: 'Tracking Number', width: 160 },
  { id: 'tracking_number_2', label: 'Tracking Number 2', width: 140 },
  { id: 'room_number', label: 'Room Number', width: 140 },
  { id: 'weight', label: 'Weight', width: 140 },
  { id: 'content', label: 'Content', width: 140 },
  { id: 'additional_identifier', label: 'Additional Identifier', width: 140 },
  { id: 'value', label: 'Value', width: 140 },
  { id: 'status', label: 'Status', width: 120 },
  { id: '', width: 88 },
];

const defaultFilters = {
  name: '',
  role: [],
};

const tableNames = [
  { id: 'name', label: 'Name' },
  { id: 'tracking_number', label: 'Tracking Number' },
  { id: 'tracking_number_2', label: 'Tracking Number 2' },
  { id: 'room_number', label: 'Room Number' },
  { id: 'weight', label: 'Weight' },
  { id: 'content', label: 'Content' },
  { id: 'additional_identifier', label: 'Additional Identifier' },
  { id: 'value', label: 'Value' },
  { id: 'status', label: 'Status' },
  // { id: '', width: 88 },
];

const status = [
  'PLACED',
  'PROCESSING',
  'SHIPPED',
  'OUT_FOR_DELIVERY',
  'DELIVERED',
  'RETURN_TO_SENDER',
  'DELAYED',
  'CANCELLED',
  'NO_TRACKING_OR_ROOM',
  'DELETED',
];

const trackingInformation = [
  {
    name: 'tracking_number',
    width: '48%',
    label: 'Tracking',
  },
  {
    name: 'tracking_number_2',
    width: '48%',
    label: 'Traking 2',
  },
  {
    name: 'room_number',
    width: '96%',
    label: 'Room Number',
  },
];

const orderDetails = [
  {
    name: 'name',
    width: '96%',
    label: 'Order Name',
    disabled: true,
  },
  {
    name: 'content',
    width: '96%',
    label: 'Content',
  },
  {
    name: 'weight',
    width: '48%',
    label: 'weight',
  },
  {
    name: 'value',
    width: '48%',
    label: 'price',
  },
];

const orderDetailsCreate = [
  {
    name: 'name',
    width: '96%',
    label: 'Order Name',
  },
  {
    name: 'content',
    width: '96%',
    label: 'Content',
  },
  {
    name: 'weight',
    width: '48%',
    label: 'weight',
  },
  {
    name: 'value',
    width: '48%',
    label: 'price',
  },
];

const additionalInformation = [
  {
    name: 'additional_identifier',
    width: '96%',
    label: 'Customer',
  },
  {
    name: 'customer_id',
    width: '96%',
    label: 'Customer ID',
    disabled: true,
  },
  {
    name: 'branch_id',
    width: '96%',
    label: 'Branch ID',
    disabled: true,
  },
];

const additionalInformationCreate = [
  {
    name: 'additional_identifier',
    width: '96%',
    label: 'Customer',
  },
  {
    name: 'customer_id',
    width: '96%',
    label: 'Customer ID',
  },
  {
    name: 'branch_id',
    width: '96%',
    label: 'Branch ID',
  },
];
const status_colors = {
  ACTIVE: ['#22C55E29', '#118D57'],
  INACTIVE: ['#919EAB29', '#637381'],
  SUSPENDED: ['#FFAB0029', '#B76E00'],
  CLOSED: ['#FF563029', '#B71D18'],

  PLACED: ['#22C55E29', '#118D57'],
  PROCESSING: ['#FF563029', '#B71D18'],
  SHIPPED: ['#FFAB0029', '#B76E00'],
  OUT_FOR_DELIVERY: ['#DDF4FA', '#1A6C9C'],
  DELIVERED: ['#919EAB29', '#637381'],
  RETURN_TO_SENDER: ['#919EAB29', '#637381'],
  DELAYED: ['#919EAB29', '#637381'],
  CANCELLED: ['#919EAB29', '#637381'],
  NO_TRACKING_OR_ROOM: ['#919EAB29', '#637381'],
  DELETED: ['#919EAB29', '#637381'],
};

const kanbanInput = [
  {
    name: 'orderId',
    width: '96%',
    label: 'Order ID',
  },
];
export {
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  trackingInformation,
  orderDetails,
  additionalInformation,
  status_colors,
  orderDetailsCreate,
  additionalInformationCreate,
  OTHER_STATUS_OPTIONS_FOR_ORDER,
  kanbanInput,
};
