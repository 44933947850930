import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const PaymentContext = createContext();
const PaymentActionsContext = createContext();

export const PaymentProvider = ({ children }) => {
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(25);

  const getPayments = useCallback(
    async (filters = { page, take }) => {
      try {
        const response = await apiService.get('/transactions', {
          params: filters,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.data;
        setPayments(data.payments);
        setTotal(data.total);
        setPage(data.page);
        setTake(data.take);
      } catch (error) {
        console.error('Error fetching Payments:', error);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          redirect('/');
        }
      }
    },
    [page, take]
  );

  const getPaymentByID = useCallback(async (id) => {
    try {
      const response = await apiService.get(`/transactions/dashboard/get/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      return data;
    } catch (error) {
      console.error('Error fetching Payment by ID:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
      return null; // Return null in case of an error
    }
  }, []);

  const refundPayment = useCallback(async (id, amount) => {
    try {
      const response = await apiService.post(
        `/transactions/refund/${id}`,
        { amount },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const data = await response.data;
      toast.success('Payment refunded successfully');
      return data;
    } catch (error) {
      console.error('Error refunding payment:', error);

      const errorMessage =
        error.response?.data?.message || error.response?.data?.error || 'An error occurred';
      toast.error(errorMessage);

      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
      }

      return null;
    }
  }, []);

  const PaymentActionsValue = useMemo(
    () => ({
      getPayments,
      getPaymentByID,
      setPage,
      setTake,
      refundPayment,
    }),
    [getPayments, getPaymentByID, refundPayment]
  );

  const PaymentsValue = useMemo(
    () => ({ payments, total, page, take }),
    [payments, total, page, take]
  );

  return (
    <PaymentContext.Provider value={PaymentsValue}>
      <PaymentActionsContext.Provider value={PaymentActionsValue}>
        {children}
      </PaymentActionsContext.Provider>
    </PaymentContext.Provider>
  );
};

export const usePaymentData = () => useContext(PaymentContext);
export const usePaymentActions = () => useContext(PaymentActionsContext);

PaymentProvider.propTypes = {
  children: PropTypes.element,
};
