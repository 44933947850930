// const STATUS_OPTIONS = [{ value: 'all', label: 'All' }, ...USER_STATUS_OPTIONS];

const TABLE_HEAD = [
  { id: 'name', label: 'Name', width: 380 },
  { id: 'address', label: 'Address', width: 380 },
  { id: 'postalCode', label: 'postalCode', width: 300 },
  { id: 'city', label: 'City', width: 300 },
  { id: 'state', label: 'State', width: 300 },
  { id: 'country', label: 'Country', width: 300 },
  { id: 'phone', label: 'Phone Number', width: 100 },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', width: 100 },
];

const defaultFilters = {
  name: '',
  address: '',
  postalCode: '',
  city: '',
  state: '',
  country: '',
  phone: '',
  role: [],
  status: 'all',
};

const tableNames = [
  { id: 'name', label: 'Name', width: 380 },
  { id: 'address', label: 'Address', width: 300 },
  { id: 'postalCode', label: 'postalCode', width: 300 },
  { id: 'city', label: 'City', width: 300 },
  { id: 'state', label: 'State', width: 300 },
  { id: 'country', label: 'Country', width: 300 },
  { id: 'phone', label: 'Phone Number', width: 100 },
  { id: 'status', label: 'Status', width: 100 },
];

const personalInformation = [
  {
    name: 'name',
    width: '48%',
    label: 'Name',
  },
  {
    name: 'phone',
    width: '48%',
    label: 'Phone',
  },

  {
    name: 'country',
    width: '48%',
    label: 'Country',
  },
  {
    name: 'postalCode',
    width: '48%',
    label: 'PostalCode',
  },

  {
    name: 'address',
    width: '96%',
    label: 'Address',
  },
];
const cityArray = [
  {
    id: 'New York',
    label: 'New York',
    name: 'New York',
  },
  {
    id: 'Los Angeles',
    label: 'Los Angeles',
    name: 'Los Angeles',
  },
  {
    id: 'Chicago',
    label: 'Chicago',
    name: 'Chicago',
  },
  {
    id: 'Houston',
    label: 'Houston',
    name: 'Houston',
  },
  {
    id: 'Philadelphia',
    label: 'Philadelphia',
    name: 'Philadelphia',
  },
  {
    id: 'Wilmington',
    label: 'Wilmington',
    name: 'Wilmington',
  },
  {
    id: 'Miami',
    label: 'Miami',
    name: 'Miami',
  },
  {
    id: 'Dallas',
    label: 'Dallas',
    name: 'Dallas',
  },
  {
    id: 'Boston',
    label: 'Boston',
    name: 'Boston',
  },
  {
    id: 'Atlanta',
    label: 'Atlanta',
    name: 'Atlanta',
  },
  {
    id: 'Denver',
    label: 'Denver',
    name: 'Denver',
  },
  {
    id: 'Phoenix',
    label: 'Phoenix',
    name: 'Phoenix',
  },
  {
    id: 'San Francisco',
    label: 'San Francisco',
    name: 'San Francisco',
  },
  {
    id: 'Orlando',
    label: 'Orlando',
    name: 'Orlando',
  },
  {
    id: 'Austin',
    label: 'Austin',
    name: 'Austin',
  },
  {
    id: 'Charlotte',
    label: 'Charlotte',
    name: 'Charlotte',
  },
  {
    id: 'DELAWARE',
    label: 'DELAWARE',
    name: 'DELAWARE',
  },
];

const status_colors = {
  OPEN: ['#22C55E29', '#118D57'],
  CLOSED: ['#919EAB29', '#637381'],
  UNDER_CONSTRUCTION: ['#FFAB0029', '#B76E00'],
  NEW: ['#FF563029', '#B71D18'],
};

const status = ['OPEN', 'CLOSED', 'UNDER_CONSTRUCTION', 'NEW'];

export {
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  personalInformation,
  status_colors,
  cityArray,
};
