import isEqual from 'lodash/isEqual';
import { useState, useCallback } from 'react';
// @mui
import {
  Card,
  Table,
  Button,
  Tooltip,
  Container,
  TableBody,
  IconButton,
  TableContainer,
  LinearProgress,
} from '@mui/material';

// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// _mock
import { _roles } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import { applyFilter } from 'src/custom_components/Table/helper/applyFilter';
import { useAddressActions, useAddressData } from 'src/context/providers/AddressContext';
import PropTypes from 'prop-types';
import UserTableRow from '../../custom_components/Table/customer-table-row';
import UserTableToolbar from '../../custom_components/Table/customer-table-toolbar';
import UserTableFiltersResult from '../../custom_components/Table/customer-table-filters-result';
import CustomerDrawerComponentAirwayBill from './customer-drawer-component'; // Updated import
import CreateAirwayBillDrawer from './CreateDrawer'; // Updated import

export default function TcTable({ TABLE_HEAD, defaultFilters, tableNames }) {
  const table = useTable();
  const { deleteAddress } = useAddressActions();
  const tableData = useAddressData();
  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [filters, setFilters] = useState(defaultFilters);

  const [reload, setReload] = useState(true);

  const [aRow, setARow] = useState({});

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (e, id) => {
      e.stopPropagation();
      deleteAddress([id]);
      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, deleteAddress]
  );

  const handleDeleteRows = useCallback(() => {
    deleteAddress(table.selected); // Updated function
  }, [table, deleteAddress]);

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleOrderDrawerToggle = () => {
    setIsOrderDrawerOpen(!isOrderDrawerOpen);
  };

  return (
    <div>
      {!tableData.length ? <LinearProgress color="success" /> : ''}

      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {Object.keys(aRow).length > 0 && (
          <CustomerDrawerComponentAirwayBill // Updated component
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}

        <CreateAirwayBillDrawer // Updated component
          isDrawerOpen={isOrderDrawerOpen}
          handleDrawerToggle={handleOrderDrawerToggle}
          setReload={setReload}
          reload={reload}
        />
        <CustomBreadcrumbs
          heading="Adress List"
          action={
            <Button
              onClick={handleOrderDrawerToggle}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Address
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            roleOptions={_roles}
          />

          {canReset && (
            <UserTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={dataFiltered.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={(e) => handleDeleteRow(e, row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        handleDrawerToggle={handleDrawerToggle}
                        setARow={setARow}
                        tableNames={tableNames}
                        ignoreCountry
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
};
