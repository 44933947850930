import { Button, Drawer, IconButton, MenuItem, Typography, Select } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useBranchActions } from 'src/context/providers/BranchContext';
import ComboBox from 'src/custom_components/Froms/comboBoxAutocomplite';
import InputFieds from '../../custom_components/Froms/InputFields';
import { status, personalInformation, status_colors, cityArray } from './view/data';

const CustomerDrawerComponentBranch = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [branchData, setBranchData] = useState({
    name: aRow.name,
    address: aRow.address,
    postalCode: aRow.postalCode,
    city: aRow.city,
    country: aRow.country.name,
    phone: aRow.phone,
    status: aRow.status,
  });

  const { updateBranch } = useBranchActions();
  const Clear = () => {
    setBranchData({});
  };

  const handleUpdate = async () => {
    const branchUPDAta = {
      country_id: aRow.id,
      name: branchData.name,
      status: branchData.status.name,
      address: branchData.address,
      postalCode: branchData.postalCode,
      city: branchData.city,
      state: branchData.state,
      phone: branchData.phone,
    };
    await updateBranch(aRow.id, branchUPDAta);
    handleDrawerToggle();
    Clear();
    setARow({});
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: `Edit Branch - ${aRow.id}`,
    buttons: {
      status: true,
      elements: [{ name: 'Update', onClick: handleUpdate, disabled: false }],
    },
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBranchData({
      ...branchData,
      [name]: value,
    });
  };
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
      setARow({});
    }
  };
  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          Clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              width: '30%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {Object.keys(branchData).map((key) => {
              if (key === 'status') {
                return (
                  <Select
                    key={key}
                    name={key}
                    value={branchData[key]}
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: status_colors[branchData.status][0],
                      color: status_colors[branchData.status][1],
                    }}
                    variant="outlined"
                    sx={{ width: '70%', height: '40px' }}
                  >
                    {status.map((i, index) => (
                      <MenuItem key={index} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }
              return null;
            })}

            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Form */}

        <div
          className="content-wrap"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Form */}
          {/* Personal Information */}
          <InputFieds
            userData={branchData}
            heading="Branch Information"
            inputFields={personalInformation}
            setUserData={setBranchData}
          />
          <div
            className="content-wrap"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              width: '100%',
              padding: '0px 0px 0px 20px',
            }}
          >
            <ComboBox
              nameOfInput="city"
              data={cityArray}
              setter={handleInputChange}
              value={branchData.city}
            />
          </div>
          {/* <Selec */}
          {/* Personal Information */}
        </div>
        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid rgba(220, 220, 220, 0.2)',
              padding: '16px',
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
            }}
          >
            {DrawerConfig.buttons.elements.map((element) => (
              <Button
                variant="contained"
                style={{
                  marginLeft: '24px',
                  width: '136px',
                }}
                disabled={element.disabled}
                onClick={element.onClick}
              >
                {element.name}
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CustomerDrawerComponentBranch.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponentBranch;
