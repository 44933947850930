import { Helmet } from 'react-helmet-async';
// import { BranchListView } from 'src/sections/branch/view';
import { OrderListView } from 'src/sections/order/view';

export default function OrderListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: User List</title>
      </Helmet>

      <OrderListView />
    </>
  );
}
