// @mui
import { Box, Container, Typography, TextField, Button } from '@mui/material';

//
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';
import { useState } from 'react';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
//
// import { EditNotifications } from '@mui/icons-material';
import { OrderBox } from 'src/assets/added_icons';
import ZebraPrintComponent from 'src/custom_components/Zebra/ZebraPrint';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import AlertReturnFunction from 'src/custom_components/Alert/AlertRetunrFunction';
import { useOrderActions, useOrderData } from 'src/context/providers/OrderContext';
import OrderDrawerComponent from '../order/order-drawer-component';
import { TIME_SLOT_FOR_ORDER } from '../order/view/data';

export default function TcTable({ TABLE_HEAD, defaultFilters, tableNames, kanbanInput }) {
  const [alertData, setAlertData] = useState(null);
  const [formData, setFormData] = useState({
    status: 'CHECKED',
    tracking: '',
  });
  const settings = useSettingsContext();
  const { updateOrderStatus, getOrderById } = useOrderActions();
  // eslint-disable-next-line
  const [date, setDate] = useState(new Date());

  // Custom
  const tableData = useOrderData().ordersCheck;
  const [aRow, setARow] = useState({});
  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  // console.log(tableData[4].customer_address.address)

  const updateAcceptance = (e) => {
    e.preventDefault();
    const updateOrderData = {
      tracking: formData.tracking,
      status: 'CHECKED',
    };
    updateOrderStatus(updateOrderData).then(() => {
      setFormData({
        status: '',
        tracking: '',
      });
    });
  };
  const clickedOrder = (e) => {
    const boxId = e.currentTarget.getAttribute('data-shelf-id');
    getOrderById(boxId).then((data) => {
      if (data) {
        setARow(data);
        setIsDrawerOpen(true);
      }
    });
  };

  const closeAlert = () => {
    setAlertData(null);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  // const parseOrderDate = (orderDate) => {
  //   const formattedOrderDate = orderDate.replace(' at ', ' ').replace(' GMT+4', ' GMT+0400');
  //   return new Date(formattedOrderDate);
  // };

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {alertData && (
          <AlertReturnFunction onClose={closeAlert} type={alertData.type} text={alertData.text} />
        )}
        {Object.keys(aRow)?.length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}

        <Box>
          <div className="select_containers_large flex_evenly_column">
            <div
              className="child_kanban_check"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#f4f6f8' }
                  : { backgroundColor: '#2D353D' }
              }
            >
              <div>
                <Box className="headings">
                  <Typography variant="h6">Check Order</Typography>
                </Box>
              </div>
              <form
                className="little_input_boxes"
                style={
                  settings.themeMode === 'light'
                    ? { backgroundColor: '#ffffff' }
                    : { backgroundColor: '#161C24' }
                }
                onSubmit={updateAcceptance}
              >
                <TextField
                  variant="filled"
                  style={{ padding: '0px', width: '80%' }}
                  name="tracking"
                  label="Tracking"
                  required
                  value={formData.tracking}
                  onChange={handleInputChange}
                />

                <Button variant="contained" type="submit" style={{ width: '20%' }}>
                  Check
                </Button>
              </form>
              {TIME_SLOT_FOR_ORDER.filter((i) => i.label !== 'All').map((item, ind) => {
                if (tableData?.['PICKED UP']) {
                  return (
                    <div
                      key={ind}
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#f4f6f8', padding: '5px', width: '100%' }
                          : { backgroundColor: '#2D353D', padding: '5px', width: '100%' }
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          margin: '10px 0px',
                        }}
                      >
                        <AccessAlarmIcon style={{ color: '#2aa76f' }} />
                        <Typography variant="h6" style={{ color: '#2aa76f', fontSize: '15px' }}>
                          {item.label}
                        </Typography>
                      </Box>
                      {tableData?.['PICKED UP']?.[item.value]?.map((i, index) => (
                        <Box
                          className="box_items_check"
                          key={i.id}
                          data-shelf-id={i.id}
                          onClick={clickedOrder}
                          style={
                            settings.themeMode === 'light'
                              ? { backgroundColor: '#ffffff' }
                              : { backgroundColor: '#161C24' }
                          }
                        >
                          <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />{' '}
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div className="bold_heading">{i.tracking_number}</div>
                            <div className="light_text">{i?.customer_address?.address}</div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <div className="bold_heading">{i.room_number}</div>
                            <div className="light_text">{i.status}</div>
                          </div>
                        </Box>
                      ))}
                    </div>
                  );
                }
                return null;
              })}
            </div>
            <div
              className="child_kanban_check"
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#f4f6f8' }
                  : { backgroundColor: '#2D353D' }
              }
            >
              {TIME_SLOT_FOR_ORDER?.filter((item) => item.label !== 'All')?.map((timeSlot, ind) => {
                if (tableData?.CHECKED?.[timeSlot.value]) {
                  return (
                    <div
                      key={ind}
                      style={
                        settings.themeMode === 'light'
                          ? { backgroundColor: '#f4f6f8', padding: '5px' }
                          : { backgroundColor: '#2D353D', padding: '5px' }
                      }
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '10px',
                          alignItems: 'center',
                          margin: '10px 0px',
                        }}
                      >
                        <AccessAlarmIcon style={{ color: '#2aa76f' }} />
                        <Typography variant="h6" style={{ color: '#2aa76f', fontSize: '15px' }}>
                          {timeSlot.label}
                        </Typography>
                      </Box>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '10px',
                          flexWrap: 'wrap',
                          overflowX: 'scroll',
                        }}
                      >
                        {tableData?.CHECKED?.[timeSlot.value]?.map((i, index) => (
                          <Box
                            className="box_items_check_right"
                            key={i.id}
                            data-shelf-id={i.id}
                            // onClick={clickedOrder}
                            style={
                              settings.themeMode === 'light'
                                ? {
                                    backgroundColor: '#ffffff',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '35%',
                                  }
                                : {
                                    backgroundColor: '#161C24',
                                    alignItems: 'center',
                                    width: '35%',
                                  }
                            }
                          >
                            <img src={OrderBox} width="40px" height="40px" alt="" id={i.id} />
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div className="bold_heading">{i.tracking_number}</div>
                              <div className="light_text">{i?.customer_address?.address}</div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <div className="light_text">{i.room_number}</div>
                              <div className="light_text">{i.status}</div>
                              <ZebraPrintComponent i={i} />
                            </div>
                          </Box>
                        ))}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </Box>
      </Container>

      <Box
        style={
          settings.themeMode === 'light'
            ? {
                backgroundColor: '#f4f5f8',
                minHeight: '300px',
                width: '40%',
                paddingBottom: '20px',
                border: '1px solid rgba(255, 255, 255)',
                borderRadius: '20px',
                color: 'rgb(42, 167, 111)',
                margin: '20px',
              }
            : {
                backgroundColor: '#2d353d',
                minHeight: '300px',
                width: '40%',
                paddingBottom: '20px',
                border: '1px solid rgba(255, 255, 255)',
                borderRadius: '20px',
                color: 'rgb(42, 167, 111)',
                margin: '20px',
              }
        }
      >
        <CustomBreadcrumbs
          heading="ONWAY Orders"
          sx={{
            padding: '10px',
          }}
        />
        {tableData?.onWay?.map((i, index) => (
          <Box
            className="box_items_slot"
            key={i.id}
            data-shelf-id={i.id}
            onClick={clickedOrder}
            style={
              settings.themeMode === 'light'
                ? { backgroundColor: '#ffffff', alignItems: 'center' }
                : { backgroundColor: '#161C24', alignItems: 'center' }
            }
            sx={{ width: '90%', margin: '10px' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap: '5px',
                }}
              >
                <div className="light_text" style={{ fontSize: '0.8rem' }}>
                  {i.tracking_number}
                </div>{' '}
                -
                <div className="light_text" style={{ fontSize: '0.8rem' }}>
                  {i?.shelf?.code}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: '5px',
                  justifyContent: 'flex-start',
                }}
              >
                <div className="light_text" style={{ fontSize: '0.8rem' }}>
                  {i?.customer_address?.address}
                </div>
                -
                <div className="light_text" style={{ fontSize: '0.8rem' }}>
                  {i?.customer?.first_name_ka} {i?.customer?.last_name_ka}
                </div>
              </div>
            </div>
          </Box>
        ))}
      </Box>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
};
