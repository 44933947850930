import { Helmet } from 'react-helmet-async';
import KanbanListViewWarehouse from 'src/sections/WarehouseLocation/view/kanban-list-view';

export default function WarehouseLocationListPage() {
  return (
    <>
      <Helmet>
        <title>Location In Warehouse</title>
      </Helmet>

      <KanbanListViewWarehouse />
    </>
  );
}
