import React from 'react';
import { Box, FormControl, TextField, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';

function SelectFieldForOrder({
  selectName = '',
  data = [],
  setter,
  widthComp = '100%',
  selectedValue,
  optionName = 'name',
}) {
  const handleChange = (_, newValue) => {
    setter({ target: { name: selectName, value: newValue.id } });
  };

  return (
    <Box sx={{ width: widthComp }}>
      <FormControl fullWidth variant="filled">
        <Autocomplete
          value={data.find((item) => item.id === selectedValue) || null}
          options={data}
          getOptionLabel={(option) => `${option[optionName]}` || null}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label={selectName} variant="filled" required />
          )}
        />
      </FormControl>
    </Box>
  );
}

export default SelectFieldForOrder;

SelectFieldForOrder.propTypes = {
  selectName: PropTypes.string,
  data: PropTypes.array,
  setter: PropTypes.func,
  widthComp: PropTypes.string,
  selectedValue: PropTypes.string,
  optionName: PropTypes.string,
  lableOfSelect: PropTypes.string,
};
