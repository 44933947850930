import { Helmet } from 'react-helmet-async';
import { AddressListView } from 'src/sections/address/view';

export default function AddressListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Address</title>
      </Helmet>

      <AddressListView />
    </>
  );
}
