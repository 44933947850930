import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import '../../styles/dashboard.scss';
import PropTypes from 'prop-types';
import { PATH_DASHBOARD } from 'src/config-global';
import { status_colors } from 'src/sections/customer/view/data';
import { OrderBox, notFoundIcon } from '../../assets/added_icons/index';

function OrderList({ aRow }) {
  const [showAllOrders, setShowAllOrders] = useState(false);
  const redirectToOrder = (id) => {
    const url = `${PATH_DASHBOARD.orders.root}/${id}`;
    window.open(url, '_blank');
  };
  const itemsToShow = showAllOrders ? aRow?.orders : aRow?.orders?.slice(0, 2);
  return (
    <>
      <div style={{ overflow: 'unset', marginTop: '24px', padding: '0px 10px' }}>
        <Typography gutterBottom className="subTitles">
          Attached orders
        </Typography>
      </div>
      {itemsToShow?.length ? (
        <>
          <div className="orders" style={{ padding: '0px 10px' }}>
            {itemsToShow?.map((item, index) => (
              <div
                key={index}
                className="TBrow"
                id={item.id}
                onClick={(e) => redirectToOrder(e.target.id)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    redirectToOrder(e.target.id);
                  }
                }}
                role="button"
                tabIndex={0}
              >
                <Box sx={{ display: 'flex', gap: '60px', alignItems: 'center' }} id={item.id}>
                  <img src={OrderBox} width="40px" height="40px" alt="" id={item.id} />
                  <div className="aligh_row_items" id={item.id}>
                    <div
                      className="bold_heading"
                      id={item.id}
                      style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}
                    >
                      {item.tracking_number}
                    </div>
                    <div className="light_text" id={item.id}>
                      {item.room_number}
                    </div>
                  </div>
                  <div className="aligh_row_items" id={item.id}>
                    <div className="bold_heading" id={item.id}>
                      {item?.price_gz}
                    </div>
                    <div className="light_text" id={item.id}>
                      {item.weight ? item.weight : 0}
                    </div>
                  </div>

                  <Box
                    className="arrow_and_status"
                    id={item.id}
                    sx={{
                      d: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start !important',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div
                      className="status"
                      id={item.id}
                      style={{
                        backgroundColor: status_colors[item?.status]
                          ? status_colors[item?.status][0]
                          : '',
                        color: status_colors[item?.status] ? status_colors[item?.status][1] : '',
                      }}
                    >
                      {item.status}
                    </div>
                    <div
                      className="status"
                      id={item.id}
                      style={{ color: item?.declaration_status ? 'green' : 'red' }}
                    >
                      {item?.declaration_status ? 'DECLARED' : 'NOT DECLARED'}
                    </div>
                  </Box>
                </Box>
              </div>
            ))}
          </div>
          {aRow?.orders.length >= 3 ? (
            <Button
              variant="outlined"
              sx={{ width: '97%', margin: '0px 10px' }}
              onClick={() => setShowAllOrders(!showAllOrders)}
            >
              {showAllOrders ? 'Show Less' : 'Show All'}
            </Button>
          ) : (
            ''
          )}
        </>
      ) : (
        <img src={notFoundIcon} alt="" width="15%" style={{ margin: '0px 20px' }} />
      )}
    </>
  );
}

OrderList.propTypes = {
  aRow: PropTypes.object,
};
export default OrderList;
