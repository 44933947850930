// CustomerContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { redirect } from 'react-router';
import PropTypes from 'prop-types'; // @mui
import { apiService } from 'src/utils/apiServices';
import { toast } from 'react-toastify';

const CustomerContext = createContext();
const CustomerActionsContext = createContext();

export const CustomerProvider = ({ children }) => {
  const [customers, setCustomers] = useState([]);
  const [custumerRecordDetails, setCustomerRecordDetails] = useState({});

  const createCustomer = async (newCustomer) => {
    try {
      // Simulate API call to create customer
      const response = await apiService.post('/customers', newCustomer, {
        headers: {
          timeout: 20000,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.data;
      setCustomers((prevCustomers) => [...prevCustomers, data]);
      toast.success('customer created!');
    } catch (error) {
      toast.error('Error creating customer!');
      console.error('Error creating customer:', error);
    }
  };

  const deleteCustomer = async (customerId) => {
    try {
      await apiService.put(
        '/customers/update/soft',
        { ids: customerId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setCustomers((prevCustomers) =>
        prevCustomers.filter((customer) => !customerId.includes(customer.id))
      );
      toast.success('customer deleted!');
    } catch (error) {
      toast.error('Error deleting customer');
      console.error('Error deleting customer:', error);
    }
  };

  const updateCustomer = async (customerId, updatedCustomer) => {
    try {
      // Simulate API call to update customer
      const response = await apiService.put(
        `/customers/updateUserFromDashboard/${customerId}`,
        updatedCustomer,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      const data = await response.data;
      setCustomers((prevCustomers) =>
        prevCustomers.map((customer) =>
          customer.id === customerId ? { ...customer, ...data } : customer
        )
      );
      toast.success('customer updated!');
    } catch (error) {
      toast.error('Error updating customer');
      console.error('Error updating customer:', error);
    }
  };

  const getCustomers = async (filters = {}) => {
    try {
      const effectiveFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (key === 'role' && Array.isArray(value) && value.length === 0) {
          return acc; // skip adding to the accumulator
        }
        acc[key] = value; // otherwise add to the accumulator
        return acc;
      }, {});

      const query = new URLSearchParams(effectiveFilters).toString();
      // Simulate API call to get customers
      const response = await apiService.get(`/customers?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data.customers;
      setCustomers(data);
      setCustomerRecordDetails({
        page: response.data.page,
        take: response.data.take,
        total: response.data.total,
        totalPages: Math.ceil(+response.data.total / +response.data.take),
      });
    } catch (error) {
      console.error('Error fetching customers:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
    }
  };

  const getCustomerById = async (customerId) => {
    try {
      // Simulate API call to get customer by ID
      const response = await apiService.get(`/customers/${customerId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching customer by ID:', error);
      throw error;
    }
  };

  const deleteCard = async (customerId, cardId) => {
    try {
      const response = await apiService.post(
        `/customers/deleteCard`,
        { id: customerId, cardId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      toast.success('Card deleted successfully');
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      throw error;
    }
  };

  const getCustomerCards = async (id) => {
    try {
      // Simulate API call to get customer by ID
      const response = await apiService.get(`/customers/findCards/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching customer by ID:', error);
      throw error;
    }
  };
  useEffect(() => {
    getCustomers();
  }, []);

  const createCustomerCallback = useCallback(createCustomer, []);
  const deleteCustomerCallback = useCallback(deleteCustomer, []);
  const updateCustomerCallback = useCallback(updateCustomer, [updateCustomer]);
  const getCustomersCallback = useCallback(getCustomers, []);
  const getCustomerByIdCallback = useCallback(getCustomerById, []);
  const getCustomerCardsCallback = useCallback(getCustomerCards, []);
  const deleteCardCallback = useCallback(deleteCard, []);
  const customerActionsValue = useMemo(
    () => ({
      createCustomer: createCustomerCallback,
      deleteCustomer: deleteCustomerCallback,
      updateCustomer: updateCustomerCallback,
      getCustomers: getCustomersCallback,
      getCustomerById: getCustomerByIdCallback,
      getCustomerCards: getCustomerCardsCallback,
      deleteCard: deleteCardCallback,
    }),
    [
      createCustomerCallback,
      deleteCustomerCallback,
      updateCustomerCallback,
      getCustomersCallback,
      getCustomerByIdCallback,
      getCustomerCardsCallback,
      deleteCardCallback,
    ]
  );

  const customersValue = useMemo(
    () => ({
      customers,
      custumerRecordDetails,
    }),
    [customers, custumerRecordDetails]
  );

  return (
    <CustomerContext.Provider value={customersValue}>
      <CustomerActionsContext.Provider value={customerActionsValue}>
        {children}
      </CustomerActionsContext.Provider>
    </CustomerContext.Provider>
  );
};

export const useCustomerData = () => useContext(CustomerContext);
export const useCustomerActions = () => useContext(CustomerActionsContext);

CustomerProvider.propTypes = {
  children: PropTypes.element,
};
