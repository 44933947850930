const inputData = [
  {
    page: 'page1',
    identifier: 'identifier1',
    nameKa: 'nameKa',
    nameEn: 'nameEn',
    name: 'name',
  },
];

const inputDataRoles = [
  {
    first_name: 'first_name',
    last_name: 'last_name',
    email: 'email',
    password: 'password',
  },
];

const tableNames = [
  { id: 'identifier', label: 'identifier', width: 380 },
  { id: 'page', label: 'page', width: 380 },
  { id: 'nameKa', label: 'nameKa', width: 380 },
  { id: 'nameEn', label: 'nameEn', width: 380 },
];

const TABLE_HEAD = [
  { id: 'identifier', label: 'identifier', width: 380 },
  { id: 'page', label: 'page', width: 380 },
  { id: 'nameEn', label: 'nameEn', width: 380 },
  { id: 'nameKa', label: 'nameKa', width: 380 },
  { id: '', width: 100 },
];
const personalInformation = [
  {
    name: 'nameKa',
    width: '100%',
    label: 'nameKa',
  },

  {
    name: 'nameEn',
    width: '100%',
    label: 'nameEn',
  },
];

export { inputData, inputDataRoles, tableNames, TABLE_HEAD, personalInformation };
