import { jwtDecode } from 'jwt-decode';
import { ORDER_STATUS_OPTIONS } from 'src/_mock';

const auth = JSON.parse(localStorage.getItem('token'));
let decoded = '';
if (auth) {
  decoded = jwtDecode(auth);
}

const STATUS_OPTIONS = [...ORDER_STATUS_OPTIONS];
const TIME_SLOT_FOR_ORDER =
  decoded?.location === 'Georgia'
    ? [
        { value: 'all', label: 'All' },
        { value: '9:00-12:00', label: '9AM TO 12PM' },
        { value: '12:00-15:00', label: '12PM TO 15PM' },
        { value: '15:00-19:00', label: '15PM TO 19PM' },
        { value: '19:00-22:00', label: '19PM TO 22PM' },
        { value: '22:00-01:00', label: '22PM TO 1AM' },
      ]
    : [];

const filterKeys = [
  {
    key: 'status',
    label: 'Status',
    options: STATUS_OPTIONS,
  },
  {
    key: 'declaration_status',
    label: 'Declaration Status',
    options: [
      { value: 'DECLERED', label: 'Declared' },
      { value: 'NOT DECLERED', label: 'Not Declared' },
    ],
  },
  {
    key: 'custom_status',
    label: 'Custom Status',
    options: [
      { value: '0', label: '0' },
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
      { value: '6', label: '6' },
      { value: '7', label: '7' },
      { value: '8', label: '8' },
      { value: '9', label: '9' },
      { value: '10', label: '10' },
    ],
  },
  {
    key: 'delivery_status',
    label: 'Delivery Status',
    options: [
      { value: 'DELIVERED', label: 'Delivered' },
      { value: 'NOT DELIVERED', label: 'Not Delivered' },
    ],
  },
  {
    key: 'vatPaymentAuto',
    label: 'Yellow Pay by user',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    key: 'DeclarationPaymentAuto',
    label: 'RS Fee Pay GZ',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    key: 'NeedsCustom',
    label: 'NeedsCustom',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    key: 'vat',
    label: 'Yellow status',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    key: 'invoiceStatus',
    label: 'invoiceStatus',
    options: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    key: 'vat_payment_status',
    label: 'Yellow Pay by GZ',
    options: [
      { value: true, label: 'YES' },
      { value: false, label: 'NO' },
    ],
  },
];

const OTHER_STATUS_OPTIONS_FOR_ORDER = [
  { value: '', label: 'All' },
  { value: 'RECEIVED IN US WAREHOUSE', label: 'RECEIVED IN US WAREHOUSE' },
  { value: 'CUSTOMS CLEARANCE', label: 'CUSTOMS CLEARANCE' },
  { value: 'RECEIVED IN TBILISI WAREHOUSE', label: 'RECEIVED IN TBILISI WAREHOUSE' },
];

const TABLE_HEAD = [
  { id: 'tracking_number', label: 'Tracking', width: 160 },
  { id: 'room_number', label: 'Room', width: 140 },
  { id: 'weight', label: 'Weight', width: 140 },
  { id: 'NeedsCustom', label: 'Custom', width: 120 },
  { id: 'declaration_status', label: 'declaration', width: 120 },
  { id: 'nusxa', label: 'nusxa', width: 120 },
  { id: 'decSum', label: 'decSum', width: 90 },
  { id: 'pallet', label: 'pallet', width: 90 },
  { id: 'custom_status', label: 'custom_status', width: 120 },
  { id: 'status', label: 'Status', width: 120 },
  { id: '', width: 88 },
];

const defaultFilters = {
  role: [],
};

const tableNames = [
  { id: 'tracking_number', label: 'Tracking' },
  { id: 'room_number', label: 'Room' },
  { id: 'weight', label: 'Weight' },
  { id: 'NeedsCustom', label: 'Custom' },
  { id: 'declaration_status', label: 'declaration' },
  { id: 'nusxa', label: 'Nusxa' },
  { id: 'decSum', label: 'decSum' },
  { id: 'pallet', label: 'pallet' },
  { id: 'custom_status', label: 'custom_status' },
  { id: 'status', label: 'Status' },
];

// dashboard statuses 2

const statusCreate = ['RECEIVED IN US WAREHOUSE', 'NOT IDENTIFIED', 'ON HOLD', 'DAMAGED'];

const status = [];
if (auth) {
  if (decoded.location === 'USA') {
    const statusArr = [
      'RECEIVED IN US WAREHOUSE',
      'ON HOLD',
      'NOT IDENTIFIED',
      'DAMAGED',
      'ON THE WAY FROM US WAREHOUSE',
      'DROPPED IN US AIRPORT FACILITY',
      'AIR SHIPPED FROM US',
      'DELAYED',
    ];
    status.push(...statusArr);
  }
  if (decoded.location === 'Georgia') {
    const statusArr = [
      'AWAITING',
      'RECEIVED IN US WAREHOUSE',
      'ON HOLD',
      'NOT IDENTIFIED',
      'DAMAGED',
      'ON THE WAY FROM US WAREHOUSE',
      'DROPPED IN US AIRPORT FACILITY',
      'AIR SHIPPED FROM US',
      'DELAYED',
      'CUSTOMS CLEARANCE',
      'CUSTOMS EXPERTISE',
      'RECEIVED IN TBILISI WAREHOUSE',
      'OUT FOR DELIVERY',
      // 'FAILED DELIVERY ATTEMPT',
      'DELIVERED',
      'CANCELED',
      'DEADLINE FOR THE CUSTOMS CLEARANCE COMPLETION',
      'NEEDS TO BE TRANSFERRED TO GOVERNMENT',
      'TRANSFERRED TO GOVERNMENT',
      'LOST PARCEL',
      'DELETED',
      'PICKED UP',
      'CHECKED',
      'FAILED DELIVERY',
      'NOT DECLARED',
    ];
    status.push(...statusArr);
  }
  if (decoded.location === 'China') {
    const statusArr = [
      'AWAITING',
      'RECEIVED IN US WAREHOUSE',
      'ON HOLD',
      'NOT IDENTIFIED',
      'DAMAGED',
      'ON THE WAY FROM US WAREHOUSE',
      'DROPPED IN US AIRPORT FACILITY',
      'AIR SHIPPED FROM US',
      'DELAYED',
      'CUSTOMS CLEARANCE',
      'CUSTOMS EXPERTISE',
      'RECEIVED IN TBILISI WAREHOUSE',
      'OUT FOR DELIVERY',
      // 'FAILED DELIVERY ATTEMPT',
      'DELIVERED',
      'CANCELED',
      'DEADLINE FOR THE CUSTOMS CLEARANCE COMPLETION',
      'NEEDS TO BE TRANSFERRED TO GOVERNMENT',
      'TRANSFERRED TO GOVERNMENT',
      'LOST PARCEL',
      'DELETED',
      'PICKED UP',
      'CHECKED',
      'FAILED DELIVERY',
      'NOT DECLARED',
    ];
    status.push(...statusArr);
  }
}

const trackingInformation = [
  {
    name: 'tracking_number',
    width: '48%',
    label: 'Tracking',
  },
  {
    name: 'tracking_number_2',
    width: '48%',
    label: 'Traking 2',
  },
  {
    name: 'room_number',
    width: '96%',
    label: 'Room Number',
  },
];

const orderDetails = [
  // {
  //   name: 'name',
  //   width: '96%',
  //   label: 'Order Name',
  //   disabled: true,
  // },
  {
    name: 'weight_before',
    label: 'Weight Before',
    // type: 'number',
    width: '96%',
    req: false,
  },
  {
    name: 'len_before',
    label: 'Length Before',
    // type: 'number',
    width: '48%',
    req: false,
  },
  {
    name: 'width_before',
    label: 'Width Before',
    // type: 'number',
    width: '48%',

    req: false,
  },
  {
    name: 'height_before',
    label: 'Height Before',
    // type: 'number',
    width: '48%',
    req: false,
  },
  {
    name: 'volume_before',
    label: 'Volume Before',
    // type: 'number',
    width: '48%',
    req: false,
  },
  {
    name: 'weight',
    width: '96%',
    // type: 'number',
    label: 'weight',
  },
  {
    name: 'len',
    width: '48%',
    // type: 'number',
    label: 'length',
  },
  {
    name: 'width',
    width: '48%',
    // type: 'number',
    label: 'width',
  },
  {
    name: 'height',
    width: '48%',
    // type: 'number',
    label: 'height',
  },

  {
    name: 'volume',
    width: '48%',
    // type: 'number',
    label: 'volume',
  },
  {
    name: 'price_gz',
    width: '96%',
    label: 'price_gz',
  },
];

const declaration = [
  {
    name: 'declaration_id_for_vat',
    label: 'Yellow declaration ID',
    type: 'number',
    width: '96%',
    req: false,
  },
  {
    name: 'vat_amount',
    label: 'Yellow amount',
    type: 'number',
    width: '96%',
    req: false,
  },
];

const additionalInformation = [
  {
    name: 'branch_id',
    width: '96%',
    label: 'Branch ID',
  },
  {
    name: 'comment',
    width: '96%',
    label: 'Comment',
  },
];
const time_slot_select = [
  {
    id: '9:00-12:00',
    name: '9AM TO 12PM',
    value: '9:00-12:00',
  },
  {
    id: '12:00-15:00',
    name: '12PM TO 15PM',
    value: '12:00-15:00',
  },
  {
    id: '15:00-19:00',
    name: '15PM TO 19PM',
    value: '15:00-19:00',
  },
  {
    id: '19:00-22:00',
    name: '19PM TO 22PM',
    value: '19:00-22:00',
  },
  {
    id: '22:00-01:00',
    name: '22PM TO 1AM',
    value: '22:00-01:00',
  },
];

const status_colors = {
  ACTIVE: ['#22C55E29', '#118D57'],
  INACTIVE: ['#919EAB29', '#637381'],
  SUSPENDED: ['#FFAB0029', '#B76E00'],
  CLOSED: ['#FF563029', '#B71D18'],

  PLACED: ['#22C55E29', '#118D57'],
  PROCESSING: ['#FF563029', '#B71D18'],
  SHIPPED: ['#FFAB0029', '#B76E00'],
  OUT_FOR_DELIVERY: ['#DDF4FA', '#1A6C9C'],
  RETURN_TO_SENDER: ['#919EAB29', '#637381'],
  DELAYED: ['#919EAB29', '#637381'],
  CANCELLED: ['#919EAB29', '#637381'],
  NO_TRACKING_OR_ROOM: ['#919EAB29', '#637381'],
  DELETED: ['#919EAB29', '#637381'],

  AWAITING: ['#919EAB29,#637381'],
  RECEIVED_IN_US_WAREHOUSE: ['#919EAB29,#637381'],
  HOLD_IN_US_WAREHOUSE: ['#919EAB29,#637381'],
  DAMAGED: ['#919EAB29,#637381'],
  DROPPED_IN_US_AIRPORT_FACILITY: ['#919EAB29,#637381'],
  AIR_SHIPPED_FROM_US: ['#919EAB29,#637381'],
  CUSTOMS_CLEARANCE: ['#919EAB29,#637381'],
  CUSTOMS_EXPERTISE: ['#919EAB29,#637381'],
  RECEIVED_IN_TBILISI_WAREHOUSE: ['#919EAB29,#637381'],
  // FAILED_DELIVERY_ATTEMPT: ['#919EAB29,#637381'],
  DELIVERED: ['#919EAB29,#637381'],
  CANCELED: ['#919EAB29,#637381'],
  UNCLEAR_ORDER: ['#919EAB29,#637381'],
  ACTION_NEEDED: ['#919EAB29,#637381'],
  NEEDS_CUSTOM_CLEARANCE: ['#919EAB29,#637381'],
  DEADLINE_FOR_CUSTOMS_CLEARANCE_COMPLETION: ['#919EAB29,#637381'],
  NEEDS_TO_BE_TRANSFERRED_TO_GOVERNMENT: ['#919EAB29,#637381'],
  TRANSFERRED_TO_GOVERNMENT: ['#919EAB29,#637381'],
  LOST_PARCEL: ['#919EAB29,#637381'],
};

/// order creation

export {
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  status,
  trackingInformation,
  orderDetails,
  additionalInformation,
  status_colors,
  OTHER_STATUS_OPTIONS_FOR_ORDER,
  TIME_SLOT_FOR_ORDER,
  time_slot_select,
  statusCreate,
  declaration,
  filterKeys,
};
