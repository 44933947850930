import { Button, Drawer, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import InputFields from 'src/custom_components/Froms/InputFields';
import { useState } from 'react';
import { useCountryActions } from 'src/context/providers/CountryContext';
import { status, personalInformation } from './view/data';

const CreateDrawerCountry = ({ isDrawerOpen, handleDrawerToggle }) => {
  const [countryData, setCountryData] = useState({
    name: '',
    currency: '',
    price: '',
    status: '',
  });

  const { createCountry } = useCountryActions();

  const Clear = () => {
    setCountryData({});
  };

  const handleCreateCountry = () => {
    const countryDataCreate = {
      name: countryData.name,
      currency: [countryData.currency],
      price: countryData.price,
      status: countryData.status,
    };
    createCountry(countryDataCreate);
    Clear();
    handleDrawerToggle();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCountryData({
      ...countryData,
      [name]: value,
    });
  };
  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '35%',
    drawerHeaderText: 'Add New Country',
    buttons: {
      status: true,
      elements: [{ name: 'Create', onClick: handleCreateCountry }],
    },
  };
  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              width: '30%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField
              name="status"
              label="status"
              value={countryData.status}
              onChange={handleInputChange}
              variant="filled"
              sx={{ minWidth: '80%' }}
              select
            >
              {status.map((i, index) => (
                <MenuItem key={index} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>

            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Form */}
        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Form */}

          {/* Personal Information */}
          <InputFields
            userData={countryData}
            heading="Country Information"
            inputFields={personalInformation}
            setUserData={setCountryData}
          />
          {/* Personal Information */}
        </div>

        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid rgba(220, 220, 220, 0.2)',
              padding: '16px',
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
            }}
          >
            {DrawerConfig.buttons.elements.map((element) => (
              <Button
                variant="contained"
                style={{
                  marginLeft: '24px',
                  width: '136px',
                }}
                onClick={element.onClick}
              >
                Create
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CreateDrawerCountry.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default CreateDrawerCountry;
