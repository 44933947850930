import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const PriceContext = createContext();
const PriceActionsContext = createContext();

export const PriceProvider = ({ children }) => {
  const [prices, setPrices] = useState([]);

  const updatePrice = async (priceId, updatedPrice) => {
    try {
      const response = await apiService.patch(`/prices/${priceId}`, updatedPrice, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      if (data) {
        getPrices();
      }
      toast.success('Price updated!');
    } catch (error) {
      toast.error('Error updating Price!');
      console.error('Error updating Price:', error);
    }
  };

  const getPrices = async () => {
    try {
      // Simulate API call to get Prices
      const response = await apiService.get(`/prices`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setPrices(data);
    } catch (error) {
      console.error('Error fetching Prices:', error);
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
    }
  };
  useEffect(() => {
    getPrices();
  }, []);

  const updatePriceCallback = useCallback(updatePrice, []);
  const getPricesCallback = useCallback(getPrices, []);
  const PriceActionsValue = useMemo(
    () => ({
      updatePrice: updatePriceCallback,
      getPrices: getPricesCallback,
    }),
    [updatePriceCallback, getPricesCallback]
  );

  const PricesValue = useMemo(() => prices, [prices]);

  return (
    <PriceContext.Provider value={PricesValue}>
      <PriceActionsContext.Provider value={PriceActionsValue}>
        {children}
      </PriceActionsContext.Provider>
    </PriceContext.Provider>
  );
};

export const usePriceData = () => useContext(PriceContext);
export const usePriceActions = () => useContext(PriceActionsContext);

PriceProvider.propTypes = {
  children: PropTypes.element,
};
