import { Button, Drawer, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useAddressActions } from 'src/context/providers/AddressContext';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import InputFieds from '../../custom_components/Froms/InputFields';
import { personalInformation } from './view/data';

const CreateAddressDrawer = ({ isDrawerOpen, handleDrawerToggle }) => {
  const [AddressData, setAddressData] = useState({
    street: '',
    city: '',
    state: '',
    country: '',
    tel: '',
    zipCode:''
  });

  const { createAddress } = useAddressActions();

  const Clear = () => {
    setAddressData({});
  };

  const handleCreateairwayBill = async () => {
    const AddressData1 = {
      street: AddressData.street,
      city: AddressData.city,
      state: AddressData.state,
      country: AddressData.country,
      tel: AddressData.tel,
      zipCode: AddressData.zipCode,
    };
    await createAddress(AddressData1);
    Clear();
    handleDrawerToggle();
  };
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setAddressData({
  //     ...AddressData,
  //     [name]: value,
  //   });
  // };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '35%',
    drawerHeaderText: 'Create Address',
    buttons: {
      status: true,
      elements: [{ name: 'Create', onClick: handleCreateairwayBill }],
    },
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              width: '10%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Form */}
        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Form */}

          {/* Personal Information */}
          <InputFieds
            userData={AddressData}
            heading="Address Information"
            inputFields={personalInformation}
            setUserData={setAddressData}
          />
          {/* Personal Information */}
        </div>

        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid rgba(220, 220, 220, 0.2)',
              padding: '16px',
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
            }}
          >
            {DrawerConfig.buttons.elements.map((element, index) => (
              <Button
                variant="contained"
                key={index}
                style={{
                  marginLeft: '24px',
                  width: '136px',
                }}
                onClick={element.onClick}
              >
                Create
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CreateAddressDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default CreateAddressDrawer;
