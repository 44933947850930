const TABLE_HEAD = [
  { id: 'state', label: 'state', width: 100 },
  { id: 'country', label: 'Country', width: 100 },
  { id: 'city', label: 'City', width: 100 },
  { id: 'street', label: 'Street', width: 100 },
  { id: 'tel', label: 'Phone Number', width: 100 },
  { id: 'zipCode', label: 'Zip Code', width: 100 },

  { id: '', width: 100 },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
  tracking: 'tracking',
};
const tableNames = [
  { id: 'state', label: 'State' },
  { id: 'country', label: 'Country' },
  { id: 'city', label: 'City' },
  { id: 'street', label: 'Street' },
  { id: 'tel', label: 'Phone Number' },
  { id: 'zipCode', label: 'Zip Code' },
];

const status = ['AWAITING', 'ON AIR', 'LANDED'];

const personalInformation = [
  {
    name: 'state',
    width: '97%',
    label: 'State',
  },
  {
    name: 'country',
    width: '48%',
    label: 'Country',
  },
  {
    name: 'city',
    width: '48%',
    label: 'City',
  },
  {
    name: 'street',
    width: '49%',
    label: 'Street',
  },
  {
    name: 'tel',
    width: '48%',
    label: 'Phone Number',
  },
  {
    name: 'zipCode',
    width: '97%',
    label: 'Zip Code',
  },
];

const status_colors = {
  ACTIVE: ['#22C55E29', '#118D57'],
  INACTIVE: ['#919EAB29', '#637381'],
};

export { TABLE_HEAD, defaultFilters, tableNames, status, personalInformation, status_colors };
