import React, { useRef } from 'react';
import { Button } from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Barcode from 'react-barcode';
import PropTypes from 'prop-types';

function ZebraPrintComponentBarCodes({ tracking }) {
  const ref = useRef(null);

  const generateAndPrintPDF = async () => {
    if (ref.current) {
      try {
        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL('image/png');

        // Create a PDF with custom dimensions (10 cm x 14 cm converted to mm)
        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
          orientation: 'p',
          unit: 'mm',
        });

        // Scale the canvas to fit into the PDF
        const imgProps = pdf.getImageProperties(imgData);
        let imgWidth = imgProps.width;
        let imgHeight = imgProps.height;

        // Calculate the scale to fit the image inside the PDF page while maintaining aspect ratio
        const scaleX = 100 / imgWidth;
        const scaleY = 70 / imgHeight;
        const scale = Math.min(scaleX, scaleY); // Use the smallest scale to ensure the entire image fits
        imgWidth *= scale;
        imgHeight *= scale;

        // Optionally center the image
        const x = (100 - imgWidth) / 2;
        const y = (70 - imgHeight) / 2;

        // Add the scaled image to the PDF
        pdf.addImage(imgData, 'PNG', x, y, imgWidth, imgHeight);

        const pdfBlob = pdf.output('blob');
        const url = URL.createObjectURL(pdfBlob);
        const printWindow = window.open(url, '_blank');
        // eslint-disable-next-line
        printWindow.onload = function () {
          try {
            printWindow.print();
          } catch (e) {
            console.error('Error triggering print dialog:', e);
          }
        };
      } catch (error) {
        console.error('Error generating or printing PDF:', error);
      }
    }
  };
  return (
    <div>
      <Button
        variant="contained"
        onClick={generateAndPrintPDF}
        style={{ margin: '10px', width: '90%' }}
      >
        Print
      </Button>

      <div
        ref={ref}
        style={{
          width: '80mm',
          height: '60mm',
          padding: '2px 2px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 20px 20px 0px',
          }}
        >
          <Barcode value={tracking} height="60px" />
        </div>
      </div>
    </div>
  );
}

export default ZebraPrintComponentBarCodes;
ZebraPrintComponentBarCodes.propTypes = {
  tracking: PropTypes.string.isRequired, // or PropTypes.string if it's optional
};
