import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
// Add imports for DatePicker and LocalizationProvider
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Button,
  Tooltip,
  Container,
  TableBody,
  IconButton,
  TableContainer,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Box,
  InputLabel,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// import { useParams } from 'react-router-dom';

// _mock
// import { _roles } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  // getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import PropTypes from 'prop-types';
// import { getColorForStatus } from 'src/custom_components/Table/helper/getColorStatus';
import { useOrderActions, useOrderData } from 'src/context/providers/OrderContext';
import { apiService } from 'src/utils/apiServices';
import { format, parseISO } from 'date-fns';
import { usePalletActions } from 'src/context/providers/PalleteContext';
// import { applyFilter } from 'src/custom_components/Table/helper/applyFilter';
import { debounce } from 'lodash';
import UserTableRow from '../../custom_components/Table/customer-table-row';
import UserTableToolbar from '../../custom_components/Table/customer-table-toolbar';
import UserTableFiltersResult from '../../custom_components/Table/customer-table-filters-result';
import OrderDrawerComponent from './order-drawer-component';
import OrderCreation from './order-creation';
import { filterKeys } from './view/data';

export default function TcTable({
  STATUS_OPTIONS,
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  OTHER_STATUS_OPTIONS_FOR_ORDER,
  TIME_SLOT_FOR_ORDER,
}) {
  const table = useTable();
  const settings = useSettingsContext();
  const { deleteOrder, getOrderss, getOrderById } = useOrderActions();
  const tableData = useOrderData().orders;
  const tableDetails = useOrderData().orderRecordDetails;

  const [aRow, setARow] = useState({});

  const router = useRouter();

  const [loadingPallet, setLoadingPallet] = useState(false);
  const [palletOptions, setPalletOptions] = useState([]);
  const { getPalletes } = usePalletActions();
  const [pallet, setPallet] = useState({
    id: '',
    tracking: '',
  });

  const confirm = useBoolean();
  const { order_id } = useParams();
  // pallet input logic

  const fetchPalletes = useCallback(
    async (filters) => {
      setLoadingPallet(true);
      try {
        const response = await getPalletes(filters); // Make API call with filters
        setPalletOptions(response?.pallets); // Assuming API returns an array of pallet data
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingPallet(false);
      }
    },
    [getPalletes]
  );

  useEffect(() => {
    const initialFilters = {
      take: 5,
    };
    fetchPalletes(initialFilters);
  }, [fetchPalletes]);
  // Fetch pallet data based on input
  const handlePalletInputChange = debounce((event, newInputValue) => {
    console.log(newInputValue);
    const filters = {
      tracking: newInputValue,
      take: 5,
    };
    fetchPalletes(filters);
  }, 500);

  useEffect(() => {
    if (order_id) {
      const rowFromRouter = tableData.find((item) => item.id === order_id);
      if (rowFromRouter) {
        setARow(rowFromRouter);
        setIsDrawerOpen(true);
      } else {
        // Fetch the order by ID if not found in the current table data
        getOrderById(order_id)
          .then((fetchedOrder) => {
            if (fetchedOrder) {
              setARow(fetchedOrder);
              setIsDrawerOpen(true);
            }
          })
          .catch((error) => {
            console.error('Error fetching order by ID:', error);
          });
      }
    }
  }, [order_id, tableData, getOrderById]);

  const [filters, setFilters] = useState({
    ...defaultFilters,
  });
  const [dataFiltered, setDataFiltered] = useState(tableData);
  useEffect(() => {
    const sortedData = [...tableData].sort((itemA, itemB) => {
      if (table.orderBy) {
        const aValue = itemA[table.orderBy];
        const bValue = itemB[table.orderBy];

        const compare = (a, b) => {
          switch (true) {
            case typeof a === 'boolean' && typeof b === 'boolean': {
              if (a === b) return 0;
              // eslint-disable-next-line
              return table.order === 'asc' ? (a ? 1 : -1) : a ? -1 : 1;
            }
            default: {
              const aStr = (a ?? '').toString().toLowerCase();
              const bStr = (b ?? '').toString().toLowerCase();

              if (aStr < bStr) {
                return table.order === 'asc' ? -1 : 1;
                // eslint-disable-next-line
              } else if (aStr > bStr) {
                return table.order === 'asc' ? 1 : -1;
              }
              return 0;
            }
          }
        };

        return compare(aValue, bValue);
      }
      return 0;
    });

    setDataFiltered(sortedData);
  }, [table.order, table.orderBy, tableData]);

  // const dataInPage = dataFiltered.slice(
  //   table.page * table.rowsPerPage,
  //   table.page * table.rowsPerPage + table.rowsPerPage
  // );
  const [selectedFilterKey, setSelectedFilterKey] = useState('');
  const [selectedFilterValue, setSelectedFilterValue] = useState('');

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );
  const handleDeleteRow = useCallback(
    (e, id) => {
      e.stopPropagation();
      deleteOrder([id]);
      // table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [deleteOrder]
  );

  const handleDeleteRows = useCallback(() => {
    deleteOrder(table.selected);
  }, [table, deleteOrder]);

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.user.edit(id));
    },
    [router]
  );

  // const handleFilterStatus = useCallback(
  //   (event, newValue) => {
  //     handleFilters('status', newValue);
  //   },
  //   [handleFilters]
  // );

  const handleAdditionalFilterChange = useCallback(
    (event, newValue) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  // const handleAdditionalFilterChangeTimeSlot = useCallback(
  //   (event, newValue) => {
  //     handleFilters('timeSlot', newValue);
  //   },
  //   [handleFilters]
  // );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, [defaultFilters]);

  // Custom
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isOrderDrawerOpen, setIsOrderDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleOrderDrawerToggle = () => {
    setIsOrderDrawerOpen(!isOrderDrawerOpen);
  };
  // useMemo(() => {
  //   const rowFromRouter = tableData.filter((item) => item.id === order_id);
  //   if (rowFromRouter.length) {
  //     setARow(rowFromRouter[0]);
  //     setIsDrawerOpen(true);
  //   }
  // }, [order_id, tableData]);

  // Update handleFilters function to handle startDate and endDate
  const handleDateChange = (name, value) => {
    // Format the date to ISO string with time details before setting state
    const formattedDate = value ? format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    handleFilters(name, formattedDate);
  };

  const resetFilters = useCallback(
    (key) => {
      const newFilters = { ...defaultFilters };
      Object.keys(newFilters).forEach((filterKey) => {
        if (filterKey !== key) {
          newFilters[filterKey] = ''; // or null, depending on your default filter values
        }
      });
      return newFilters;
    },
    [defaultFilters]
  );

  const handleFiltersDynamic = useCallback(
    (key, value) => {
      setFilters((prevState) => ({
        ...resetFilters(key),
        [key]: value,
      }));
      table.onResetPage();
    },
    [table, resetFilters]
  );

  useEffect(() => {
    getOrderss(filters);
  }, [filters, getOrderss]);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleDateChangeExcel = (name, value) => {
    const formattedDate = value ? format(value, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : null;
    if (name === 'startDate') {
      setStartDate(formattedDate);
    } else if (name === 'endDate') {
      setEndDate(formattedDate);
    }
  };

  const downloadExcelReport = async () => {
    try {
      const response = await apiService.get('orders/getPricesByDate', {
        params: {
          startDate,
          endDate,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = response.data;
      const csvHeaders = [
        'custom_fee',
        'customer',
        'gzTransportation',
        'orderTrackings',
        'transportation',
        'total',
        'date',
      ];
      // Function to convert JSON to CSV
      const jsonToCsv = (jsonData, headers) => {
        const rows = jsonData.map((row) =>
          headers
            .map((header) => {
              let value = row[header];
              if (Array.isArray(value)) {
                value = value.join(';'); // Join array values with a semicolon
              }
              return `${value || ''}`; // Quote the values to handle commas in data
            })
            .join(', ')
        );
        return [headers.join(','), ...rows].join('\n');
      };

      // Convert data to CSV format
      const csvContent = jsonToCsv(data, csvHeaders);

      // Create a Blob for the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      // Create a link to trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'orders_report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
      // toast.error('Error downloading the Excel report!');
    }
  };

  return (
    <div>
      {!tableData.length ? <LinearProgress color="success" /> : ''}
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {Object.keys(aRow).length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}

        <OrderCreation
          isDrawerOpen={isOrderDrawerOpen}
          handleDrawerToggle={handleOrderDrawerToggle}
        />

        <CustomBreadcrumbs
          heading="Order List"
          action={
            <Button
              component={RouterLink}
              onClick={handleOrderDrawerToggle}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              New Order
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          {/* <FormControl
            style={{ padding: '10px' }}
            value={filters.status}
            onChange={handleFilterStatus}
          >
            <Select
              value={filters.status}
              onChange={(event) => handleFilterStatus(event, event.target.value)}
              style={{
                margin: '5px ',
                backgroundColor: settings.theme === 'light' ? 'white' : '',
                border: 'none',
              }}
              startAdornment={
                <FilterAltIcon style={{ marginRight: '5px' }} /> // Replace with your desired icon
              }
              IconComponent={() => (
                <Label
                  variant={(filters.status === 'all' && 'filled') || 'soft'}
                  color={getColorForStatus(filters.status)}
                  style={{ marginRight: '20px' }}
                >
                  {filters.status === 'all'
                    ? tableData.length
                    : tableData.filter((order) => order.status === filters.status).length}
                </Label>
              )}
            >
              {STATUS_OPTIONS.map((tab, index) => (
                <MenuItem key={index} value={tab.value}>
                  {tab.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              marginBottom: 2,
              marginTop: 2,
              width: '60%',
              padding: 1,
            }}
          >
            <FormControl fullWidth>
              <InputLabel>Filter By</InputLabel>
              <Select
                value={selectedFilterKey}
                label="Filter By"
                onChange={(e) => {
                  setSelectedFilterKey(e.target.value);
                  setSelectedFilterValue('');
                }}
                startAdornment={<FilterAltIcon style={{ marginRight: '5px' }} />}
              >
                {filterKeys.map((filter) => (
                  <MenuItem key={filter.key} value={filter.key}>
                    {filter.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel>Value</InputLabel>
              <Select
                value={selectedFilterValue}
                label="Value"
                onChange={(e) => {
                  setSelectedFilterValue(e.target.value);
                  handleFiltersDynamic(selectedFilterKey, e.target.value);
                }}
                disabled={!selectedFilterKey} // Disable until a filter key is selected
              >
                {filterKeys
                  .find((filter) => filter.key === selectedFilterKey)
                  ?.options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>

          <Tabs
            value={filters.status || OTHER_STATUS_OPTIONS_FOR_ORDER[0]?.value}
            onChange={handleAdditionalFilterChange}
            style={{ marginLeft: '18px', paddingBottom: '10px' }}
            sx={{
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {OTHER_STATUS_OPTIONS_FOR_ORDER.map((tab, index) => (
              <Tab key={index} iconPosition="end" value={tab.value} label={tab.label} />
            ))}
          </Tabs>

          {/* <Tabs
            value={filters.timeSlot}
            onChange={handleAdditionalFilterChangeTimeSlot}
            style={{ marginLeft: '18px', paddingBottom: '10px' }}
            sx={{
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TIME_SLOT_FOR_ORDER.map((tab, index) => (
              <Tab
                key={index}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.timeSlot) && 'filled') ||
                      'soft'
                    }
                    color={getColorForStatus(tab.value)}
                  >
                    {tab.value === 'all'
                      ? tableData.length
                      : tableData.filter((order) => order.timeSlot === tab.value).length}
                  </Label>
                }
              />
            ))}
          </Tabs> */}

          {/* date filtering  */}
          <Box sx={{ padding: '40px 10px 10px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={filters.startDate ? parseISO(filters.startDate) : null}
                onChange={(newValue) => handleDateChange('startDate', newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                sx={{ marginLeft: '1%' }}
                value={filters.endDate ? parseISO(filters.endDate) : null}
                onChange={(newValue) => handleDateChange('endDate', newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ padding: '10px 10px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Delivery Date"
                value={filters.startDate ? parseISO(filters.order_date) : null}
                onChange={(newValue) => handleDateChange('order_date', newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </Box>

          <Box sx={{ padding: '10px 10px', display: 'flex', alignItems: 'center', gap: '20px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={startDate ? parseISO(startDate) : null}
                onChange={(newValue) => handleDateChangeExcel('startDate', newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <DatePicker
                label="End Date"
                sx={{ marginLeft: '1%' }}
                value={endDate ? parseISO(endDate) : null}
                onChange={(newValue) => handleDateChangeExcel('endDate', newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
              <Button variant="contained" color="success" onClick={downloadExcelReport}>
                Download Report
              </Button>
            </LocalizationProvider>
          </Box>

          <Box sx={{ padding: '10px 10px' }}>
            <Autocomplete
              sx={{ width: '40%' }}
              options={palletOptions}
              getOptionLabel={(option) => option.tracking || ''}
              value={pallet}
              onChange={(event, newValue) => {
                setPallet(newValue);
                handleFilters('pallet_id', newValue ? newValue.id : null);
              }}
              onInputChange={handlePalletInputChange}
              loading={loadingPallet}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Pallet Tracking"
                  variant="filled"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingPallet ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
          {/* date filtering  */}

          <UserTableToolbar
            filters={filters}
            onFilters={handleFilters}
            //
            // roleOptions={_roles}
            dataFiltered={dataFiltered}
          />

          <UserTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            onResetFilters={handleResetFilters}
            //
            results={tableDetails.total}
            sx={{ p: 2.5, pt: 0 }}
          />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                )
              }
              action={
                <Tooltip title="Delete">
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={table.selected.length}
                  onSort={table.onSort}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />

                <TableBody>
                  {dataFiltered
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row) => (
                      <UserTableRow
                        key={row.id}
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => table.onSelectRow(row.id)}
                        onDeleteRow={(e) => handleDeleteRow(e, row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        handleDrawerToggle={handleDrawerToggle}
                        setARow={setARow}
                        tableNames={tableNames}
                      />
                    ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
          {/* 
          <TablePaginationCustom
            count={dataFiltered.length}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          /> */}

          <TablePaginationCustom
            count={tableDetails.total ? tableDetails.total : 0} // total items count
            page={tableDetails.page ? tableDetails.page - 1 : 0} // current page, subtract 1 if your pagination starts from 1
            rowsPerPage={tableDetails.take ? tableDetails.take : 0} // items per page
            onPageChange={(event, newPage) => {
              setFilters((prevState) => ({
                ...prevState,
                page: newPage + 1,
              }));
            }}
            onRowsPerPageChange={(event) => {
              setFilters((prevState) => ({
                ...prevState,
                take: parseInt(event.target.value, 10),
                page: 1,
              }));
            }}
            //
            dense={table.dense}
            onChangeDense={table.onChangeDense}
          />
        </Card>
      </Container>
      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </div>
  );
}

TcTable.propTypes = {
  STATUS_OPTIONS: PropTypes.array,
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  OTHER_STATUS_OPTIONS_FOR_ORDER: PropTypes.array,
  TIME_SLOT_FOR_ORDER: PropTypes.array,
};
