// @mui
import { Box, Container, Typography } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

//
import '../../styles/dashboard.scss';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// _mock
// hooks
// components
import { useSettingsContext } from 'src/components/settings';
//
import { apiService } from 'src/utils/apiServices';
import AlertReturnFunction from 'src/custom_components/Alert/AlertRetunrFunction';
import OrderDrawerComponent from '../order/order-drawer-component';

export default function TcTable({
  TABLE_HEAD,
  defaultFilters,
  tableNames,
  kanbanInput,
  STATUS_ORDER,
}) {
  const [alertData, setAlertData] = useState(null);
  const settings = useSettingsContext();
  const [customOrders, setCustomOrders] = useState([]);
  const [aRow, setARow] = useState({});

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const closeAlert = () => {
    setAlertData(null);
  };

  const getCustomOrders = async () => {
    try {
      const response = await apiService.get(`orders/customTracking/customs`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setCustomOrders(data);
      return data;
    } catch (error) {
      setCustomOrders([]);
      return undefined;
    }
  };
  useEffect(() => {
    getCustomOrders();
  }, []);

  return (
    <div>
      <Container maxWidth={settings.themeStretch ? false : 'lg'}>
        {alertData && (
          <AlertReturnFunction onClose={closeAlert} type={alertData.type} text={alertData.text} />
        )}
        {Object.keys(aRow).length > 0 && (
          <OrderDrawerComponent
            isDrawerOpen={isDrawerOpen}
            handleDrawerToggle={handleDrawerToggle}
            aRow={aRow}
            setARow={setARow}
          />
        )}
        <CustomBreadcrumbs heading="Custom Orders" sx={{ padding: '0px 0.5%' }} />

        <div className="select_containers" style={{ gap: '10px' }}>
          {STATUS_ORDER.map((item, ind) => (
            <div
              className="child_kanban aligh_row_items"
              key={ind}
              style={
                settings.themeMode === 'light'
                  ? { backgroundColor: '#f4f6f8', padding: '5px' }
                  : { backgroundColor: '#2D353D', padding: '5px' }
              }
            >
              <Box
                sx={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '10px 0px',
                  padding: '10px',
                }}
              >
                <Typography variant="h6" style={{ color: '#2aa76f', fontSize: '17px' }}>
                  {item.label} - {customOrders?.[item.value]?.length}
                </Typography>
              </Box>

              <Box
                style={{
                  backgroundColor: settings.themeMode === 'light' ? '#f4f5f8' : '#2d353d',
                  height: '70vh',
                  overflowX: 'hidden',
                }}
              >
                {customOrders?.[item.value]?.map((i, index) => (
                  <Box
                    className="box_items_slot"
                    key={i.id}
                    style={{
                      alignItems: 'center',
                      backgroundColor: settings.themeMode === 'light' ? '#ffffff' : '#161C24',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          gap: '5px',
                        }}
                      >
                        <div className="light_text" style={{ fontSize: '0.8rem' }}>
                          {i?.tracking_number}
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '5px',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <div className="light_text" style={{ fontSize: '0.8rem' }}>
                          {i?.room_number}
                        </div>
                        <div className="light_text" style={{ fontSize: '0.8rem' }}>
                          * {i?.nusxa}
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
              </Box>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
}

TcTable.propTypes = {
  TABLE_HEAD: PropTypes.array,
  defaultFilters: PropTypes.object,
  tableNames: PropTypes.array,
  kanbanInput: PropTypes.array,
  STATUS_ORDER: PropTypes.array,
};
