import { Button, Drawer, IconButton, MenuItem, Typography, Select } from '@mui/material';
import OrderList from 'src/custom_components/orderList/OrderList';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

import ZebraPrintComponentBarCodes from 'src/custom_components/zebraPrintBarcode/ZebraPrintBarcode';
// import { useCountryData } from 'src/context/providers/CountryContext';
import { useBoxActions } from 'src/context/providers/BoxContext';
import InputFieds from '../../custom_components/Froms/InputFields';
import { status, personalInformation, status_colors } from './view/data';

const CustomerDrawerComponentbox = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [boxData, setboxData] = useState({
    tracking: aRow.tracking || '',
    status: aRow.status || '',
  });

  const { updateBox } = useBoxActions();

  const Clear = () => {
    setboxData({});
  };

  const handleUpdate = () => {
    const boxUPDAta = {
      id: aRow.id,
      name: boxData.name,
      tracking: boxData.tracking || '',
      status: boxData.status,
    };
    updateBox(aRow.id, boxUPDAta);
    handleDrawerToggle();
    Clear();
    setARow({});
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: `Edit box - ${aRow.id}`,
    buttons: {
      status: true,
      elements: [{ name: 'Update', onClick: handleUpdate, disabled: false }],
    },
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setboxData({
      ...boxData,
      [name]: value,
    });
  };
  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
      setARow({});
    }
  };
  const CloseTab = () => {
    handleDrawerToggle();
    Clear();
    setARow({});
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          Clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={CloseTab}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        <div>
          {/* Header */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
              borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
            }}
          >
            <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
            <div
              style={{
                width: '40%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {Object.keys(boxData)?.map((key) => {
                if (key === 'status') {
                  return (
                    <Select
                      key={key}
                      name={key}
                      value={boxData[key]}
                      onChange={handleInputChange}
                      style={{
                        backgroundColor: status_colors[boxData.status][0],
                        color: status_colors[boxData.status][1],
                      }}
                      variant="outlined"
                      sx={{ width: '70%', height: '40px' }}
                    >
                      {status.map((i, index) => (
                        <MenuItem key={index} value={i}>
                          {i}
                        </MenuItem>
                      ))}
                    </Select>
                  );
                }
                return null;
              })}

              <IconButton onClick={CloseTab}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {/* Form */}

          <div
            className="content-wrap"
            style={{
              gap: '2px',
              padding: '30px 0px 0px 0px',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              minHeight: '80vh',
            }}
          >
            {/* Form */}
            {/* attached orders */}
            <OrderList aRow={aRow} />
            {/* attached orders */}

            {/* Personal Information */}
            <InputFieds
              userData={boxData}
              heading="Box Information"
              inputFields={personalInformation}
              setUserData={setboxData}
            />

            {/* Personal Information */}
            <div
              className="content-wrap"
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                gap: '2px',
                padding: '30px 0px 0px 20px',
                position: 'relative',
              }}
            >
              <ZebraPrintComponentBarCodes tracking={aRow.tracking} />
            </div>
          </div>
        </div>

        <div>
          <Button
            variant="contained"
            style={{
              marginLeft: '24px',
              width: '136px',
              float: 'right',
            }}
            onClick={handleUpdate}
          >
            update
          </Button>
        </div>
      </Drawer>
    </div>
  );
};

CustomerDrawerComponentbox.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponentbox;
