// utils
// import { _id, _postTitles } from 'src/_mock/assets';

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  page403: '/403',
  page404: '/404',
  page500: '/500',

  // AUTH
  auth: {
    amplify: {
      login: `${ROOTS.AUTH}/amplify/login`,
      verify: `${ROOTS.AUTH}/amplify/verify`,
      register: `${ROOTS.AUTH}/amplify/register`,
      newPassword: `${ROOTS.AUTH}/amplify/new-password`,
      forgotPassword: `${ROOTS.AUTH}/amplify/forgot-password`,
    },
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
    firebase: {
      login: `${ROOTS.AUTH}/firebase/login`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      register: `${ROOTS.AUTH}/firebase/register`,
      forgotPassword: `${ROOTS.AUTH}/firebase/forgot-password`,
    },
    auth0: {
      login: `${ROOTS.AUTH}/auth0/login`,
    },
  },
  authDemo: {
    classic: {
      login: `${ROOTS.AUTH_DEMO}/classic/login`,
      register: `${ROOTS.AUTH_DEMO}/classic/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/classic/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/classic/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/classic/verify`,
    },
    modern: {
      login: `${ROOTS.AUTH_DEMO}/modern/login`,
      register: `${ROOTS.AUTH_DEMO}/modern/register`,
      forgotPassword: `${ROOTS.AUTH_DEMO}/modern/forgot-password`,
      newPassword: `${ROOTS.AUTH_DEMO}/modern/new-password`,
      verify: `${ROOTS.AUTH_DEMO}/modern/verify`,
    },
  },

  // DASHBOARD
  // dashboard: {
  //   root: `${ROOTS.DASHBOARD}/customer/list`,

  //   customer: {
  //     root: `${ROOTS.DASHBOARD}/customer/list`,
  //   },
  //   customerAddressDetails: {
  //     root: `${ROOTS.DASHBOARD}/customer-address-details/list`,
  //   },
  //   orders: {
  //     root: `${ROOTS.DASHBOARD}/orders/list`,
  //   },
  //   branch: {
  //     root: `${ROOTS.DASHBOARD}/branch/list`,
  //   },
  //   country: {
  //     root: `${ROOTS.DASHBOARD}/country/list`,
  //   },
  // },

  dashboard: {
    root: `${ROOTS.DASHBOARD}/customer`,

    customer: {
      root: `${ROOTS.DASHBOARD}/customer`,
    },
    customerAddressDetails: {
      root: `${ROOTS.DASHBOARD}/customer-address-details`,
    },
    orders: {
      root: `${ROOTS.DASHBOARD}/orders`,
    },
    branch: {
      root: `${ROOTS.DASHBOARD}/branch`,
    },
    country: {
      root: `${ROOTS.DASHBOARD}/country`,
    },
    kanban: {
      root: `${ROOTS.DASHBOARD}/kanban`,
    },
    box: {
      root: `${ROOTS.DASHBOARD}/box`,
    },
    pallete: {
      root: `${ROOTS.DASHBOARD}/pallet`,
    },
    shelf: {
      root: `${ROOTS.DASHBOARD}/shelf`,
    },
    warehouseLocation: {
      root: `${ROOTS.DASHBOARD}/warehouseLocation`,
    },
    TimeSlot: {
      root: `${ROOTS.DASHBOARD}/TimeSlot`,
    },
    OrderCheck: {
      root: `${ROOTS.DASHBOARD}/OrderCheck`,
    },
    orderStatus: {
      root: `${ROOTS.DASHBOARD}/OrderStatus`,
    },
    content: {
      root: `${ROOTS.DASHBOARD}/ContentManagement`,
    },
    airwayBill: {
      root: `${ROOTS.DASHBOARD}/airwayBill`,
    },

    address: {
      root: `${ROOTS.DASHBOARD}/address`,
    },
    rs: {
      root: `${ROOTS.DASHBOARD}/rs`,
    },
    price: {
      root: `${ROOTS.DASHBOARD}/price`,
    },
    payment: {
      root: `${ROOTS.DASHBOARD}/payments`,
    },
    liveOrders: {
      root: `${ROOTS.DASHBOARD}/liveorders`,
    },
    customOrders:{
      root: `${ROOTS.DASHBOARD}/customOrders`,
    },
  },
};
