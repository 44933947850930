import { Button, Drawer, IconButton, MenuItem, Typography, Select } from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import InputFields from 'src/custom_components/Froms/InputFields';
import { useState } from 'react';
import { useCountryActions } from 'src/context/providers/CountryContext';
import { status, status_colors, personalInformation } from './view/data';

const CustomerDrawerComponentCountry = ({ isDrawerOpen, handleDrawerToggle, aRow, setARow }) => {
  const [countryData, setCountryData] = useState({
    name: aRow.name,
    currency: aRow.currency,
    price: aRow.price,
    status: aRow.status,
  });
  const { updateCountry } = useCountryActions();

  const Clear = () => {
    setCountryData({});
  };

  const handleUpdate = async () => {
    const updateCountryData = {
      name: countryData.name,
      currency: Array.isArray(countryData.currency) ? countryData.currency : [countryData.currency],
      price: countryData.price,
      status: countryData.status,
    };
    await updateCountry(aRow.id, updateCountryData);
    handleDrawerToggle();
    setARow({});
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: `Edit Country - #${aRow.id}`,
    buttons: {
      status: true,
      elements: [{ name: 'Update', onClick: handleUpdate, disabled: false }],
    },
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
      setARow({});
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCountryData({
      ...countryData,
      [name]: value,
    });
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
          Clear();
          setARow({});
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              width: '30%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {Object.keys(countryData).map((key) => {
              if (key === 'status') {
                return (
                  <Select
                    key={key}
                    name={key}
                    value={countryData[key]}
                    onChange={handleInputChange}
                    style={{
                      backgroundColor: status_colors[countryData.status][0],
                      color: status_colors[countryData.status][1],
                    }}
                    variant="outlined"
                    sx={{ width: '70%', height: '40px' }}
                  >
                    {status.map((i, index) => (
                      <MenuItem key={index} value={i}>
                        {i}
                      </MenuItem>
                    ))}
                  </Select>
                );
              }
              return null;
            })}

            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Form */}

        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Form */}
          {/* Personal Information */}
          <InputFields
            userData={countryData}
            heading="Country Information"
            inputFields={personalInformation}
            setUserData={setCountryData}
          />
          {/* Personal Information */}
        </div>
        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid rgba(220, 220, 220, 0.2)',
              padding: '16px',
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
            }}
          >
            {DrawerConfig.buttons.elements.map((element) => (
              <Button
                variant="contained"
                style={{
                  marginLeft: '24px',
                  width: '136px',
                }}
                disabled={element.disabled}
                onClick={element.onClick}
              >
                {element.name}
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CustomerDrawerComponentCountry.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  aRow: PropTypes.object.isRequired,
  setARow: PropTypes.func.isRequired,
};

export default CustomerDrawerComponentCountry;
