const TABLE_HEAD = [
  { id: 'tracking', label: 'Tracking', width: 380 },
  { id: 'airwayBill', label: 'AirwayBill', width: 100 },
  { id: 'totalWeight', label: 'totalWeight', width: 100 },
  { id: 'status', label: 'Status', width: 100 },
  { id: '', width: 100 },
];

const defaultFilters = {
  name: '',
  role: [],
};
const tableNames = [
  { id: 'tracking', label: 'Tracking' },
  { id: 'airwayBill', label: 'airwayBill' },
  { id: 'totalWeight', label: 'totalWeight' },
  { id: 'status', label: 'Status' },
];
const personalInformation = [
  {
    name: 'tracking',
    width: '97%',
    label: 'Tracking',
  },
];

const status_colors = {
  CREATED: ['#22C55E29', '#118D57'],
  SHIPPED: ['#919EAB29', '#637381'],
  DELIVERED: ['#FFAB0029', '#B76E00'],
  CANCELLED: ['#FF563029', '#B71D18'],
};

const status = ['CREATED', 'SHIPPED', 'DELIVERED', 'CANCELLED'];

export { TABLE_HEAD, defaultFilters, tableNames, status, personalInformation, status_colors };
