import { Helmet } from 'react-helmet-async';
import KanbanListView from 'src/sections/kanban/view/kanban-list-view';

export default function KanbanListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: kanban</title>
      </Helmet>
      <KanbanListView />
    </>
  );
}
