import { useEffect, useState } from 'react';
import {
  Drawer,
  IconButton,
  Typography,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from '@mui/material';
import PropTypes from 'prop-types';
import SearchIcon from '@mui/icons-material/Search';
import CustomizedSwitches from 'src/custom_components/Froms/InputSwitcher';
import CloseIcon from '@mui/icons-material/Close';
import { useOrderActions } from 'src/context/providers/OrderContext';
import SelectFieldForOrder from 'src/custom_components/Froms/selectForOrder';
import InputFields from 'src/custom_components/Froms/InputFields';
import { useCustomerActions } from 'src/context/providers/CustomerContext';

import { jwtDecode } from 'jwt-decode';
import DatePickerValue from 'src/custom_components/Froms/datePicker';
import { toast } from 'react-toastify';

import {
  additionalInformation,
  declaration,
  orderDetails,
  status,
  status_colors,
  time_slot_select,
  trackingInformation,
} from './view/data';

const OrderCreation = ({ isDrawerOpen, handleDrawerToggle }) => {
  const { createOrderWithSteps } = useOrderActions();
  const [searchValue, setSeachValue] = useState('');
  const { updateOrder } = useOrderActions();
  const { getCustomerById } = useCustomerActions();
  const [customer, setCustomer] = useState([]);
  const [originalData, setOriginalData] = useState({});
  const [formData, setFormData] = useState({
    tracking_number: null,
    tracking_number_2: null,
    room_number: null,
    content: null,
    additional_identifier: null,
    price: null,
    status: 'RECEIVED IN US WAREHOUSE',
    additional_details_id: null,
    customer_id: null,
    branch_id: null,
    box_id: null,
    pallet_id: null,
    shelf_id: null,
    timeSlot: null,
    order_date: '',
    wrapping: null,
    width: null,
    height: null,
    len: null,
    volume: null,
    weight: null,
    width_before: null,
    height_before: null,
    len_before: null,
    volume_before: null,
    weight_before: null,
    declaration_status: null,
    tracking_status: null,
    custom_status: null,
    delivery_status: null,
    pay_status: null,
    vatPaymentAuto: null,
    DeclarationPaymentAuto: null,
    NeedsCustom: null,
    vat: null,
    vat_payment_status: null,
    declaration_id_for_vat: null,
    vat_amount: null,
    comment: '',
  });

  // images

  const { getOrderByTracking } = useOrderActions();

  const DrawerConfig = {
    SubNavigation: false,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: 'Create Order',
    SubNav: true,
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setCustomer([]);
    getOrderByTracking(searchValue)
      .then((data) => {
        if (data.room_number === null) {
          data.room_number = data?.customer?.room_number;
        }
        if (data.customer) {
          setCustomer(data?.customer);
        }
        setFormData(data);
        setOriginalData(data);
      })
      .catch((error) => {
        setFormData({
          tracking_number: searchValue,
          status: 'RECEIVED IN US WAREHOUSE',
        });
        setOriginalData({
          tracking_number: null,
        });
      });
    setSeachValue('');
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
      Clear();
    }
  };
  const Clear = () => {
    setFormData({
      tracking_number: null,
      tracking_number_2: null,
      room_number: null,
      weight: null,
      content: null,
      additional_identifier: null,
      price: null,
      status: 'RECEIVED IN US WAREHOUSE',
      additional_details_id: null,
      customer_id: null,
      branch_id: null,
      box_id: null,
      pallet_id: null,
      shelf_id: null,
      order_date: '',
      timeSlot: null,
      wrapping: null,
      width: null,
      height: null,
      len: null,
      volume: null,
      width_before: null,
      height_before: null,
      len_before: null,
      volume_before: null,
      weight_before: null,
      declaration_status: null,
      tracking_status: null,
      custom_status: null,
      delivery_status: null,
      pay_status: null,
      vatPaymentAuto: null,
      DeclarationPaymentAuto: null,
      NeedsCustom: null,
      vat: null,
      vat_payment_status: null,
      declaration_id_for_vat: null,
      vat_amount: null,
      comment: '',
    });
    setCustomer([]);
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const auth = JSON.parse(localStorage.getItem('token'));
  let decoded = '';
  if (auth) {
    decoded = jwtDecode(auth);
  }
  // useMemo(() => {
  //   if (!formData.volume && formData.width && formData.len && formData.height) {
  //     const newVolu = (formData.width * formData.len * formData.height) / 6000;
  //     setFormData({
  //       ...formData,
  //       volume: newVolu,
  //     });
  //   }
  //   if (
  //     !formData.volume_before &&
  //     formData.width_before &&
  //     formData.len_before &&
  //     formData.height_before
  //   ) {
  //     const newVolume_before =
  //       (formData.width_before * formData.len_before * formData.height_before) / 6000;
  //     setFormData({
  //       ...formData,
  //       volume_before: newVolume_before,
  //     });
  //   }
  // }, [formData]);

  useEffect(() => {
    if (formData.room_number && formData.room_number.length >= 6) {
      getCustomerById(formData.room_number)
        .then((customerForRoom) => {
          if (customerForRoom && !formData.id) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              wrapping: customerForRoom.repackaging,
            }));

            setCustomer(customerForRoom);
          }
        })
        .catch((error) => {
          console.error('Error fetching customer by room number:', error);
        });
    }
    // eslint-disable-next-line
  }, [formData.room_number]);

  useEffect(() => {
    const width = Number(formData.width);
    const height = Number(formData.height);
    const len = Number(formData.len);

    if (
      Number.isFinite(width) &&
      Number.isFinite(height) &&
      Number.isFinite(len) &&
      width > 0 &&
      height > 0 &&
      len > 0
    ) {
      const newVolume = (width * len * height) / 6000;
      setFormData((prevFormData) => ({
        ...prevFormData,
        volume: newVolume.toFixed(2),
      }));
    }
  }, [formData.width, formData.height, formData.len]);

  useEffect(() => {
    const widthBefore = Number(formData.width_before);
    const heightBefore = Number(formData.height_before);
    const lenBefore = Number(formData.len_before);

    if (
      Number.isFinite(widthBefore) &&
      Number.isFinite(heightBefore) &&
      Number.isFinite(lenBefore) &&
      widthBefore > 0 &&
      heightBefore > 0 &&
      lenBefore > 0
    ) {
      const newVolumeBefore = (widthBefore * lenBefore * heightBefore) / 6000;
      setFormData((prevFormData) => ({
        ...prevFormData,
        volume_before: newVolumeBefore.toFixed(2),
      }));
    }
  }, [formData.width_before, formData.height_before, formData.len_before]);
  const searchField = (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '20px',
        justifyContent: 'flex-start',
        padding: '40px 40px 10px',
      }}
    >
      <TextField
        label="tracking"
        variant="filled"
        style={{ width: '40%' }}
        value={searchValue}
        onChange={(event) => {
          setSeachValue(event.target.value);
        }}
      />

      <Button
        variant="contained"
        style={{ padding: '10px 20px' }}
        onClick={(e) => handleSubmitSearch(e)}
        endIcon={<SearchIcon />}
      >
        Search
      </Button>
    </div>
  );

  const identifyChanges = () => {
    const changes = {};
    Object.entries(formData).forEach(([key, value]) => {
      // Compare current form data with original data
      if (originalData[key] !== value) {
        changes[key] = value;
      }
    });
    return changes;
  };
  const UpdateFields = () => {
    const changes = identifyChanges();
    // If there are no changes, log a message and exit the function
    if (Object.keys(changes).length === 0) {
      return;
    }

    // Proceed with the update using only the changed fields
    updateOrder(formData?.id, changes)
      .then(() => {
        handleDrawerToggle();
        Clear();
        setFormData({});
      })
      .catch((error) => {
        console.error('Update failed:', error);
      });
  };
  const createOrder = () => {
    if (!formData.room_number) {
      toast.error('Please enter a room number');
    } else {
      createOrderWithSteps(formData);
      Clear();
      handleDrawerToggle();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}
        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Subnavigation */}

        <div
          className="content-wrap"
          style={{
            width: '100%',
            minHeight: '87.5%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {searchField}

          {/* Form */}
          <form style={{ padding: '0px 16px' }}>
            {/* Tracking Information */}
            <InputFields
              userData={formData}
              heading="Tracking Information"
              inputFields={trackingInformation}
              setUserData={setFormData}
              hasCopy
            />
            <div
              className="content-wrap"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                padding: '10px 0px 0px 20px',
              }}
            >
              <Typography gutterBottom className="subTitles" sx={{ color: '#41B588' }}>
                Customer - {customer?.first_name} {customer?.last_name}
              </Typography>
            </div>

            {/* Tracking Information */}
            {/* Order details */}
            <div style={{ display: 'flex', padding: '20px 0px 0px 20px' }}>
              <FormControl variant="outlined" sx={{ width: '95%', height: '40px' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  onChange={handleInputChange}
                  label="Status" // This ensures the label shrinks correctly when the Select is focused or filled
                  style={{
                    backgroundColor: status_colors[formData.status]
                      ? status_colors[formData.status][0]
                      : '',
                    color: status_colors[formData.status] ? status_colors[formData.status][1] : '',
                  }}
                >
                  {status.map((i, index) => (
                    <MenuItem key={index} value={i}>
                      {i?.replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* Wrapping */}
            <div
              className="content-wrap"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                padding: '30px 0px 0px 20px',
              }}
            >
              <Typography gutterBottom className="subTitles">
                Wrapping
              </Typography>
              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                <CustomizedSwitches
                  text="wrapping"
                  isChecked={formData.wrapping}
                  userData={formData}
                  setUserData={setFormData}
                />
              </div>
            </div>
            {/* Wrapping */}
            <InputFields
              userData={formData}
              heading="Order Details"
              inputFields={orderDetails}
              setUserData={setFormData}
            />
            {/* Order details */}

            {/* Box and pallets */}
            {/* <div
              className="content-wrap"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '30px 0px 0px 20px',
              }}
            >
              <Typography gutterBottom className="subTitles">
                Box and pallets
              </Typography>
              <SelectFieldForOrder
                key="box"
                widthComp="95%"
                selectName="box_id"
                setter={handleInputChange}
                data={boxes}
                selectedValue={formData.box_id}
                optionName="tracking"
              />
              <SelectFieldForOrder
                key="pallete"
                widthComp="95%"
                selectName="pallet_id"
                data={palletes}
                setter={handleInputChange}
                selectedValue={formData.pallet_id}
                optionName="tracking"
              />
              {decoded?.location === 'Georgia' ? (
                <SelectFieldForOrder
                  key="shelf"
                  widthComp="95%"
                  selectName="shelf_id"
                  data={shelfs}
                  setter={handleInputChange}
                  selectedValue={formData.shelf_id}
                  optionName="code"
                />
              ) : (
                ''
              )}
            </div> */}
            {/* box and pallets */}

            {/* Order Steps */}
            <div
              className="content-wrap"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '30px 0px 0px 20px',
              }}
            >
              <Typography gutterBottom className="subTitles">
                Order Steps
              </Typography>

              {decoded?.location === 'Georgia' ? (
                <>
                  <SelectFieldForOrder
                    key="declaration_status"
                    widthComp="95%"
                    selectName="declaration_status"
                    data={[
                      { id: 'DECLERED', name: 'DECLERED' },
                      { id: 'NOT DECLERED', name: 'NOT DECLERED' },
                    ]}
                    setter={handleInputChange}
                    selectedValue={formData.declaration_status}
                    optionName="name"
                  />
                  <SelectFieldForOrder
                    key="tracking_status"
                    widthComp="95%"
                    selectName="tracking_status"
                    data={[
                      { id: 'TRACKING', name: 'TRACKING' },
                      { id: 'NOT TRACKING', name: 'NOT TRACKING' },
                    ]}
                    setter={handleInputChange}
                    selectedValue={formData.tracking_status}
                    optionName="name"
                  />

                  <SelectFieldForOrder
                    key="custom_status"
                    widthComp="95%"
                    selectName="custom_status"
                    data={[
                      { id: '0', name: '0' },
                      { id: '1', name: '1' },
                      { id: '2', name: '2' },
                      { id: '3', name: '3' },
                      { id: '4', name: '4' },
                      { id: '5', name: '5' },
                      { id: '6', name: '6' },
                      { id: '7', name: '7' },
                    ]}
                    setter={handleInputChange}
                    selectedValue={formData.custom_status}
                    optionName="name"
                  />

                  <SelectFieldForOrder
                    key="delivery_status"
                    widthComp="95%"
                    selectName="delivery_status"
                    data={[
                      { id: 'DELIVERED', name: 'DELIVERED' },
                      { id: 'NOT DELIVERED', name: 'NOT DELIVERED' },
                    ]}
                    setter={handleInputChange}
                    selectedValue={formData.delivery_status}
                    optionName="name"
                  />

                  <SelectFieldForOrder
                    key="pay_status"
                    widthComp="95%"
                    selectName="pay_status"
                    data={[
                      { id: 'PAYED', name: 'PAYED' },
                      { id: 'NOT PAYED', name: 'NOT PAYED' },
                    ]}
                    setter={handleInputChange}
                    selectedValue={formData.pay_status}
                    optionName="name"
                  />
                  {/* Order declaration */}
                  <InputFields
                    userData={formData}
                    heading=""
                    inputFields={declaration}
                    setUserData={setFormData}
                    noPadding
                  />
                  {/* Order declaration */}
                </>
              ) : (
                ''
              )}
            </div>

            {/* Order Steps */}

            {/* declarations */}

            <div
              className="content-wrap"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '2px',
                padding: '30px 0px 0px 20px',
              }}
            >
              <Typography gutterBottom className="subTitles">
                Declaration
              </Typography>
              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '10px',
                }}
              >
                <CustomizedSwitches
                  text="vatPaymentAuto"
                  isChecked={formData.vatPaymentAuto}
                  userData={formData}
                  setUserData={setFormData}
                />
                <CustomizedSwitches
                  text="DeclarationPaymentAuto"
                  isChecked={formData.DeclarationPaymentAuto}
                  userData={formData}
                  setUserData={setFormData}
                />
                <CustomizedSwitches
                  text="NeedsCustom"
                  isChecked={formData.NeedsCustom}
                  userData={formData}
                  setUserData={setFormData}
                />
                <CustomizedSwitches
                  text="vat"
                  isChecked={formData.vat}
                  userData={formData}
                  setUserData={setFormData}
                />
                <CustomizedSwitches
                  text="vat_payment_status"
                  isChecked={formData.vat_payment_status}
                  userData={formData}
                  setUserData={setFormData}
                />
              </div>
            </div>
            {/* declarations */}

            {/* Delivery Time */}

            {decoded?.location === 'Georgia' ? (
              <div
                className="content-wrap"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px',
                  padding: '30px 0px 0px 20px',
                }}
              >
                <Typography gutterBottom className="subTitles">
                  Delivery time
                </Typography>

                <SelectFieldForOrder
                  key="timeSlot"
                  widthComp="95%"
                  selectName="timeSlot"
                  data={time_slot_select}
                  setter={handleInputChange}
                  selectedValue={formData.timeSlot}
                  optionName="name"
                />

                <DatePickerValue
                  setter={handleInputChange}
                  value={new Date(formData.order_date)}
                  selectName="order_date"
                />
              </div>
            ) : (
              ''
            )}

            {/* Delivery Time */}

            {/* Additional Infomation */}

            <InputFields
              userData={formData}
              heading="Additional Infomation"
              inputFields={additionalInformation}
              setUserData={setFormData}
            />
            {/* Additional Infomation */}
            {/* Button */}

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                borderTop: '1px solid rgba(220, 220, 220, 0.2)',
                padding: '16px',
                marginRight: '10px',
              }}
            >
              {!formData?.id ? (
                <Button variant="contained" onClick={createOrder}>
                  CREATE
                </Button>
              ) : (
                <Button variant="contained" onClick={UpdateFields}>
                  UPDATE
                </Button>
              )}
            </div>
          </form>
        </div>
      </Drawer>
    </div>
  );
};

OrderCreation.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default OrderCreation;
