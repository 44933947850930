import { Helmet } from 'react-helmet-async';
import { AirwayBill } from 'src/sections/airwayBill/view';

export default function AirwayBillListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: AirwayBill</title>
      </Helmet>

      <AirwayBill />
    </>
  );
}
