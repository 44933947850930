import { Helmet } from 'react-helmet-async';
import PriceListView from 'src/sections/price/view/price-list-view';

export default function PriceListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Price</title>
      </Helmet>

      <PriceListView />
    </>
  );
}
