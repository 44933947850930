// ShelfContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const ShelfContext = createContext();
const ShelfActionsContext = createContext();
const ShelfDetailsContext = createContext();

export const ShelfProvider = ({ children }) => {
  const [shelves, setShelves] = useState([]);
  const [selectedShelf, setSelectedShelf] = useState(null);
  const [filteredShelves, setFilteredShelves] = useState({
    shelves: [],
    total: 0,
    page: 1,
    take: 25,
  });

  const createShelf = async (newShelf) => {
    try {
      const response = await apiService.post('/shelf', newShelf, {
        headers: {
          timeout: 10000,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // eslint-disable-next-line
      const data = await response.data;
      getShelves();
      getFilteredShelves();
      toast.success('Shelf created!');
    } catch (error) {
      toast.error('Error creating Shelf');
      console.error('Error creating Shelf:', error);
    }
  };

  const deleteShelf = async (shelfId) => {
    try {
      await apiService.put(
        '/shelf/update/soft',
        { ids: shelfId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setShelves((prevShelves) => prevShelves.filter((shelf) => !shelfId.includes(shelf.id)));
      toast.success('Shelf deleted!');
    } catch (error) {
      toast.error('Error deleting Shelf');
      console.error('Error deleting Shelf:', error);
    }
  };

  const updateShelf = async (shelfId, updatedShelf) => {
    try {
      const response = await apiService.put(`/shelf/${shelfId}`, updatedShelf, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setShelves((prevShelves) =>
        prevShelves.map((shelf) => (shelf.id === shelfId ? { ...shelf, ...data } : shelf))
      );
      setFilteredShelves((prevState) => ({
        ...prevState,
        shelves: prevState.shelves.map((shelf) =>
          shelf.id === shelfId ? { ...shelf, ...data } : shelf
        ),
      }));
      toast.success('Shelf updated!');
    } catch (error) {
      toast.error('Error updating Shelf');
      console.error('Error updating Shelf:', error);
    }
  };

  const getShelves = async () => {
    try {
      const response = await apiService.get('/shelf', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setShelves(data);
    } catch (error) {
      console.error('Error fetching Shelves:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
    }
  };

  const getFilteredShelves = async (filters = {}) => {
    try {
      const effectiveFilters = Object.entries(filters).reduce((acc, [key, value]) => {
        if (key === 'role' && Array.isArray(value) && value.length === 0) {
          return acc; // skip adding to the accumulator
        }
        acc[key] = value; // otherwise add to the accumulator
        return acc;
      }, {});

      const query = new URLSearchParams(effectiveFilters).toString();
      // Simulate API call to get customers
      const response = await apiService.get(`shelf/filteredShelf?${query}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setFilteredShelves(data);
    } catch (error) {
      console.error('Error fetching customers:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
    }
  };

  const getShelfById = async (shelfId) => {
    try {
      const response = await apiService.get(`/shelf/${shelfId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setSelectedShelf(data);
      return data;
    } catch (error) {
      console.error('Error fetching Shelf by ID:', error);
      throw error;
    }
  };

  const findShelfByOrder = async (tracking) => {
    try {
      const response = await apiService.get(`/shelf/getSuggestShelf/${tracking}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      toast.info(data, {
        autoClose: 10000,
      });

      return data;
    } catch (error) {
      console.error('Error fetching suggested shelf:', error);
      throw error;
    }
  };

  useEffect(() => {
    getShelves();
  }, []);

  const createShelfCallback = useCallback(createShelf, []);
  const deleteShelfCallback = useCallback(deleteShelf, []);
  const updateShelfCallback = useCallback(updateShelf, []);
  const getShelvesCallback = useCallback(getShelves, []);
  const getFilteredShelvesCallback = useCallback(getFilteredShelves, []);
  const findShelfByOrderCallback = useCallback(findShelfByOrder, []);

  const ShelfActionsValue = useMemo(
    () => ({
      createShelf: createShelfCallback,
      deleteShelf: deleteShelfCallback,
      updateShelf: updateShelfCallback,
      getShelves: getShelvesCallback,
      getShelfById,
      getFilteredShelves: getFilteredShelvesCallback,
      findShelfByOrder: findShelfByOrderCallback,
    }),
    [
      createShelfCallback,
      deleteShelfCallback,
      updateShelfCallback,
      getShelvesCallback,
      findShelfByOrderCallback,
      getFilteredShelvesCallback,
    ]
  );

  const ShelvesValue = useMemo(
    () => ({
      shelves,
      filteredShelves,
    }),
    [shelves, filteredShelves]
  );
  return (
    <ShelfContext.Provider value={ShelvesValue}>
      <ShelfActionsContext.Provider value={ShelfActionsValue}>
        <ShelfDetailsContext.Provider value={selectedShelf}>
          {children}
        </ShelfDetailsContext.Provider>
      </ShelfActionsContext.Provider>
    </ShelfContext.Provider>
  );
};

export const useShelfData = () => useContext(ShelfContext);
export const useShelfActions = () => useContext(ShelfActionsContext);
export const useShelfDetails = () => useContext(ShelfDetailsContext);

ShelfProvider.propTypes = {
  children: PropTypes.element,
};
