import React from 'react';
import { Box, FormControl, TextField, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import '../../styles/dashboard.scss';

function SelectField({
  selectName,
  data = [],
  setter,
  widthComp = '100%',
  selectedValue = null,
  name1 = 'name',
  showOrders = true,
}) {
  const handleChange = (_, newValue) => {
    setter(newValue);
  };
  return (
    <Box sx={{ width: widthComp }}>
      <FormControl fullWidth variant="filled">
        <Autocomplete
          value={selectedValue}
          options={data}
          getOptionLabel={(option) => `${option[name1]}`}
          renderOption={(props, option) => (
            <li {...props} className="headings_select">
              <div>{option[name1]}</div>
              {showOrders ? <div className="number_of_item">{option?.orders?.length}</div> : ''}
            </li>
          )}
          onChange={handleChange}
          renderInput={(params) => (
            <TextField {...params} label={selectName} variant="filled" required />
          )}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
        />
      </FormControl>
    </Box>
  );
}

export default SelectField;

SelectField.propTypes = {
  selectName: PropTypes.string,
  data: PropTypes.array,
  setter: PropTypes.func,
  widthComp: PropTypes.string,
  selectedValue: PropTypes.object,
  name1: PropTypes.string,
  showOrders: PropTypes.bool,
};
