// BranchContext.js
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types'; // @mui
import { apiService } from 'src/utils/apiServices';
import { redirect } from 'react-router';
import { toast } from 'react-toastify';

const CountryContext = createContext();
const CountryActionsContext = createContext();

export const CountryProvider = ({ children }) => {
  const [countries, setCountries] = useState([]);

  const createCountry = async (newCountry) => {
    try {
      // Simulate API call to create country
      const response = await apiService.post('/countries', newCountry, {
        headers: {
          timeout: 10000,
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      const data = await response.data;
      setCountries((prevCountries) => [...prevCountries, data]);
      toast.success('country created!');
    } catch (error) {
      toast.error('Error creating country:');
      console.error('Error creating country', error);
    }
  };

  const deleteCountry = async (countryId) => {
    try {
      await apiService.put(
        '/countries/update/soft',
        { ids: countryId },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      setCountries((prevCountries) =>
        prevCountries.filter((country) => !countryId.includes(country.id))
      );
      toast.success('country deleted!');
    } catch (error) {
      toast.error('Error deleting country');
      console.error('Error deleting country:', error);
    }
  };

  const updateCountry = async (countryId, updatedCountry) => {
    try {
      // Simulate API call to update country
      const response = await apiService.put(`/countries/${countryId}`, updatedCountry, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setCountries((prevCountries) =>
        prevCountries.map((country) =>
          country.id === countryId ? { ...country, ...data } : country
        )
      );
      toast.success('country updated!');
    } catch (error) {
      toast.error('Error updating country');
      console.error('Error updating country:', error);
    }
  };

  const getCountries = async () => {
    try {
      // Simulate API call to get countries
      const response = await apiService.get(`/countries`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.data;
      setCountries(data);
    } catch (error) {
      console.error('Error fetching countries:', error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        redirect('/');
      }
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  const createCountryCallback = useCallback(createCountry, []);
  const deleteCountryCallback = useCallback(deleteCountry, []);
  const updateCountryCallback = useCallback(updateCountry, [updateCountry]);
  const getCountriesCallback = useCallback(getCountries, []);

  const countryActionsValue = useMemo(
    () => ({
      createCountry: createCountryCallback,
      deleteCountry: deleteCountryCallback,
      updateCountry: updateCountryCallback,
      getCountries: getCountriesCallback,
    }),
    [createCountryCallback, deleteCountryCallback, updateCountryCallback, getCountriesCallback]
  );

  const countriesValue = useMemo(() => countries, [countries]);

  return (
    <CountryContext.Provider value={countriesValue}>
      <CountryActionsContext.Provider value={countryActionsValue}>
        {children}
      </CountryActionsContext.Provider>
    </CountryContext.Provider>
  );
};

export const useCountryData = () => useContext(CountryContext);
export const useCountryActions = () => useContext(CountryActionsContext);

CountryProvider.propTypes = {
  children: PropTypes.element,
};
