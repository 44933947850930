import { Button, Drawer, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useAirwayBillActions } from 'src/context/providers/AirwayBillContext';
import CloseIcon from '@mui/icons-material/Close';
import DatePickerValue from 'src/custom_components/Froms/datePicker';
import { useState } from 'react';
import InputFieds from '../../custom_components/Froms/InputFields';
import { status, personalInformation } from './view/data';

const CreateAirwayBillDrawer = ({ isDrawerOpen, handleDrawerToggle }) => {
  const [airwayBillData, setairwayBillData] = useState({
    name: '',
    tracking: '',
    status: '',
    order_date: '',
  });

  const { createAirwayBill } = useAirwayBillActions();

  const Clear = () => {
    setairwayBillData({});
  };

  const handleCreateairwayBill = async () => {
    const airwayBillData1 = {
      name: airwayBillData.name,
      tracking: airwayBillData.tracking,
      status: airwayBillData.status,
      airway_date: airwayBillData.order_date,
    };
    await createAirwayBill(airwayBillData1);
    Clear();
    handleDrawerToggle();
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setairwayBillData({
      ...airwayBillData,
      [name]: value,
    });
  };

  const DrawerConfig = {
    SubNavigation: true,
    anchor: 'right',
    width: '40%',
    drawerHeaderText: 'Create airwayBill',
    buttons: {
      status: true,
      elements: [{ name: 'Create', onClick: handleCreateairwayBill }],
    },
  };

  const containerStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,
    display: isDrawerOpen ? 'block' : 'none',
    cursor: 'pointer',
  };

  const handleOutsideClick = (event) => {
    if (event.target === event.currentTarget) {
      handleDrawerToggle();
    }
  };

  return (
    <div
      role="button"
      tabIndex={0}
      style={containerStyle}
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleDrawerToggle();
        }
      }}
      onClick={handleOutsideClick}
    >
      <Drawer
        variant="persistent"
        anchor={DrawerConfig.anchor}
        open={isDrawerOpen}
        onClose={handleDrawerToggle}
        PaperProps={{
          sx: { width: { lg: DrawerConfig.width, xs: '100%', md: '100%' } },
        }}
      >
        {/* Drawer Content */}

        {/* Header */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
            borderBottom: '1px solid rgba(220, 220, 220, 0.2)',
          }}
        >
          <Typography variant="h6">{DrawerConfig.drawerHeaderText}</Typography>
          <div
            style={{
              width: '30%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <TextField
              name="status"
              label="status"
              value={airwayBillData[status]}
              onChange={handleInputChange}
              variant="filled"
              sx={{ minWidth: '80%' }}
              select
            >
              {status.map((i, index) => (
                <MenuItem key={index} value={i}>
                  {i}
                </MenuItem>
              ))}
            </TextField>

            <IconButton onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* Form */}
        <div
          className="content-wrap"
          style={{
            gap: '2px',
            padding: '30px 0px 0px 0px',
            alignItems: 'center',
            justifyContent: 'space-evenly',
          }}
        >
          {/* Form */}

          {/* Personal Information */}
          <InputFieds
            userData={airwayBillData}
            heading="Air Waybill Information"
            inputFields={personalInformation}
            setUserData={setairwayBillData}
          />
          {/* Personal Information */}

          <div
            className="content-wrap"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              padding: '0px 0px 0px 20px',
            }}
          >
            <DatePickerValue
              setter={handleInputChange}
              value={new Date(airwayBillData.date)}
              selectName="order_date"
            />
          </div>
        </div>

        {/* Buttons */}
        {DrawerConfig.buttons && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: '1px solid rgba(220, 220, 220, 0.2)',
              padding: '16px',
              position: 'absolute',
              bottom: '0',
              left: '0',
              width: '100%',
            }}
          >
            {DrawerConfig.buttons.elements.map((element, index) => (
              <Button
                variant="contained"
                key={index}
                style={{
                  marginLeft: '24px',
                  width: '136px',
                }}
                onClick={element.onClick}
              >
                Create
              </Button>
            ))}
          </div>
        )}
      </Drawer>
    </div>
  );
};

CreateAirwayBillDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
};

export default CreateAirwayBillDrawer;
