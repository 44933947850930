import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { Stack } from '@mui/material';
import { MultiFilePreview, UploadBox } from 'src/components/upload';

export default function UploadImageComponent({
  uniqueIdentifier,
  hasPadding = true,
  files,
  setFiles,
  imageId,
}) {
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          uniqueIdentifier,
        })
      );
      setFiles(newFiles);
    },
    [setFiles, uniqueIdentifier]
  );
  const handleRemoveFile = useCallback(
    (inputFile) => {
      const filtered = files.filter((file) => file !== inputFile);
      setFiles(filtered);
    },
    [files, setFiles]
  );

  return (
    <div
      className="content-wrap"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
        padding: hasPadding ? '30px 0px 0px 20px' : '',
      }}
    >
      <div
        className="content-wrap"
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
        }}
      >
        {files.map((i, index) =>
          i.uniqueIdentifier === uniqueIdentifier ? (
            <MultiFilePreview
              key={index}
              thumbnail
              files={files}
              onRemove={(file) => handleRemoveFile(file)}
              sx={{ width: 100, height: 100 }}
            />
          ) : null
        )}
        <Stack direction="row" flexWrap="wrap">
          <UploadBox onDrop={handleDrop} sx={{ width: 100, height: 100 }} />
        </Stack>
      </div>
    </div>
  );
}

UploadImageComponent.propTypes = {
  uniqueIdentifier: PropTypes.string.isRequired,
  hasPadding: PropTypes.bool,
  files: PropTypes.array.isRequired,
  setFiles: PropTypes.func.isRequired,
  imageId: PropTypes.string,
};
